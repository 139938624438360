export class ExamResultSeverityModel {
  SeverityId: number;
  SeverityUid: string;
  SeverityName: string;
  SeverityLabel: string;
  OrgId: number;
  CreatedBy: number;
  CreatedOn: Date;
  LastModifiedBy: number;
  LastModifiedOn: Date;
  UnitId: number;
}
