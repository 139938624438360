import { RisExamTypeModel } from "./ris-exam-type.model";
import { RisExam } from "./ris-exam.model";

export class ExamImageQualityModel {
  ExamimagequalityId: number;
  ExamimagequalityUid: string;
  ExamimagequalityName: string;
  ExamTypeId?: number;
  ExamId?: number;
  IsDefault?: boolean;
  IsActive?: boolean;
  IsCustomtext?: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  RisExam: RisExam;
  RisExamtype: RisExamTypeModel;
}
