import { Injectable } from "@angular/core";
declare let alertify: any;
@Injectable({
  providedIn: "root",
})
// export class AlertifyService {

//   constructor() { }
// }
export class AlertifyService {
  constructor() {}

  success(message: string) {
    alertify.success(message);
  }

  // info(message: string) {
  //   alertify.info(message);
  // }

  error(message: string) {
    alertify.error(message);
  }

  warning(message: string) {
    alertify.warning(message);
  }

  message(message: string) {
    alertify.message(message);
  }
}
