export class ServiceTypeModel {
  ServiceTypeId: number;
  ServiceTypeUID: string;
  ServiceTypeText: string;
  IsUpdated: string;
  IsDeleted: string;
  IsActive: string;
  OrgId: number;
  CreatedBy: number;
  CreatedOn: Date;
  LastModifiedBy: string;
  LastModifiedOn: Date;
}
