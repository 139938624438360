import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { StudyModel } from "../../_models/study.model";
import { PwdValidatorParam, PwdValidatorRes } from "src/app/_models/password-policy/pwdvalidator-res.modal";
import { GeneralResModel } from "src/app/_models";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiPATH = "Auth/";
  constructor(private api: ApiService) {}

  login(model: any) {
    var userModel = {
      userEmail: model.Useremail,
      password: model.Password,
      isCloud: model.IsCloud,
    };
    return this.api.post<any>(`${this.apiPATH}login`, userModel);
  }

  logout(userId: number) {
    return this.api.get<any>(`${this.apiPATH}logout/${userId}`);
  }

  getSharebleLinkById(linkId: string) {
    return this.api.get<string>(`${this.apiPATH}getSharebleLinkById/${linkId}`);
  }

  LoginWithLine(code: string, state: string) {
    return this.api.get<any>(`${this.apiPATH}OAuth/${code}/${state}`);
  }
  getAccessToken(): string {
    return localStorage.getItem("token");
  }
  ValidatePassword(request: PwdValidatorParam) {
    return this.api.post<GeneralResModel<PwdValidatorRes>>(
      `${this.apiPATH}ValidatePassword`,
      request
    );
  }
}
