import { Component, OnInit } from "@angular/core";
import { User } from "./_models/user-models/user";
import { AppSessionState } from "./_services/common/app.service";
import { AppMainComponent } from "./app.main.component";

@Component({
  selector: "app-menu",
  templateUrl: "./app.menu.component.html",
})
export class AppMenuComponent implements OnInit {
  model: any[];
  lblLatestRouter: string="Latest Router";
  public client: User = new User();
  constructor(
    public appMain: AppMainComponent,
    private _appState: AppSessionState
  ) {}

  ngOnInit() {
    this.client = JSON.parse(this._appState.getSessionStorage());
    if(this.client.LatestRouterVersionNo){
      this.lblLatestRouter="Latest Router "+this.client.LatestRouterVersionNo;
    }
    // ['SP','Customer','Hospital','Viewer']
    this.model = [
      {
        label: "Dashboard",
        icon: "pi pi-fw pi-chart-line",
        routerLink: ["/dashboard/chart"],
        roleType: ["All"],
      },
      {
        label: "Worklist",
        icon: "pi pi-fw pi-book",
        routerLink: ["/study/worklist"],
        roleType: ["All"],
      },
      {
        label: "Study Tracking",
        icon: "pi pi-map-marker",
        routerLink: ["/study/study-tracking-by-accession"],
        roleType: ["All"],
      },
      {
        label: "Site Contract",
        icon: "pi pi-clone",
        routerLink: ["/study/site-contract"],
        roleType: ["CustomerD"],
      },
      {
        label: "Radiologist Contract",
        icon: "pi pi-clone",
        routerLink: ["/study/radiologist-contract"],
        roleType: ["Hospital"],
      },
      {
        label: "Report Template",
        icon: "pi pi-id-card",
        routerLink: ["/study/report-template"],
        roleType: ["CustomerD"],
      },
    
      // {
      //   label: "Registration",
      //   icon: "pi pi-fw pi-plus-circle",
      //   routerLink: ["/study/registration"],
      //   roleType:['All']
      // },
      // {
      //   label: "BI",
      //   icon: "pi pi-fw pi-chart-line",
      //   routerLink: ["/study/bi"],
      //   roleType:['All']
      // },
    
      {
        label: "Unlock Worklist",
        icon: "pi pi-fw pi-unlock",
        routerLink: ["/study/unlock-worklist"],
        roleType: ["All"],
      },
      {
        label: "Int. Monitoring Center",
        icon: "pi pi-fw pi-circle",
        routerLink: ["/study/integration-monitoring-center"],
        roleType: ["SP", "Hospital", "CustomerA"],
      },
   

      {
        label: "Setting",
        icon: "pi pi-cog",
        routerLink: ["/blocks"],
        items: [
          {
            label: "User list",
            icon: "pi pi-fw pi-users",
            routerLink: ["/study/userlist"],
            roleType: ["SP", "CustomerA"],
          },
          {
            label: "Org Setup",
            icon: "pi pi-align-justify",
            routerLink: ["/study/org-info"],
            roleType: ["SP","HospitalA"],
          },
          {
            label: "Exam Info.",
            icon: "pi pi-align-justify",
            routerLink: ["/study/exam-info"],
            //roleType: ["SP"],
            roleType: ["CustomerA", "Hospital"],
          },
          {
            label: "Modality Info.",
            icon: "pi pi-align-justify",
            routerLink: ["/study/modality"],
            roleType: ["SP","HospitalA"],
          },
          {
            label: "Modality Exam Info.",
            icon: "pi pi-align-justify",
            routerLink: ["/study/modality-exam-info"],
            roleType: ["SP","HospitalA"],
          },
          {
            label: "Earning Type Setting",
            icon: "pi pi-align-justify",
            routerLink: ["/study/earningtype-setting"],
            roleType: ["SP"],
          },
          {
            label: "Job Type Setup",
            icon: "pi pi-cog",
            routerLink: ["/blocks"],
            items: [
              {
                label: "Job Type",
                icon: "pi pi-fw pi-lock",
                routerLink: ["/study/jobtype-list"],
                roleType: ["SP"],
              },
              {
                label: "Job Type Level",
                icon: "pi pi-fw pi-lock",
                routerLink: ["/study/jobtypelevel-list"],
                roleType: ["SP"],
              },
              {
                label: "Job Type Permission",
                icon: "pi pi-fw pi-lock",
                routerLink: ["/study/jobtypepermission-list"],
                roleType: ["SP"],
              },
            ],
            roleType: ["SP"],

          },
        
        ],
        roleType: ["SP","HospitalA", "CustomerA"],
      },
      {
        label: "Reports",
        icon: "pi pi-file-pdf",
        routerLink: ["/blocks"],
        items: [
          {
            label: "Report Setting",
            icon: "pi pi-cog",
            routerLink: ["/study/report-setting"],
            roleType: ["SP"],
          },
          {
            label: "Report Job Type Setting",
            icon: "pi pi-cog",
            routerLink: ["/study/report-jobtypesetting"],
            roleType: ["SP"],
          },
          {
            label: "Report Header Setting",
            icon: "pi pi-cog",
            routerLink: ["/study/report-header-setting"],
            roleType: ["SP"],
          },
          {
            label: "Report Viewer",
            icon: "pi pi-eye",
            routerLink: ["/study/report-viewer"],
            roleType: ["All"],
          },
        ],
        roleType: ["All"],
      },
      {
        label: this.lblLatestRouter,
        icon: "pi pi-fw pi-download",
        routerLink: ["/study/router-upload"],
        roleType: ["SP", "HospitalA"],
      },
   

      // {
      //   label: "Order Submit",
      //   icon: "pi pi-fw pi-unlock",
      //   routerLink: ["/study/order-submit"],
      //   roleType: ["SP"],
      // },
      // {
      //     label: 'UI Kit', icon: 'pi pi-fw pi-star-fill', routerLink: ['/uikit'],
      //     items: [
      //         {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout']},
      //         {label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input']},
      //         {label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel']},
      //         {label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/uikit/invalidstate']},
      //         {label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon'},
      //         {label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table']},
      //         {label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list']},
      //         {label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree']},
      //         {label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel']},
      //         {label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay']},
      //         {label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media']},
      //         {label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'], preventExact: true},
      //         {label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message']},
      //         {label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file']},
      //         {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts']},
      //         {label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc']}
      //     ]
      // },
      // {
      //     label:'Prime Blocks', icon:'pi pi-fw pi-prime', routerLink: ['/blocks'],
      //     items:[
      //         {label: 'Free Blocks', icon: 'pi pi-fw pi-eye', routerLink: ['/blocks']},
      //         {label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: ['https://www.primefaces.org/primeblocks-ng'], target: '_blank'},
      //     ]
      // },
      // {
      //     label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['/utilities'],
      //     items: [
      //         {label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['utilities/icons']},
      //         {label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: ['https://www.primefaces.org/primeflex/'], target: '_blank'},
      //     ]
      // },
      // {
      //     label: 'Pages', icon: 'pi pi-fw pi-copy', routerLink: ['/pages'],
      //     items: [
      //         {label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud']},
      //         {label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar']},
      //         {label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/pages/timeline']},
      //         { label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
      //         { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'], target: '_blank' },
      //         { label: 'Error', icon: 'pi pi-fw pi-exclamation-triangle', routerLink: ['/error'], target: '_blank' },
      //         { label: '404', icon: 'pi pi-fw pi-times', routerLink: ['/404'], target: '_blank' },
      //         {label: 'Access Denied', icon: 'pi pi-fw pi-ban', routerLink: ['/accessdenied'], target: '_blank'},
      //         { label: 'Empty', icon: 'pi pi-fw pi-clone', routerLink: ['/pages/empty'] },
      //     ]
      // },
      // {
      //     label: 'Hierarchy', icon: 'pi pi-fw pi-sitemap',
      //     items: [
      //         {
      //             label: 'Submenu 1', icon: 'pi pi-fw pi-sign-in',
      //             items: [
      //                 {
      //                     label: 'Submenu 1.1', icon: 'pi pi-fw pi-sign-in',
      //                     items: [
      //                         { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-sign-in' },
      //                         { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-sign-in' },
      //                         { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-sign-in' },
      //                     ]
      //                 },
      //                 {
      //                     label: 'Submenu 1.2', icon: 'pi pi-fw pi-sign-in',
      //                     items: [
      //                         { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-sign-in' }
      //                     ]
      //                 },
      //             ]
      //         },
      //         {
      //             label: 'Submenu 2', icon: 'pi pi-fw pi-sign-in',
      //             items: [
      //                 {
      //                     label: 'Submenu 2.1', icon: 'pi pi-fw pi-sign-in',
      //                     items: [
      //                         { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-sign-in' },
      //                         { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-sign-in' },
      //                     ]
      //                 },
      //                 {
      //                     label: 'Submenu 2.2', icon: 'pi pi-fw pi-sign-in',
      //                     items: [
      //                         { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-sign-in' },
      //                     ]
      //                 },
      //             ]
      //         }
      //     ]
      // },
      // {
      //     label: 'Docs', icon: 'pi pi-fw pi-file', routerLink: ['/documentation']
      // },
      // {
      //     label: 'Buy Now', icon: 'pi pi-fw pi-money-bill', url: ['https://www.primefaces.org/store']
      // }
    ];
  }
  menuHide(menu: any) {
    let res = true; // menu hide
    // console.log(menu)
    menu.roleType.forEach((element) => {
      if (element == this.client.OrgType) {
        res = false;
      }
      if (element == "All") {
        res = false;
      }
      if (element == this.client.OrgType + this.client.JobType) {
        // jobtype Radiologists hole hide
        res = false;
      }
    });
    return res;
  }
  onMenuClick() {
    this.appMain.menuClick = true;
  }
}
