export class HrEmpSubspecialtyModel {
  EmpsubspecialtyId: number;
  EmpId: number;
  SubspecialtyId: number;
  EmpsubspecialtyDetail: string;
  IsActive?: boolean;
  IsUpdated?: boolean;
  IsDeleted?: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: string;
  LastModifiedBy?: number;
  LastModifiedOn?: string;
  SubspecialtyUid: string;
  SpecialtyId?: number;
  SubspecialtyName: string;
  SubspecialtyDetail: string;
}
