export class HrEmpVerify {
    VerifyId: number;
    PhoneNo: string;
    PhoneOtp: string;
    IsPhoneVerified?: boolean;
    Email: string;
    EmailOtp: string;
    IsEmailVerified?: boolean;
    CreatedOn?: Date;
    LastModifiedOn?: Date;
    CountryDialCode:string;
}