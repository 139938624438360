import {Action, State, createReducer, on } from '@ngrx/store';
import * as LoginAction from '../actions/login.actions';
import {User} from "../../_models/user-models/user";
import { UserSessionStateModel } from '../models/user-session-state.model';




export const initialState : UserSessionStateModel ={
    LoginTime: null,
    UserInfo: null,
    UserImageUrl : null,
};


const loginStateReducer = createReducer(
    initialState,
    on(LoginAction.login, (state,{userSessionDetail}) => (
            { 
                LoginTime: userSessionDetail.LoginTime, 
                UserInfo: userSessionDetail.UserInfo 
            }
        )),
        on(LoginAction.setUserImageUrl, (state,{userSessionDetail}) => (
            { 
                LoginTime: userSessionDetail.LoginTime, 
                UserInfo: userSessionDetail.UserInfo,
                UserImageUrl: userSessionDetail.UserImageUrl 
            }
        )),
        // on(LoginAction.setUserImageUrl, (state,{userSessionDetail}) => (
        //     { 
        //         UserImageUrl: userSessionDetail.UserImageUrl, 
        //     }
        // ))

);

export function reducer(state: UserSessionStateModel , action: Action) {

    return loginStateReducer(state, action);
}



// import {UserSessionStateModel} from "../models/user-session-state.model";
// import * as LoginActions from '../actions/login.actions';
//
//
//
// const initialState : UserSessionStateModel = new UserSessionStateModel()
//
//
//
//
// export function loginReducer(state: UserSessionStateModel= initialState, action: LoginActions.Actions){
//
//     debugger;
//     switch (action.type){
//         case LoginActions.LOGIN_BY_EMAIL_PASSWORD:
//             return{...state,
//                 LoginTime : Date.now(),
//                 UserInfo : action.payload.UserInfo,
//
//             }
//     }
// }
