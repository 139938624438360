export class GblReportHeaderModel {
    ReportheaderId: number;
    ReportId?: number;
    ReportheaderType: string;
    ModalityName: string;
    ReportheaderName: string;
    OrgId?: number;
    OrgLogo: string;
    IsDefault?: boolean;
    CreatedBy?: number;
    CreatedOn?: Date;
    LastModifiedBy?: number;
    LastModifiedOn?: Date;
    AddressLine1: string;
    AddressLine2: string;
    AddressLine3: string;
    HeaderImage: string;
    UseHeaderImage?: boolean;
    IsActive?: boolean;
}