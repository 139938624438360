import { GblReportJobtypeSettingModel } from "./gbl-report-jobtype-setting.model";
import { GblReportParameterSettingModel } from "./gbl-report-parameter-setting.model";

export class GblReportModel {
  ReportId: number;
  ReportUid: string;
  ReportName: string;
  ReportDescription: string;
  ReportUrl: string;
  IsActive?: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  GblReportjobtypesetting: GblReportJobtypeSettingModel[];
  GblReportparametersetting: GblReportParameterSettingModel[];
}
