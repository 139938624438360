import { ExamImageQualityModel } from "./exam-image-quality.model";

export class RisExamTypeModel {
  examTypeId: number;
  examTypeUid: string;
  examTypeText: string;
  examTypeAbbr: string;
  examTypeIns: string;
  isActive: string;
  orgId?: number;
  createdBy?: number;
  createdOn?: string;
  lastModifiedBy?: number;
  lastModifiedOn?: string;
  examTypeInsKid: string;
  startNormal?: number;
  startMedium?: number;
  startHigh?: number;
  startNormalColor: string;
  startMediumColor: string;
  startHighColor: string;
  examTypeInsEng: string;
  examTypeInsKidEng: string;
  risExamimagequality: ExamImageQualityModel[];
}
