import { GblReportParameterSettingModel } from "./gbl-report-parameter-setting.model";

export class GblReportParameterModel {
  ParameterId: number;
  ParameterUid: string;
  ParameterName: string;
  ParameterDisplayText: string;
  ParameterShortText: string;
  ParameterValueType: string;
  IsActive?: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  gblReportparametersetting: GblReportParameterSettingModel[];
}
