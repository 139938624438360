export class UserPreservedDataModel {
  Id: number;
  Uid: string;
  UserId: number;
  OrgId: number;
  GridData: string;
  Type: string;
  IsActive: boolean;
  CreatedBy: number;
  CreatedOn: Date;
  LastModifiedBy: number;
  LastModifiedOn: number;
}
