export class UpdateStudyReqModel {
  hn: string;
  pacsHn: string;
  patientName: string;
  orderId: number;
  orgId: number;
  accessionNo: string;
  examDt: string;
  noOfImage: number;
  examId: number;
  examName: string;
  status: string;
  modalityId: number;
  modalityName: string;
  orderDt: string;
  gender: string;
  isCloud: boolean;
  fname: string;
  lname: string;
  mname: string;
  dob: string;
  regId: number;
  addr1: string;
  statusName: string;
  pacsAccessionNo: string;
  studyInstanceUId: string;
  filePath: string;
  examCode: string;
  cancelHost: string;
  cancelledBy: number;
  cancelledReason: string;
  cancelledOn: string;
  sSN: string;
  queue: number;
  busId: number;
  caseNo: string;
  room: string;
  govtId: string;
  ServiceType: number;
  ServiceTypeText: string = "Test";
}
