export class ExamDtoModel {
  ExamId: number;
  ExamUid: string;
  OrgId?: number;
  ExamName: string;
  Rate: number;
  GovtId?: number;
  GovtCode: string;
  GovtName: string;
  GovtRate?: number;
  ExamTypeId?: number;
  ServiceTypeId?: number;
  ServiceTypeName: string;
  SubspecialtyId?: number;
  SubspecialtyName: string;
  IsSubspecialtyActive?: boolean;
  SubspecialtyMappingCreatedBy?: number;
  SubspecialtyMappingLastModifiedBy?: number;
  MultipleReportingallow?: boolean;
  MultipleNoOfReporting?: number;
  AnalyzeritemId!: number;
  AnalyzeritemName!: string;
  ServiceType!: number;
  CreatedBy: string;
  CreatedOn?: Date;
  LastModifiedBy: string;
  LastModifiedOn?: Date;
  IsDefault?: boolean;
}
