export class StudyVersionResModel {
  VersionName: string;
  VersionNO?: number;
  FinalizedRadName: string;
  FinalizedBy?: number;
  FinalizedById?: number;
  TransactionType: string;
  ExamresulthistoryId: number;
  TransactionOn?: Date;
  FinalizedOn?: Date;
}
