import { DataTransferService } from "./common/data-transfer.service";
import { DicomService } from "./dicom/dicom.service";
import { EncrypDepcrypService } from "./encryption/encryp-depcryp.service";
import { ExamInfoService } from "./exam-info/exam-info.service";
import { ExamResultService } from "./exam-result/exam-result.service";
import { OpenAiService } from "./open-ai/open-ai.service";
import { ReportService } from "./report/report.service";
import { TimeLimitService } from "./utility/time-limit.service";
import { UtilityService } from "./utility/utility.service";

export const services: any[] = [
  DicomService,
  ExamResultService,
  ExamInfoService,
  ReportService,
  OpenAiService,
  UtilityService,
  EncrypDepcrypService,
  TimeLimitService,
  DataTransferService
];


export * from "./utility/time-limit.service";
export * from "./encryption/encryp-depcryp.service";
export * from "./utility/utility.service";
export * from "./dicom/dicom.service";
export * from "./exam-info/exam-info.service";
export * from "./exam-result/exam-result.service";
export * from "./report/report.service";
export * from "./open-ai/open-ai.service";
export * from "./common/data-transfer.service";
