import { ExamResult } from "../exam/exam-result.model";
import { OrderScoreModel } from "../order-score/order-score.model";

export class OrderclinicalsignificanceModel {
  ClinicalSignificanceId: number;
  ClinicalSignificanceUid: string;
  ClinicalSignificanceText: string;
  ClinicalSignificanceLtext: string;
  OrderScoreId: number;
  DisplayOrder: number;
  IsActive: boolean;
  OrgId: number;
  IsDeleted: boolean;
  CreatedBy: number;
  CreatedOn: string;
  LastModifiedBy: number;
  LastModifiedOn: string;
  OrderScore: OrderScoreModel;
  RisExamresult: ExamResult[] = [];
  OrderScoreText: string;
  ScoreDisplayOrder: number;
  OrderScoreUid: string;
}
