import { AutoCompleteResponse } from "./autocompleteresponse";

export class ReportFilterViewModel {
  accNo: string;
  fromDate: string;
  toDate: string;
  recordLimit: number;
  ascendingOrder: boolean;
  radiologistId: number;
  orderId: number;
  orgId: number[];
  selectedAcc:AutoCompleteResponse;
}
