export class HisRegistrationModel {
  regId: number;
  hn: string;
  title: string;
  regDt?: string;
  fname: string;
  mname: string;
  lname: string;
  titleEng: string;
  fnameEng: string;
  mnameEng: string;
  lnameEng: string;
  ssn: string;
  dob?: string;
  age?: number;
  addr1: string;
  addr2: string;
  addr3: string;
  addr4: string;
  addr5: string;
  phone1: string;
  phone2: string;
  phone3: string;
  email: string;
  gender: string;
  maritalStatus: string;
  occupationId?: number;
  nationality: string;
  passportNo: string;
  bloodGroup: string;
  religion: string;
  patientType: string;
  blockPatient: string;
  emContactPerson: string;
  emRelation: string;
  emAddr: string;
  emPhone: string;
  insuranceType: string;
  hl7Format: string;
  hl7Send: string;
  linkDown: string;
  allergies: string;
  orgId?: number;
  createdBy?: number;
  createdOn?: string;
  lastModifiedBy?: number;
  lastModifiedOn?: string;
  isDeleted: string;
  isUpdated: string;
  picture: string;
  isJohndoe: string;
  hisHn: string;
  extHn: string;
  pacsHn: string;
  hospitalHn: string;
  oWNRPID: string;
  oWNNAME: string;
  countryId?: number;
  provinceId?: number;
  districtId?: number;
  subdistrictId?: number;
  zipCode?: number;
  pcrDate?: string;
}
