export class StudyResetReasonResModel {
  ResetreasonId: number;
  ResetreasonUid: string;
  ResetreasonName: string;
  ResetreasonFor: string;
  CustomAllow?: boolean;
  IsActive?: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
}
