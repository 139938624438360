import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserService } from '../../../../_services/user/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Country } from '../../../../demo/domain/customer';
import { environment } from "src/environments/environment";
import { User } from '../../../../_models/user-models/user';
import { AlertifyService } from '../../../../_services/common/alertify.service';
import { emailValidator } from 'src/app/_helper/validator.helper';
import { MenuItem } from 'primeng/api';
import { RadiologistPhoneVerificationComponent } from '../radiologist-phone-verification/radiologist-phone-verification.component';
import { GblCountry } from 'src/app/_models/radiologist-signup/GblCountry';
import { GblDistrict } from '../../../../_models/radiologist-signup/GblDistrict';
import { GblProvince } from '../../../../_models/radiologist-signup/GblProvince';
import { GblSubdistrict } from '../../../../_models/radiologist-signup/GblSubdistrict';

@Component({
  selector: 'app-radiologist-signup',
  templateUrl: './radiologist-signup.component.html',
  styleUrls: ['./radiologist-signup.component.scss']
})
export class RadiologistSignupComponent implements OnInit {
  radiogistForm: FormGroup;

  constructor(
    private _activateRoute:ActivatedRoute,
    private _userService:UserService,
    private alertifyService:AlertifyService
  ) { }

  lineProfileImageUrl:string;
  
  genders:any;
  orglist:any;
  examList:any;
  selectedFName:string;
  selectedLName:string;
  selectedEmail:string;
  selectePassword:string;
  selectConfirmPassword:string;
  selectedLineId:string;
  selectedPhoneNo:string;
  selectedZipCode?:number;
  profileImageUrl:string;
  selectedCountry:GblCountry;
  countryList:GblCountry[];
  selectedDistrict:GblDistrict;
  districtList:GblDistrict[];
  selectedProvince:GblProvince;
  ProvinceList:GblProvince[];
  selectedSubDistrict:GblSubdistrict;
  SubDistrictList:GblSubdistrict[];
  selectedPassword:string;
  selectedConfirmPassword:string;
  disable:boolean=true;
  saveOrUpdateButtonLabel:string;

  items: MenuItem[];
    
  activeIndex: number = 0;

  selectedHrUser:User;

  @ViewChild(RadiologistPhoneVerificationComponent)
  radiologistPhoneVerificationComponent: RadiologistPhoneVerificationComponent;

  @Input('LineId') LineId:string;

  @Output() userEmitter = new EventEmitter<User>();
  
  ngOnInit() {

    this.items = [{
      label: 'Radiologist Sign up',
      // routerLink: 'personal'
  },
  {
      label: 'Phone Number Verification',
      // routerLink: 'seat'
  },
  {
      label: 'Email Address Verification',
      // routerLink: 'payment'
  },
  {
      label: 'Confirmation',
      // routerLink: 'confirmation'
  }
];

    this.lineProfileImageUrl="https://i.insider.com/5fbd515550e71a001155724f?width=700";

    this.disable=false;
    this.saveOrUpdateButtonLabel="Save";

    this.radiogistForm = new FormGroup({
      FName: new FormControl("", Validators.required),
      LName:new FormControl("", Validators.required),
      Email:new FormControl("", emailValidator),
      Password:new FormControl("", Validators.required),
      ConfirmPassword: new FormControl("", Validators.required),
      LineId:new FormControl(),
      PhoneNo: new FormControl(
        "",
        Validators.compose([Validators.pattern("[0-9]+")])
      ),
      Country:new FormControl(),
      District: new FormControl(),
      Province:new FormControl(),
      SubDistrict:new FormControl(),
      ZipCode:new FormControl()
    },
    { validators: this.passwordMatch }
    );

    this.profileImageUrl=environment.profileImagerUrl+"/Profile/";

    if(this.LineId){
      this.disable=true;
      this.saveOrUpdateButtonLabel="Update";
      this._userService.getUserByLineId(this.LineId).subscribe(user=>{
        if(user){
       this.selectedHrUser=user;
        if(user.ImgFileName){
          this.profileImageUrl=environment.profileImagerUrl+"/Profile/"+user.OrgId+"/"+user.EmpId+"/"+user.ImgFileName;
        }

        this.selectedEmail=user.EmailOfficial;
        this.selectedLineId=user.LineId;
        }
      })
    }

    this.loadStartUpData();
  }


  loadStartUpData(){
    this._userService.getCountryList().subscribe(x=>{
      if(x){
        this.countryList=x;
      }
    });


  }

  loadProvince(){

    this._userService.getProvinceList(this.selectedCountry.CountryId).subscribe(x=>{
      if(x){
        this.ProvinceList=x;
      }
    })

    this.EmmitRediologist();
  }

  loadDistrict(){
    this._userService.getDistrictList(this.selectedProvince.ProvinceId).subscribe(x=>{
      if(x){
        this.districtList=x;
      }
    })
    this.EmmitRediologist();
  }

  loadSubDistrict(){
    this._userService.getSubDistrictList(this.selectedDistrict.DistrictId).subscribe(x=>{
      if(x){
        this.SubDistrictList=x;
      }
    })
    this.EmmitRediologist();
  }

  passwordMatch(g: FormGroup) {
    return g.get("Password").value === g.get("ConfirmPassword").value
      ? null
      : { mismatch: true };
  }

 EmmitRediologist(){
    let user= new User();

    user.Fname=this.selectedFName;
    user.Lname=this.selectedLName;
    user.EmailOfficial=this.selectedEmail;
    user.Pwd=this.selectedPassword;
    user.PhoneOffice=this.selectedPhoneNo;
    user.ZipCode=this.selectedZipCode;

    user.CountryId=this.selectedCountry?.CountryId;
    user.DistrictId=this.selectedDistrict?.DistrictId;
    user.ProvinceId=this.selectedProvince?.ProvinceId;
    user.SubdistrictId=this.selectedSubDistrict?.SubdistrictId;

    user.LineId=this.selectedLineId;
    if(this.LineId){
      user.OrgId=this.selectedHrUser.OrgId;
    }

    this.userEmitter.emit(user);

  }
}
