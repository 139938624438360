import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AppMainComponent } from "./app.main.component";
import { User } from "./_models/user-models/user";
import { AuthService } from "./_services/auth/auth.service";

@Component({
  selector: "app-topbar",
  template: `
    <div class="layout-topbar">
      <div class="layout-topbar-wrapper">
        <div class="layout-topbar-left">
          <div class="layout-topbar-logo-wrapper">
            <a routerLink="/study/worklist" class="layout-topbar-logo">
              <img
                src="assets/layout/images/radportal_logo.svg"
                alt="mirage-layout"
              />
              <span class="app-name">RadPortal</span>
            </a>
          </div>

          <a
            routerLink="/study/worklist"
            class="sidebar-menu-button"
            (click)="appMain.onMenuButtonClick($event)"
          >
            <i class="pi pi-bars"></i>
          </a>

          <a
            href="#"
            class="topbar-menu-mobile-button"
            (click)="appMain.onTopbarMobileMenuButtonClick($event)"
          >
            <i class="pi pi-ellipsis-v"></i>
          </a>
          <img
            class="mr-2 mt-2"
            src="{{ demoHospitalLogo }}"
            alt=""
            width="25"
            height="25"
          />
          <!-- <h5>
            {{ loginUserOrgName }}
          </h5> -->
        </div>
        <!-- <h5 class="dblock-sm dnone-sm pl-5">
          {{ loginUserOrgName }}
        </h5> -->
        <div class="brand-logo">
          <h5>{{ loginUserOrgName }}</h5>
        </div>
        <div class="layout-topbar-right fadeInDown">
          <ul class="layout-topbar-actions">
            <li
              #profile
              class="topbar-item profile-item"
              [ngClass]="{
                'active-topmenuitem': appMain.activeTopbarItem === profile
              }"
            >
              <a href="#" (click)="appMain.onTopbarItemClick($event, profile)">
                <span class="profile-image-wrapper">
                  <img src="{{ defaultProfilePhoto }}" alt="User Photo" />
                </span>
                <span class="profile-info-wrapper">
                  <h3>{{ currentUserName }}</h3>
                  <!-- <span>Design</span> -->
                </span>
              </a>
              <ul class="profile-item-submenu fadeInDown">
                <li class="layout-submenu-item">
                  <i class="pi pi-user icon icon-3"></i>
                  <div class="menu-text">
                    <!-- <a routerLink="/study/profile">Profile</a> -->
                    <a
                      [routerLink]="
                        isRadiologist
                          ? '/study/profile'
                          : '/study/radiologist-profile'
                      "
                      >Profile</a
                    >
                  </div>
                  <!-- <i class="pi pi-angle-right"></i> -->
                </li>
                <hr class="hr-y" />
                <li class="layout-submenu-footer">
                  <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-sign-out"
                    iconPos="left"
                    label="Sign Out"
                    class="p-button-raised p-button-text"
                    (click)="logout()"
                  ></button>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="profile-mobile-wrapper">
            <li
              #mobileProfile
              class="topbar-item profile-item"
              [ngClass]="{
                'active-topmenuitem': appMain.activeTopbarItem === mobileProfile
              }"
            >
              <a
                href="#"
                (click)="appMain.onTopbarItemClick($event, mobileProfile)"
              >
                <span class="profile-image-wrapper">
                  <img src="{{ defaultProfilePhoto }}" alt="mirage-layout" />
                </span>
                <span class="profile-info-wrapper">
                  <h3>{{ currentUserName }}</h3>
                  <!-- <span>Design</span> -->
                </span>
              </a>
              <ul class="fadeInDown">
                <li class="layout-submenu-item">
                  <i class="pi pi-user icon icon-3"></i>
                  <div class="menu-text">
                    <a routerLink="/study/profile">Profile</a>
                  </div>
                  <i class="pi pi-angle-right"></i>
                </li>
                <hr class="hr-y" />
                <li class="layout-submenu-footer">
                  <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-sign-out"
                    iconPos="left"
                    label="Sign Out"
                    class="p-button-raised p-button-text"
                    (click)="logout()"
                  ></button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  `,
})
export class AppTopBarComponent implements OnInit {
  activeItem: number;
  currentUserName: string;
  currentUserProfilePhoto: string;
  defaultProfilePhoto: string;
  profileImagerUrl = environment.profileImagerUrl;
  loginUserOrgName: string;
  demoHospitalLogo: string;
  isRadiologist: boolean = false;

  constructor(
    private _router: Router,
    public appMain: AppMainComponent,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getCurrentUserInfo();
  }

  mobileMegaMenuItemClick(index) {
    this.appMain.megaMenuMobileClick = true;
    this.activeItem = this.activeItem === index ? null : index;
  }

  getCurrentUserInfo() {
    let user = JSON.parse(localStorage.getItem("user")) as User;
    this.loginUserOrgName = user.OrgName;
    this.currentUserName = user.Fname + " " + user.Lname;
    this.defaultProfilePhoto = "./assets/layout/images/topbar/avatar.png";
    this.demoHospitalLogo = "./assets/layout/images/topbar/demo-hospital.png";
    if (user.JobType != "D") {
      this.isRadiologist = true;
    }
    // if (user.ImgFileName != null && user.ImgFileName != undefined) {
    //   this.currentUserProfilePhoto =
    //     this.profileImagerUrl +
    //     "Profile/" +
    //     user.OrgId +
    //     "/" +
    //     user.ImgFileName;
    // } else {
    //   this.defaultProfilePhoto = "./assets/layout/images/topbar/avatar.png";
    // }
  }

  logout() {
    let user = JSON.parse(localStorage.getItem("user")) as User;
    let userId = +user.EmpId;

    this._authService.logout(userId).subscribe(
      (data) => {
        localStorage.removeItem("token");

        sessionStorage.removeItem("user");
        localStorage.removeItem("loginTime");

        this._router.navigate(["/login"]);
      },
      (error) => {
        // this.alertify.error('Failed to logout');
        // this.router.navigate(["/login"]);
      },
      () => {
        // this.alertify.success('Logged out');
        // this.router.navigate(["/login"]);
      }
    );
  }
}
