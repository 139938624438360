import { OrderclinicalsignificanceModel } from "../order-clinical-significance/order-clinical-significance.model";

export class OrderScoreModel {
  OrderScoreId: number;
  OrderScoreUid: string;
  OrderScoreText: string;
  OrderScoreLtext: string;
  DisplayOrder: number;
  IsActive: boolean;
  OrgId: number;
  IsDeleted: boolean;
  CreatedBy: number;
  CreatedOn: string;
  LastModifiedBy: number;
  LastModifiedOn: string;
  RisOrderclinicalsignificance: OrderclinicalsignificanceModel[] = [];
}
