<div class="grid">
    <div class="col-12">
        <div class="card">
            <h5>Advanced</h5>
            <p-fileUpload name="demo[]" url="./upload.php" (onUpload)="onUpload($event)"
                          multiple="multiple" accept="image/*" maxFileSize="1000000">
                <ng-template pTemplate="content">
                    <ul *ngIf="uploadedFiles.length">
                        <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                    </ul>
                </ng-template>
            </p-fileUpload>

            <h5>Basic</h5>
            <p-fileUpload mode="basic" name="demo[]" url="./upload.php" accept="image/*" maxFileSize="1000000" (onUpload)="onBasicUpload($event)"></p-fileUpload>

        </div>
    </div>
</div>