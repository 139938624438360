import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AlertifyService } from 'src/app/_services/common/alertify.service';
import { UserService } from 'src/app/_services/user/user.service';
import { User } from '../../../../_models/user-models/user';

@Component({
  selector: 'app-radiologist-signup-steps',
  templateUrl: './radiologist-signup-steps.component.html',
  styleUrls: ['./radiologist-signup-steps.component.scss']
})
export class RadiologistSignupStepsComponent implements OnInit {
  lineId: string;

  constructor(private _activateRoute:ActivatedRoute,
    private userService:UserService,
    private alertifyService:AlertifyService) { }

  items: MenuItem[];  
  activeIndex: number = 0;
  user:User;
  
  ngOnInit() {

    this.items = [{
      label: 'Radiologist Sign up',
      // routerLink: 'personal'
  },
  {
      label: 'Phone Number Verification',
      // routerLink: 'seat'
  },
  {
      label: 'Email Address Verification',
      // routerLink: 'payment'
  },
  // {
  //     label: 'Confirmation',
  //     // routerLink: 'confirmation'
  // }
];

this._activateRoute.params.subscribe(params=>{
  this.lineId=params.LineId;
});

  }

   NextMenu(){
    if(this.activeIndex==0){
      this.SaveRadiologist();
    }
    
    this.activeIndex++;

   
  }
 

  PrevMenu(){
    this.activeIndex--;
  }


  reciveUserData(user:User){
   // console.log(user);
    this.user=user;
  }


  SaveRadiologist() {
    // this.userService.addNewRadiologist(this.user).subscribe(x=>{
    //   if(x){
    //     this.alertifyService.success("Radiologist created successfully");
    //   }
    // });
  }

}
