export class Configuration {
    /**
     *
     */
    constructor() {
        this.setting = new Setting();
    }
    setting:Setting;
}
export class Setting {
    /**
     *
     */
    constructor() {
        this.TermsAndConditions ="";
    }
    TermsAndConditions :string;
}
