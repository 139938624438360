import { StudyImageInfos } from "../study.model";

export class OpenAiModel {
    UserId?: number;
    OrderId?: number;
    AccessionNo: string;
    AiModel: string;
    PromptText: string;
    ReportText: string;
    StudyImageInfos: StudyImageInfos[] = []
    SelectedImageNames: string[];
    AIResponseResult: string = '';
}