<div class="grid">
    <div class="col-12">
        <div class="card docs no-margin">

            <h4>Current Version</h4>
            <p>Angular 14 and PrimeNG 14</p>

            <h4>Dependencies</h4>
            <p>Mirage has no direct dependency. More information about dependencies is available at <a href="https://www.primefaces.org/why-primeng-templates/">Why PrimeNG Templates</a> article.</p>

            <h4>Getting Started</h4>
            <p>Mirage is a true native application template for Angular and is distributed as a CLI project. In case CLI is not installed already, use
                the command below to set it up.</p>

<app-code ngPreserveWhitespaces ngNonBindable lang="markup">
npm install -g @angular/cli
</app-code>

            <p>Once CLI is ready in your system, extract the contents of the Mirage zip file distribution, cd to the directory,
            install the libraries from npm and then execute "ng serve" to run the application in your local environment.</p>
<app-code ngPreserveWhitespaces ngNonBindable lang="markup">
cd mirage
npm install
ng serve
</app-code>

            <p>The application should run at http://localhost:4200/, you may now start with the development of your application.</p>

            <h4>Important CLI Commands</h4>
            <p>Following commands are derived from CLI.</p>
<app-code ngPreserveWhitespaces ngNonBindable lang="markup">
Run 'ng serve' for a dev server. Navigate to `http://localhost:4200/`. The app will automatically reload if you change any of the source files.

Run 'ng generate component component-name' to generate a new component. You can also use `ng generate directive/pipe/service/class/module`.

Run 'ng build' to build the project. The build artifacts will be stored in the `dist/` directory. Use the `-prod` flag for a production build.

Run 'ng test' to execute the unit tests via [Karma](https://karma-runner.github.io).

Run 'ng e2e' to execute the end-to-end tests via [Protractor](http://www.protractortest.org/).

Run 'ng help' for more options.
</app-code>

            <h4>Structure</h4>
            <p>Mirage consists of 3 main parts; the application layout, layout resources and theme resources for PrimeNG components. <i>app.component.html</i> inside app folder is the html template for the base layout,
                required resources for the layout are placed inside the <i>src/assets/layout</i> folder and similarly theme resources are inside <i>src/assets/theme</i> folder.
            </p>

            <h4>Template</h4>
            <p>Main layout is the html template of the <i>app.main.component.ts</i>, it is divided into a couple of sections such as topbar, sidebar and footer. Here is the code for
                the main template. The component class <i>app.main.component.ts</i> implements the logic such as opening menus and managing layout modes.
            </p>
<app-code  ngPreserveWhitespaces ngNonBindable lang="markup">
&lt;div class="layout-wrapper" (click)="onLayoutClick()"
    [ngClass]="&#123;'layout-horizontal': app.horizontalMenu,
               'layout-wrapper-static': staticMenuActive,
               'layout-rightpanel-active': rightPanelActive,
               'layout-topbar-mobile-active': topbarMobileMenuActive,
               'layout-megamenu-mobile-active': megaMenuMobileActive,
               'layout-sidebar-mobile-active': menuMobileActive,
               'p-input-filled': app.inputStyle === 'filled',
               'p-ripple-disabled': !app.ripple&#125;" [class]="app.menuColor"&gt;

   &lt;app-menu&gt;&lt;/app-menu&gt;

   &lt;div class="layout-main"&gt;
       &lt;app-topbar&gt;&lt;/app-topbar&gt;

       &lt;app-rightpanel&gt;&lt;/app-rightpanel&gt;

       &lt;app-breadcrumb&gt;&lt;/app-breadcrumb&gt;

       &lt;div class="layout-content"&gt;
           &lt;router-outlet&gt;&lt;/router-outlet&gt;
       &lt;/div&gt;

       &lt;app-footer&gt;&lt;/app-footer&gt;

       &lt;app-config&gt;&lt;/app-config&gt;

       &lt;div class="layout-main-mask"&gt;&lt;/div&gt;
   &lt;/div&gt;
&lt;/div&gt;
</app-code>

            <h4>Menu</h4>
            <p>Menu is a separate component defined in <i>app.menu.component.ts</i> file based on PrimeNG MenuModel API. In order to define the menuitems,
            navigate to this file and define your own model as a nested structure. Here is the menu component from the sample application.</p>

<div style="height:400px;overflow: auto;">
<app-code  ngPreserveWhitespaces ngNonBindable lang="javascript">
@Component(&#123;
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
&#125;)
export class AppMenuComponent implements OnInit &#123;

    model: any[];

    constructor(public appMain: AppMainComponent) &#123;&#125;

    ngOnInit() &#123;
        this.model = [
            &#123;label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/']&#125;,
            &#123;
                label: 'UI Kit', icon: 'pi pi-fw pi-star-fill', routerLink: ['/uikit'],
                items: [
                    &#123;label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout']&#125;,
                    &#123;label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input']&#125;,
                    &#123;label: 'Float Label', icon: 'pi pi-bookmark', routerLink: ['/uikit/floatlabel']&#125;,
                    &#123;label: 'Invalid State', icon: 'pi pi-exclamation-circle', routerLink: ['/uikit/invalidstate']&#125;,
                    &#123;label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon'&#125;,
                    &#123;label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table']&#125;,
                    &#123;label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list']&#125;,
                    &#123;label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree']&#125;,
                    &#123;label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel']&#125;,
                    &#123;label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay']&#125;,
                    &#123;label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'], preventExact: true&#125;,
                    &#123;label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message']&#125;,
                    &#123;label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file']&#125;,
                    &#123;label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts']&#125;,
                    &#123;label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc']&#125;
                ]
            &#125;,
            &#123;
                label: 'Prime Blocks', icon: 'pi pi-fw pi-prime', routerLink: ['/blocks'],
                items: [
                    &#123;label: 'Free Blocks', icon: 'pi pi-fw pi-eye', routerLink: ['/blocks']&#125;,
                    &#123;label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: ['https://www.primefaces.org/primeblocks-ng'], target: '_blank'&#125;,
                ]
            &#125;,
            &#123;
                label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['/utilities'],
                items: [
                    &#123;label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['utilities/icons']&#125;,
                    &#123;label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: ['https://www.primefaces.org/primeflex/'], target: '_blank'&#125;,
                ]
            &#125;,
            &#123;
                label: 'Pages', icon: 'pi pi-fw pi-copy', routerLink: ['/pages'],
                items: [
                    &#123;label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud']&#125;,
                    &#123;label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar']&#125;,
                    &#123;label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/pages/timeline']&#125;,
                    &#123; label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' &#125;,
                    &#123; label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'], target: '_blank' &#125;,
                    &#123; label: 'Error', icon: 'pi pi-fw pi-exclamation-triangle', routerLink: ['/error'], target: '_blank' &#125;,
                    &#123; label: '404', icon: 'pi pi-fw pi-times', routerLink: ['/404'], target: '_blank' &#125;,
                    &#123;label: 'Access Denied', icon: 'pi pi-fw pi-ban',routerLink: ['/accessdenied'], target: '_blank'&#125;,
                    &#123; label: 'Empty', icon: 'pi pi-fw pi-clone', routerLink: ['/pages/empty'] &#125;,
                ]
            &#125;,
            &#123;
                label: 'Hierarchy', icon: 'pi pi-fw pi-sitemap',
                items: [
                    &#123;
                        label: 'Submenu 1', icon: 'pi pi-fw pi-sign-in',
                        items: [
                            &#123;
                                label: 'Submenu 1.1', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    &#123; label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-sign-in' &#125;,
                                    &#123; label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-sign-in' &#125;,
                                    &#123; label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-sign-in' &#125;,
                                ]
                            &#125;,
                            &#123;
                                label: 'Submenu 1.2', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    &#123; label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-sign-in' &#125;
                                ]
                            &#125;,
                        ]
                    &#125;,
                    &#123;
                        label: 'Submenu 2', icon: 'pi pi-fw pi-sign-in',
                        items: [
                            &#123;
                                label: 'Submenu 2.1', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    &#123; label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-sign-in' &#125;,
                                    &#123; label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-sign-in' &#125;,
                                ]
                            &#125;,
                            &#123;
                                label: 'Submenu 2.2', icon: 'pi pi-fw pi-sign-in',
                                items: [
                                    &#123; label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-sign-in' &#125;,
                                ]
                            &#125;,
                        ]
                    &#125;
                ]
            &#125;,
            &#123;
                label: 'Docs', icon: 'pi pi-fw pi-file', routerLink: ['/documentation']
            &#125;,
            &#123;
                label: 'Buy Now', icon: 'pi pi-fw pi-money-bill', url: ['https://www.primefaces.org/store']
            &#125;
        ];
    &#125;
</app-code>
</div>

            <h4>Integration with an Existing CLI Project</h4>
            <p>To setup Mirage in an existing project, follow the steps below;</p>

<ul>
    <li>Copy the <i>src/assets</i> folder to your projects folder with the same name.</li>
    <li>Copy all app.* files and breadcrumb.service.ts to the <i>src/app</i> folder of your application.</li>
</ul>

          <p>Install PrimeNG</p>
<app-code ngPreserveWhitespaces ngNonBindable lang="markup">
npm install primeng@latest --save
npm install primeicons@latest --save
</app-code>

          <p>Add PrimeNG CSS at styles section in angular.json.</p>

<app-code ngPreserveWhitespaces ngNonBindable lang="markup">
"styles": [
    "styles.scss"                                               //your styles and overrides
],
</app-code>

            <p>Last part is adding theme and layout css files, in the CLI app they are defined using link tags in index.html so the demo can switch them on
            the fly by changing the path however if this is not a requirement, you may also add them to the styles configuration above or import them in <i>styles.scss </i>so they go inside the bundle.</p>

            <h4>Component Themes</h4>
            <p>Mirage provides 24 PrimeNG themes out of the box featuring light and dark alternatives.</p>

            <ul>
                <li>blue/theme-light.scss</li>
                <li>blue/theme-dark.scss</li>
                <li>brown/theme-light.scss</li>
                <li>brown/theme-dark.scss</li>
                <li>cyan/theme-light.scss</li>
                <li>cyan/theme-dark.scss</li>
                <li>green/theme-light.scss</li>
                <li>green/theme-dark.scss</li>
                <li>indigo/theme-light.scss</li>
                <li>indigo/theme-dark.scss</li>
                <li>lime/theme-light.scss</li>
                <li>lime/theme-dark.scss</li>
                <li>magenta/theme-light.scss</li>
                <li>magenta/theme-dark.scss</li>
                <li>orange/theme-light.scss</li>
                <li>orange/theme-dark.scss</li>
                <li>purple/theme-light.scss</li>
                <li>purple/theme-dark.scss</li>
                <li>red/theme-light.scss</li>
                <li>red/theme-dark.scss</li>
                <li>teal/theme-light.scss</li>
                <li>teal/theme-dark.scss</li>
                <li>yellow/theme-light.scss</li>
                <li>yellow/theme-dark.scss</li>
            </ul>

            <p>A custom theme can be developed by the following steps.</p>
            <ul>
                <li>Choose a custom theme name such as "mytheme".</li>
                <li>Create a folder named "mytheme" under <i>assets/theme folder</i>.</li>
                <li>Create a file such as theme.scss under <i>assets/theme/mytheme folder</i>.</li>
                <li>Define the variables listed below in your file.</li>
                <li>Import either <i>../../sass/theme/_theme_light</i> or <i>../../sass/theme/_theme_dark</i> based on the type of your theme.</li>
                <li>Add the <i>theme.scss</i> to your project at <i>styles</i> section of angular.json or import via <i>styles.scss</i>.</li>
            </ul>

            <p>Here are the variables required to create a theme.</p>
<div style="height:400px;overflow: auto;">
<app-code ngPreserveWhitespaces ngNonBindable lang="css">
//main
$primaryColor:#5E81AC;
$primaryTextColor:#ffffff;

//highlight
$highlightBgColor:$primaryColor;
$highlightTextColor:$primaryTextColor;

//input
$inputFocusBorderColor:#9EB3CD;
$inputFocusBorderShadowColor:rgba(22,107,212,.25);

//primary button
$primaryButtonTextColor:#ffffff;
$primaryButtonBgColor:#5E81AC;
$primaryButtonBorderColor:#5E81AC;
$primaryButtonHoverBgColor:#466489;
$primaryButtonHoverBorderColor:#466489;
$primaryButtonActiveBgColor:#263951;
$primaryButtonActiveBorderColor:#263951;
$primaryButtonFocusBorderShadow:rgba(22,107,212,.5);

@import '../../sass/variables/_theme_light';
@import '../../sass/theme/_theme';
</app-code>
</div>

            <p>In case you'd like to manually build the css instead of using CLI, an example sass command to compile the css would be;</p>

<app-code ngPreserveWhitespaces ngNonBindable lang="markup">
sass src/assets/theme/mytheme/theme.scss src/assets/theme/mytheme/theme.css
</app-code>

            <p>Watch mode is handy to avoid compiling everytime when a change is made, instead use the following command
            so that sass generates the file whenever you make a customization. This builds all css files whenever a change is made to any scss file.</p>
<app-code ngPreserveWhitespaces ngNonBindable lang="markup">
sass --watch src/:src/ --no-source-map
</app-code>

            <h4>Layout Theme</h4>
            <p>There are 24 layout colors in total with light and dark alternatives which is enabled by including the <i>layout-*.scss</i> file to the project such as <i>blue/layout-light.scss.</i>.
            Note that the primary colors are not utilized when a custom menu theme is used.</p>

            <ul>
                <li>blue/layout-light.scss</li>
                <li>blue/layout-dark.scss</li>
                <li>brown/layout-light.scss</li>
                <li>brown/layout-dark.scss</li>
                <li>cyan/layout-light.scss</li>
                <li>cyan/layout-dark.scss</li>
                <li>green/layout-light.scss</li>
                <li>green/layout-dark.scss</li>
                <li>indigo/layout-light.scss</li>
                <li>indigo/layout-dark.scss</li>
                <li>lime/layout-light.scss</li>
                <li>lime/layout-dark.scss</li>
                <li>magenta/layout-light.scss</li>
                <li>magenta/layout-dark.scss</li>
                <li>orange/layout-light.scss</li>
                <li>orange/layout-dark.scss</li>
                <li>purple/layout-light.scss</li>
                <li>purple/layout-dark.scss</li>
                <li>red/layout-light.scss</li>
                <li>red/layout-dark.scss</li>
                <li>teal/layout-light.scss</li>
                <li>teal/layout-dark.scss</li>
                <li>yellow/layout-light.scss</li>
                <li>yellow/layout-dark.scss</li>
            </ul>

            <p>Creating a custom layout theme is similar to creating a custom PrimeNG Component Theme.</p>
            <ul>
                <li>Choose a layout name such as layout-myown.</li>
                <li>Create an empty file named layout-myown.scss inside <i>assets/layout/css</i> folder.</li>
                <li>Define the variables listed below in your file.</li>
                <li>Import either <i>../../../sass/variables/_layout_light</i> or <i>../../../sass/variables/_layout_dark</i> based on the type of your theme.</li>
                <li>Import main <i>../../../sass/layout/_layout</i> file.</li>
                <li>Add the <i>layout-myown.scss</i> to your project at <i>styles.scss</i> section of angular.json or import via <i>styles.scss</i>.</li>
            </ul>

            <p>Here are the variables required to create a layout.</p>
<app-code ngPreserveWhitespaces ngNonBindable lang="css">
$primaryColor:#5e81ac;
$primaryLightColor:#809bbd;
$primaryTextColor:#ffffff;

@import '../../../sass/variables/_layout_light';
@import '../../../sass/layout/_layout';
</app-code>

            <p><i>_layout_light.scss</i> contains the shared variables of the layout themes with a light color scheme.</p>
<app-code ngPreserveWhitespaces ngNonBindable lang="css">
$fontSize:14px;
$fontFamily:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$transitionDuration:.15s;
$letterSpacing:normal;
$borderRadius:4px;
$mobileBreakpoint:991px;

$bodyBgColor:#eceff4;
$textColor:#4c566a;
$textSecondaryColor:#9199a9;

$topbarBgColor:#eceff4;
$breadcrumbBgColor:#d8dee9;
$contentBgColor:#ffffff;
$contentAltBgColor:#f9fafb;
$contentAltBorderColor:#e5e9f0;
$dividerColor:rgba(145,153,169,0.3);
$menuShadow:3px 0 5px 0 rgba(33, 35, 39, 0.15);

@import '../overrides/layout_variables';
</app-code>

            <p><i>_layout_dark.scss</i> contains the shared variables of the layout themes with a light color scheme.</p>
<app-code ngPreserveWhitespaces ngNonBindable lang="css">
$fontSize:14px;
$fontFamily:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$transitionDuration:.15s;
$letterSpacing:normal;
$borderRadius:4px;
$mobileBreakpoint:991px;

$bodyBgColor:#2e3440;
$textColor:#d8dee9;
$textSecondaryColor:#9199a9;

$topbarBgColor:#2e3440;
$breadcrumbBgColor:#20252e;
$contentBgColor:#20252e;
$contentAltBgColor:#2e3440;
$contentAltBorderColor:#434c5e;
$dividerColor:rgba(90,101,124,0.3);
$menuShadow:3px 0 5px 0 rgba(33, 35, 39, 0.3);

@import '../overrides/layout_variables';
</app-code>
            
            <p><i>theme/_variables_light.scss</i> contains the shared variables of the component themes with a light color scheme.</p>
<div style="max-height: 400px; overflow:auto">
<app-code ngPreserveWhitespaces ngNonBindable lang="css">
$colors: (
    "blue": #2196F3,
    "green": #4caf50,
    "yellow": #FBC02D,
    "cyan": #00BCD4,
    "pink": #E91E63,
    "indigo": #3F51B5,
    "teal": #009688,
    "orange": #F57C00,
    "bluegray": #607D8B,
    "purple": #9C27B0,
    "primary": $primaryColor
) !default;

//reused color variables
$shade000:#ffffff;              //surface
$shade100:#efefef;              //header background
$shade200:#e9ecef;              //hover background
$shade300:#dee2e6;              //border, divider
$shade400:#ced4da;              //input border
$shade500:#adb5bd;              //unused
$shade600:#6c757d;              //text secondary color
$shade700:#495057;              //input text color
$shade800:#343a40;              //unused
$shade900:#212529;              //text color

//global
$fontFamily:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$fontSize:1rem;
$fontWeight:normal;
$textColor:$shade900;
$textSecondaryColor:$shade600;
$borderRadius:4px;
$transitionDuration:.15s;
$formElementTransition:background-color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration;
$actionIconTransition:box-shadow $transitionDuration;
$listItemTransition:box-shadow $transitionDuration;
$primeIconFontSize:1rem;
$divider:1px solid $shade300;
$inlineSpacing:.5rem;
$disabledOpacity:.65;
$maskBg:rgba(0, 0, 0, 0.4);
$loadingIconFontSize:2rem;
$errorColor:#dc3545;

//scale
$scaleSM:0.875;
$scaleLG:1.25;

//focus
$focusOutlineColor:$primaryLightColor;
$focusOutline:0 none;
$focusOutlineOffset:0;
$focusShadow:0 0 0 0.2rem $focusOutlineColor;

//action icons
$actionIconWidth:2rem;
$actionIconHeight:2rem;
$actionIconBg:transparent;
$actionIconBorder:0 none;
$actionIconColor:$shade600;
$actionIconHoverBg:transparent;
$actionIconHoverBorderColor:transparent;
$actionIconHoverColor:$shade700;
$actionIconBorderRadius:50%;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding:.5rem .75rem;
$inputTextFontSize:1rem;
$inputBg:$shade000;
$inputTextColor:$shade700;
$inputIconColor:$shade700;
$inputBorder:1px solid $shade400;
$inputHoverBorderColor:$shade400;
$inputFocusBorderColor:$primaryColor;
$inputErrorBorderColor:$errorColor;
$inputPlaceholderTextColor:$shade600;
$inputFilledBg:$shade100;
$inputFilledHoverBg:$shade100;
$inputFilledFocusBg:$shade100;

//input groups
$inputGroupBg:$shade200;
$inputGroupTextColor:$shade700;
$inputGroupAddOnMinWidth:2.357rem;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg:$shade000;
$inputListTextColor:$shade900;
$inputListBorder:$inputBorder;
$inputListPadding:.5rem 0;
$inputListItemPadding:.5rem 1.5rem;
$inputListItemBg:transparent;
$inputListItemTextColor:$shade900;
$inputListItemHoverBg:$shade200;
$inputListItemTextHoverColor:$shade900;
$inputListItemBorder:0 none;
$inputListItemBorderRadius:0;
$inputListItemMargin:0;
$inputListItemFocusShadow:inset 0 0 0 0.15rem $focusOutlineColor;
$inputListHeaderPadding:.75rem 1.5rem;
$inputListHeaderMargin:0;
$inputListHeaderBg:$shade100;
$inputListHeaderTextColor:$shade900;
$inputListHeaderBorder:1px solid $shade300;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg:$inputListBg;
$inputOverlayHeaderBg:$inputListHeaderBg;
$inputOverlayBorder:1px solid rgba(0,0,0,.15);
$inputOverlayShadow:none;

//button
$buttonPadding:.5rem .75rem;
$buttonIconOnlyWidth:2.357rem;
$buttonIconOnlyPadding:.5rem 0;
$buttonBg:$primaryColor;
$buttonTextColor:$primaryTextColor;
$buttonBorder:1px solid $primaryColor;
$buttonHoverBg:$primaryDarkColor;
$buttonTextHoverColor:$primaryTextColor;
$buttonHoverBorderColor:$primaryDarkColor;
$buttonActiveBg:$primaryDarkerColor;
$buttonTextActiveColor:$primaryTextColor;
$buttonActiveBorderColor:$primaryDarkerColor;
$raisedButtonShadow:0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
$roundedButtonBorderRadius:2rem;

$textButtonHoverBgOpacity:.04;
$textButtonActiveBgOpacity:.16;
$outlinedButtonBorder:1px solid;
$plainButtonTextColor:$textSecondaryColor;
$plainButtonHoverBgColor:$shade200;
$plainButtonActiveBgColor:$shade300;

$secondaryButtonBg:#6c757d;
$secondaryButtonTextColor:#ffffff;
$secondaryButtonBorder:1px solid #6c757d;
$secondaryButtonHoverBg:#5a6268;
$secondaryButtonTextHoverColor:#ffffff;
$secondaryButtonHoverBorderColor:#5a6268;
$secondaryButtonActiveBg:#545b62;
$secondaryButtonTextActiveColor:#ffffff;
$secondaryButtonActiveBorderColor:#4e555b;
$secondaryButtonFocusShadow:0 0 0 0.2rem rgba(130,138,145,.5);

$infoButtonBg:#17a2b8;
$infoButtonTextColor:#ffffff;
$infoButtonBorder:1px solid #17a2b8;
$infoButtonHoverBg:#138496;
$infoButtonTextHoverColor:#ffffff;
$infoButtonHoverBorderColor:#117a8b;
$infoButtonActiveBg:#138496;
$infoButtonTextActiveColor:#ffffff;
$infoButtonActiveBorderColor:#117a8b;
$infoButtonFocusShadow:0 0 0 0.2rem rgba(58,176,195,.5);

$successButtonBg:#28a745;
$successButtonTextColor:#ffffff;
$successButtonBorder:1px solid #28a745;
$successButtonHoverBg:#218838;
$successButtonTextHoverColor:#ffffff;
$successButtonHoverBorderColor:#1e7e34;
$successButtonActiveBg:#1e7e34;
$successButtonTextActiveColor:#ffffff;
$successButtonActiveBorderColor:#1c7430;
$successButtonFocusShadow:0 0 0 0.2rem rgba(72,180,97,.5);

$warningButtonBg:#ffc107;
$warningButtonTextColor:#212529;
$warningButtonBorder:1px solid #ffc107;
$warningButtonHoverBg:#e0a800;
$warningButtonTextHoverColor:#212529;
$warningButtonHoverBorderColor:#d39e00;
$warningButtonActiveBg:#d39e00;
$warningButtonTextActiveColor:#212529;
$warningButtonActiveBorderColor:#c69500;
$warningButtonFocusShadow:0 0 0 0.2rem rgba(222,170,12,.5);

$helpButtonBg:#6f42c1;
$helpButtonTextColor:#ffffff;
$helpButtonBorder:1px solid #6f42c1;
$helpButtonHoverBg:#633bad;
$helpButtonTextHoverColor:#ffffff;
$helpButtonHoverBorderColor:#58349a;
$helpButtonActiveBg:#58349a;
$helpButtonTextActiveColor:#ffffff;
$helpButtonActiveBorderColor:#4d2e87;
$helpButtonFocusShadow:0 0 0 0.2rem #d3c6ec;

$dangerButtonBg:#dc3545;
$dangerButtonTextColor:#ffffff;
$dangerButtonBorder:1px solid #dc3545;
$dangerButtonHoverBg:#c82333;
$dangerButtonTextHoverColor:#ffffff;
$dangerButtonHoverBorderColor:#bd2130;
$dangerButtonActiveBg:#bd2130;
$dangerButtonTextActiveColor:#ffffff;
$dangerButtonActiveBorderColor:#b21f2d;
$dangerButtonFocusShadow:0 0 0 0.2rem rgba(225,83,97,.5);

$linkButtonColor:$primaryColor;
$linkButtonHoverColor:$primaryDarkColor;
$linkButtonTextHoverDecoration:underline;
$linkButtonFocusShadow:0 0 0 0.2rem $focusOutlineColor;

//checkbox
$checkboxWidth:20px;
$checkboxHeight:20px;
$checkboxBorder:2px solid $shade400;
$checkboxIconFontSize:14px;
$checkboxActiveBorderColor:$primaryColor;
$checkboxActiveBg:$primaryColor;
$checkboxIconActiveColor:$primaryTextColor;
$checkboxActiveHoverBg:$primaryDarkerColor;
$checkboxIconActiveHoverColor:$primaryTextColor;
$checkboxActiveHoverBorderColor:$primaryDarkerColor;

//radiobutton
$radiobuttonWidth:20px;
$radiobuttonHeight:20px;
$radiobuttonBorder:2px solid $shade400;
$radiobuttonIconSize:12px;
$radiobuttonActiveBorderColor:$primaryColor;
$radiobuttonActiveBg:$primaryColor;
$radiobuttonIconActiveColor:$primaryTextColor;
$radiobuttonActiveHoverBg:$primaryDarkerColor;
$radiobuttonIconActiveHoverColor:$primaryTextColor;
$radiobuttonActiveHoverBorderColor:$primaryDarkerColor;

//colorpicker
$colorPickerPreviewWidth:2rem;
$colorPickerPreviewHeight:2rem;
$colorPickerBg:#212529;
$colorPickerBorderColor:#212529;
$colorPickerHandleColor:$shade000;

//togglebutton
$toggleButtonBg:#6c757d;
$toggleButtonBorder:1px solid #6c757d;
$toggleButtonTextColor:#ffffff;
$toggleButtonIconColor:#ffffff;
$toggleButtonHoverBg:#5a6268;
$toggleButtonHoverBorderColor:#545b62;
$toggleButtonTextHoverColor:#ffffff;
$toggleButtonIconHoverColor:#ffffff;
$toggleButtonActiveBg:#545b62;
$toggleButtonActiveBorderColor:#4e555b;
$toggleButtonTextActiveColor:#ffffff;
$toggleButtonIconActiveColor:#ffffff;
$toggleButtonActiveHoverBg:#545b62;
$toggleButtonActiveHoverBorderColor:#4e555b;
$toggleButtonTextActiveHoverColor:#ffffff;
$toggleButtonIconActiveHoverColor:#ffffff;

//inplace
$inplacePadding:$inputPadding;
$inplaceHoverBg:$shade200;
$inplaceTextHoverColor:$shade900;

//rating
$ratingIconFontSize:1.143rem;
$ratingCancelIconColor:#dc3545;
$ratingCancelIconHoverColor:#dc3545;
$ratingStarIconOffColor:$shade700;
$ratingStarIconOnColor:$primaryColor;
$ratingStarIconHoverColor:$primaryColor;

//slider
$sliderBg:$shade200;
$sliderBorder:0 none;
$sliderHorizontalHeight:.286rem;
$sliderVerticalWidth:0.286rem;
$sliderHandleWidth:1.143rem;
$sliderHandleHeight:1.143rem;
$sliderHandleBg:$primaryColor;
$sliderHandleBorder:2px solid $primaryColor;
$sliderHandleBorderRadius:$borderRadius;
$sliderHandleHoverBorderColor:$primaryDarkColor;
$sliderHandleHoverBg:$primaryDarkColor;
$sliderRangeBg:$primaryColor;

//calendar
$calendarTableMargin:.5rem 0;
$calendarPadding:0;
$calendarBg:$shade000;
$calendarInlineBg:$calendarBg;
$calendarTextColor:$shade900;
$calendarBorder:$inputListBorder;
$calendarOverlayBorder:$inputOverlayBorder;

$calendarHeaderPadding:.5rem;
$calendarHeaderBg:$shade100;
$calendarInlineHeaderBg:$calendarBg;
$calendarHeaderBorder:1px solid $shade300;
$calendarHeaderTextColor:$shade900;
$calendarHeaderFontWeight:600;
$calendarHeaderCellPadding:.5rem;

$calendarCellDatePadding:.5rem;
$calendarCellDateWidth:2.5rem;
$calendarCellDateHeight:2.5rem;
$calendarCellDateBorderRadius:$borderRadius;
$calendarCellDateBorder:1px solid transparent;
$calendarCellDateHoverBg:$shade200;
$calendarCellDateTodayBg:$shade400;
$calendarCellDateTodayBorderColor:transparent;
$calendarCellDateTodayTextColor:$shade900;

$calendarButtonBarPadding:1rem 0;
$calendarTimePickerPadding:.5rem;
$calendarTimePickerElementPadding:0 .5rem;
$calendarTimePickerTimeFontSize:1.25rem;

$calendarBreakpoint:769px;
$calendarCellDatePaddingSM:0;

//input switch
$inputSwitchWidth:3rem;
$inputSwitchHeight:1.75rem;
$inputSwitchBorderRadius:$borderRadius;
$inputSwitchHandleWidth:1.250rem;
$inputSwitchHandleHeight:1.250rem;
$inputSwitchHandleBorderRadius:$borderRadius;
$inputSwitchSliderPadding:.25rem;
$inputSwitchSliderOffBg:$shade400;
$inputSwitchHandleOffBg:$shade000;
$inputSwitchSliderOffHoverBg:$shade400;
$inputSwitchSliderOnBg:$primaryColor;
$inputSwitchSliderOnHoverBg:$primaryColor;
$inputSwitchHandleOnBg:$shade000;

//panel
$panelHeaderBorder:1px solid $shade300;
$panelHeaderBg:$shade100;
$panelHeaderTextColor:$shade900;
$panelHeaderFontWeight:600;
$panelHeaderPadding:1rem 1.25rem;
$panelToggleableHeaderPadding:.5rem 1.25rem;

$panelHeaderHoverBg:$shade200;
$panelHeaderHoverBorderColor:$shade300;
$panelHeaderTextHoverColor:$shade900;

$panelContentBorder:1px solid $shade300;
$panelContentBg:$shade000;
$panelContentTextColor:$shade900;
$panelContentPadding:1.25rem;

$panelFooterBorder:1px solid $shade300;
$panelFooterBg:$shade000;
$panelFooterTextColor:$shade900;
$panelFooterPadding:.5rem 1.25rem;

//accordion
$accordionSpacing:0;
$accordionHeaderBorder:$panelHeaderBorder;
$accordionHeaderBg:$panelHeaderBg;
$accordionHeaderTextColor:$panelHeaderTextColor;
$accordionHeaderFontWeight:$panelHeaderFontWeight;
$accordionHeaderPadding:$panelHeaderPadding;

$accordionHeaderHoverBg:$shade200;
$accordionHeaderHoverBorderColor:$shade300;
$accordionHeaderTextHoverColor:$shade900;

$accordionHeaderActiveBg:$panelHeaderBg;
$accordionHeaderActiveBorderColor:$shade300;
$accordionHeaderTextActiveColor:$shade900;

$accordionHeaderActiveHoverBg:$shade200;
$accordionHeaderActiveHoverBorderColor:$shade300;
$accordionHeaderTextActiveHoverColor:$shade900;

$accordionContentBorder:$panelContentBorder;
$accordionContentBg:$panelContentBg;
$accordionContentTextColor:$panelContentTextColor;
$accordionContentPadding:$panelContentPadding;

//tabview
$tabviewNavBorder:1px solid #dee2e6;
$tabviewNavBorderWidth:0 0 1px 0;
$tabviewNavBg:transparent;

$tabviewHeaderSpacing:0;
$tabviewHeaderBorder:solid;
$tabviewHeaderBorderWidth:1px;
$tabviewHeaderBorderColor:$shade000 $shade000 #dee2e6 $shade000;
$tabviewHeaderBg:$shade000;
$tabviewHeaderTextColor:$shade600;
$tabviewHeaderFontWeight:$panelHeaderFontWeight;
$tabviewHeaderPadding:.75rem 1rem;
$tabviewHeaderMargin:0 0 -1px 0;

$tabviewHeaderHoverBg:$shade000;
$tabviewHeaderHoverBorderColor:#dee2e6;
$tabviewHeaderTextHoverColor:$shade600;

$tabviewHeaderActiveBg:$shade000;
$tabviewHeaderActiveBorderColor:#dee2e6 #dee2e6 $shade000 #dee2e6;
$tabviewHeaderTextActiveColor:$shade700;

$tabviewContentBorder:0 none;
$tabviewContentBg:$panelContentBg;
$tabviewContentTextColor:$panelContentTextColor;
$tabviewContentPadding:$panelContentPadding;

//upload
$fileUploadProgressBarHeight:.25rem;
$fileUploadContentPadding:2rem 1rem;

//scrollpanel
$scrollPanelTrackBorder:0 none;
$scrollPanelTrackBg:$shade100;

//card
$cardBodyPadding:1.5rem;
$cardTitleFontSize:1.5rem;
$cardTitleFontWeight:700;
$cardSubTitleFontWeight:400;
$cardSubTitleColor:$shade600;
$cardContentPadding:1rem 0;
$cardFooterPadding:1rem 0 0 0;
$cardShadow:0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);

//editor
$editorToolbarBg:$panelHeaderBg;
$editorToolbarBorder:$panelHeaderBorder;
$editorToolbarPadding:$panelHeaderPadding;
$editorToolbarIconColor:$textSecondaryColor;
$editorToolbarIconHoverColor:$textColor;
$editorIconActiveColor:$primaryColor;
$editorContentBorder:$panelContentBorder;
$editorContentBg:$panelContentBg;

//paginator
$paginatorBg:$shade000;
$paginatorTextColor:$primaryColor;
$paginatorBorder:solid $shade300;
$paginatorBorderWidth:0;
$paginatorPadding:.75rem;
$paginatorElementWidth:$buttonIconOnlyWidth;
$paginatorElementHeight:$buttonIconOnlyWidth;
$paginatorElementBg:$shade000;
$paginatorElementBorder:1px solid #dee2e6;
$paginatorElementIconColor:$primaryColor;
$paginatorElementHoverBg:$shade200;
$paginatorElementHoverBorderColor:#dee2e6;
$paginatorElementIconHoverColor:$primaryColor;
$paginatorElementBorderRadius:0;
$paginatorElementMargin:0 0 0 -1px;
$paginatorElementPadding:0;

//table
$tableHeaderBorder:solid #dee2e6;
$tableHeaderBorderWidth:1px 0 0 0;
$tableHeaderBg:$shade100;
$tableHeaderTextColor:$shade900;
$tableHeaderFontWeight:600;
$tableHeaderPadding:1rem 1rem;

$tableHeaderCellPadding:1rem 1rem;
$tableHeaderCellBg:$shade000;
$tableHeaderCellTextColor:$shade900;
$tableHeaderCellFontWeight:600;
$tableHeaderCellBorder:1px solid #dee2e6;
$tableHeaderCellBorderWidth:1px 0 2px 0;
$tableHeaderCellHoverBg:$shade200;
$tableHeaderCellTextHoverColor:$shade900;
$tableHeaderCellIconColor:$shade600;
$tableHeaderCellIconHoverColor:$shade600;
$tableHeaderCellHighlightBg:$shade000;
$tableHeaderCellHighlightTextColor:$primaryColor;
$tableHeaderCellHighlightHoverBg:$shade200;
$tableHeaderCellHighlightTextHoverColor:$primaryColor;
$tableSortableColumnBadgeSize:1.143rem;

$tableBodyRowBg:$shade000;
$tableBodyRowTextColor:$shade900;
$tableBodyRowEvenBg:rgba(0,0,0,.05);
$tableBodyRowHoverBg:$shade200;
$tableBodyRowTextHoverColor:$shade900;
$tableBodyCellBorder:1px solid #dee2e6;
$tableBodyCellBorderWidth:1px 0 0 0;
$tableBodyCellPadding:1rem 1rem;

$tableFooterCellPadding:1rem 1rem;
$tableFooterCellBg:$shade000;
$tableFooterCellTextColor:$shade900;
$tableFooterCellFontWeight:600;
$tableFooterCellBorder:1px solid #dee2e6;
$tableFooterCellBorderWidth:1px 0 1px 0;
$tableResizerHelperBg:$primaryColor;

$tableFooterBorder:1px solid #dee2e6;
$tableFooterBorderWidth:1px 0 1px 0;
$tableFooterBg:$shade100;
$tableFooterTextColor:$shade900;
$tableFooterFontWeight:600;
$tableFooterPadding:1rem 1rem;

$tableCellContentAlignment:left;
$tableTopPaginatorBorderWidth:1px 0 0 0;
$tableBottomPaginatorBorderWidth:1px 0 0 0;

$tableScaleSM:0.5;
$tableScaleLG:1.25;

//dataview
$dataViewContentPadding:0;
$dataViewContentBorder:0 none;
$dataViewListItemBorder:1px solid $shade300;
$dataViewListItemBorderWidth:1px 0 0 0;

//orderlist, picklist
$orderListBreakpoint:769px;
$pickListBreakpoint:769px;

//schedule
$fullCalendarEventBg:$primaryDarkColor;
$fullCalendarEventBorder:1px solid $primaryDarkColor;
$fullCalendarEventTextColor:$primaryTextColor;

//tree
$treeContainerPadding:0.286rem;
$treeNodePadding:0.143rem;
$treeNodeContentPadding:.5rem;
$treeNodeChildrenPadding:0 0 0 1rem;
$treeNodeIconColor:$shade600;

//timeline
$timelineVerticalEventContentPadding:0 1rem !default;
$timelineHorizontalEventContentPadding:1rem 0 !default;
$timelineEventMarkerWidth:1rem !default;
$timelineEventMarkerHeight:1rem !default;
$timelineEventMarkerBorderRadius:50% !default;
$timelineEventMarkerBorder:0 none !default;
$timelineEventMarkerBackground:$primaryColor !default;
$timelineEventConnectorSize:2px !default;
$timelineEventColor:$shade300 !default;

//org chart
$organizationChartConnectorColor:$shade300;

//message
$messageMargin:1rem 0;
$messagePadding:1rem 1.25rem;
$messageBorderWidth:1px;
$messageIconFontSize:1.5rem;
$messageTextFontSize:1rem;
$messageTextFontWeight:500;

//inline message
$inlineMessagePadding:$inputPadding;
$inlineMessageMargin:0;
$inlineMessageIconFontSize:1rem;
$inlineMessageTextFontSize:1rem;
$inlineMessageBorderWidth:0px;

//toast
$toastIconFontSize:2rem;
$toastMessageTextMargin:0 0 0 1rem;
$toastMargin:0 0 1rem 0;
$toastPadding:1rem;
$toastBorderWidth:0;
$toastShadow:0 0.25rem 0.75rem rgba(0,0,0,.1);
$toastOpacity:1;
$toastTitleFontWeight:700;
$toastDetailMargin:$inlineSpacing 0 0 0;

//severities
$infoMessageBg:#cce5ff;
$infoMessageBorder:solid #b8daff;
$infoMessageTextColor:#004085;
$infoMessageIconColor:#004085;
$successMessageBg:#d4edda;
$successMessageBorder:solid #c3e6cb;
$successMessageTextColor:#155724;
$successMessageIconColor:#155724;
$warningMessageBg:#fff3cd;
$warningMessageBorder:solid #ffeeba;
$warningMessageTextColor:#856404;
$warningMessageIconColor:#856404;
$errorMessageBg:#f8d7da;
$errorMessageBorder:solid #f5c6cb;
$errorMessageTextColor:#721c24;
$errorMessageIconColor:#721c24;

//overlays
$overlayContentBorder:1px solid rgba(0,0,0,.2);
$overlayContentBg:$panelContentBg;
$overlayContainerShadow:none;

//dialog
$dialogHeaderBg:$shade000;
$dialogHeaderBorder:1px solid $shade200;
$dialogHeaderTextColor:$shade900;
$dialogHeaderFontWeight:600;
$dialogHeaderFontSize:1.25rem;
$dialogHeaderPadding:1rem;
$dialogContentPadding:1rem;
$dialogFooterBorder:1px solid $shade200;
$dialogFooterPadding:1rem;

//tooltip
$tooltipBg:$shade900;
$tooltipTextColor:$shade000;
$tooltipPadding:$inputPadding;

//steps
$stepsItemBg:transparent;
$stepsItemBorder:1px solid $shade300;
$stepsItemTextColor:$shade600;
$stepsItemNumberWidth:2rem;
$stepsItemNumberHeight:2rem;
$stepsItemNumberFontSize:1.143rem;
$stepsItemNumberColor:$shade900;
$stepsItemNumberBorderRadius:$borderRadius;
$stepsItemActiveFontWeight:600;

//progressbar
$progressBarHeight:1.5rem;
$progressBarBorder:0 none;
$progressBarBg:$shade200;
$progressBarValueBg:$primaryColor;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth:12.5rem;
$menuBg:$shade000;
$menuBorder:1px solid $shade300;
$menuTextColor:$shade900;
$menuitemPadding:.75rem 1rem;
$menuitemBorderRadius:0;
$menuitemTextColor:$shade900;
$menuitemIconColor:$shade900;
$menuitemTextHoverColor:$shade900;
$menuitemIconHoverColor:$shade900;
$menuitemHoverBg:$shade200;
$menuitemTextActiveColor:$shade900;
$menuitemIconActiveColor:$shade900;
$menuitemActiveBg:$shade200;
$menuitemSubmenuIconFontSize:.875rem;
$submenuHeaderMargin:0;
$submenuHeaderPadding:.75rem 1rem;
$submenuHeaderBg:$shade000;
$submenuHeaderTextColor:$shade900;
$submenuHeaderBorderRadius:0;
$submenuHeaderFontWeight:600;
$overlayMenuBg:$menuBg;
$overlayMenuBorder:1px solid rgba(0,0,0,.15);
$overlayMenuShadow:none;
$verticalMenuPadding:.5rem 0;
$verticalMenuitemMargin:0;
$menuSeparatorMargin:.5rem 0;

$breadcrumbPadding:1rem;
$breadcrumbBg:$shade100;
$breadcrumbBorder:0 none;
$breadcrumbItemTextColor:$primaryColor;
$breadcrumbItemIconColor:$primaryColor;
$breadcrumbLastItemTextColor:$shade600;
$breadcrumbLastItemIconColor:$shade600;
$breadcrumbSeparatorColor:$shade600;

$horizontalMenuPadding:.5rem 1rem;
$horizontalMenuBg:$shade100;
$horizontalMenuBorder:0 none;
$horizontalMenuTextColor:rgba(0,0,0,.9);
$horizontalMenuRootMenuitemPadding:1rem;
$horizontalMenuRootMenuitemBorderRadius:$borderRadius;
$horizontalMenuRootMenuitemTextColor:rgba(0,0,0,.5);
$horizontalMenuRootMenuitemIconColor:rgba(0,0,0,.5);
$horizontalMenuRootMenuitemTextHoverColor:rgba(0,0,0,.7);
$horizontalMenuRootMenuitemIconHoverColor:rgba(0,0,0,.7);
$horizontalMenuRootMenuitemHoverBg:transparent;
$horizontalMenuRootMenuitemTextActiveColor:rgba(0,0,0,.9);
$horizontalMenuRootMenuitemIconActiveColor:rgba(0,0,0,.9);
$horizontalMenuRootMenuitemActiveBg:transparent;

//badge and tag
$badgeBg:$primaryColor;
$badgeTextColor:$primaryTextColor;
$badgeMinWidth:1.5rem;
$badgeHeight:1.5rem;
$badgeFontWeight:700;
$badgeFontSize:.75rem;

$tagPadding:.25rem .4rem;

//carousel
$carouselIndicatorsPadding:1rem;
$carouselIndicatorBg:$shade200;
$carouselIndicatorHoverBg:$shade300;
$carouselIndicatorBorderRadius:0;
$carouselIndicatorWidth:2rem;
$carouselIndicatorHeight:.5rem;

//galleria
$galleriaMaskBg:rgba(0,0,0,0.9);
$galleriaCloseIconMargin:.5rem;
$galleriaCloseIconFontSize:2rem;
$galleriaCloseIconBg:transparent;
$galleriaCloseIconColor:$shade100;
$galleriaCloseIconHoverBg:rgba(255,255,255,0.1);
$galleriaCloseIconHoverColor:$shade100;
$galleriaCloseIconWidth:4rem;
$galleriaCloseIconHeight:4rem;
$galleriaCloseIconBorderRadius:$borderRadius;

$galleriaItemNavigatorBg:transparent;
$galleriaItemNavigatorColor:$shade100;
$galleriaItemNavigatorMargin:0 .5rem;
$galleriaItemNavigatorFontSize:2rem;
$galleriaItemNavigatorHoverBg:rgba(255,255,255,0.1);
$galleriaItemNavigatorHoverColor:$shade100;
$galleriaItemNavigatorWidth:4rem;
$galleriaItemNavigatorHeight:4rem;
$galleriaItemNavigatorBorderRadius:$borderRadius;

$galleriaCaptionBg:rgba(0,0,0,.5);
$galleriaCaptionTextColor:$shade100;
$galleriaCaptionPadding:1rem;

$galleriaIndicatorsPadding:1rem;
$galleriaIndicatorBg:$shade200;
$galleriaIndicatorHoverBg:$shade300;
$galleriaIndicatorBorderRadius:$borderRadius;
$galleriaIndicatorWidth:1rem;
$galleriaIndicatorHeight:1rem;
$galleriaIndicatorsBgOnItem:rgba(0,0,0,.5);
$galleriaIndicatorBgOnItem:rgba(255,255,255,.4);
$galleriaIndicatorHoverBgOnItem:rgba(255,255,255,.6);

$galleriaThumbnailContainerBg:rgba(0,0,0,.9);
$galleriaThumbnailContainerPadding:1rem .25rem;
$galleriaThumbnailNavigatorBg:transparent;
$galleriaThumbnailNavigatorColor:$shade100;
$galleriaThumbnailNavigatorHoverBg:rgba(255,255,255,0.1);
$galleriaThumbnailNavigatorHoverColor:$shade100;
$galleriaThumbnailNavigatorBorderRadius:$borderRadius;
$galleriaThumbnailNavigatorWidth:2rem;
$galleriaThumbnailNavigatorHeight:2rem;

//divider
$dividerHorizontalMargin:1rem 0;
$dividerHorizontalPadding:0 1rem;
$dividerVerticalMargin:0 1rem;
$dividerVerticalPadding:1rem 0;
$dividerSize:1px;
$dividerColor:$shade300;

//avatar
$avatarBg:$shade300;
$avatarTextColor:$textColor;

//chip
$chipBg:$shade300;
$chipTextColor:$textColor;
$chipBorderRadius: 16px;

//scrollTop
$scrollTopBg:rgba(0,0,0,0.7);
$scrollTopHoverBg:rgba(0,0,0,0.8);
$scrollTopWidth:3rem;
$scrollTopHeight:3rem;
$scrollTopBorderRadius:$borderRadius;
$scrollTopFontSize:1.5rem;
$scrollTopTextColor:$shade100;

//skeleton
$skeletonBg:$shade200;
$skeletonAnimationBg:rgba(255,255,255,0.4);

//splitter
$splitterGutterBg:$shade100;
$splitterGutterHandleBg:$shade300;

:root &#123;
--surface-a:#&#123;$shade000&#125;;
--surface-b:#&#123;$shade100&#125;;
--surface-c:#&#123;$shade200&#125;;
--surface-d:#&#123;$shade300&#125;;
--surface-e:#&#123;$shade000&#125;;
--surface-f:#&#123;$shade000&#125;;
--text-color:#&#123;$shade900&#125;;
--text-color-secondary:#&#123;$shade600&#125;;
--primary-color:#&#123;$primaryColor&#125;;
--primary-color-text:#&#123;$primaryTextColor&#125;;
--font-family:#&#123;$fontFamily&#125;;
&#125;
</app-code>
</div>

            <h4>Menu Modes</h4>
            <p>Menu has 3 modes; <b>static</b>, <b>overlay</b> and <b>horizontal</b>. Layout wrapper element in template.xhtml is used to define which mode to use by adding specific classes. Default
            is overlay and adding <i>layout-menu-horizontal</i> enables horizontal menu whereas for static menu use <i>ayout-wrapper-static</i>.</p>

<app-code  ngPreserveWhitespaces ngNonBindable lang="markup">
&lt;div class="layout-wrapper layout-menu-horizontal"&gt;
</app-code>

            <p>It is also possible to leave the choice to the user by keeping the preference at a component and using an ngClass expression so that user can switch between modes. Sample
                    application has an example implementation of such use case. Refer to <i>app.main.component.ts</i> for an example.</p>

            <h4>Light and Dark Menu</h4>
            <p>Menu theme is defined at the main container element, template uses the default light menus whereas a dark theme can be applied using the <i>layout-menu-dark</i> style class.</p>
<app-code  ngPreserveWhitespaces ngNonBindable lang="markup">
&lt;div class="layout-wrapper layout-menu-dark"&gt;
...
&lt;/div&gt;
</app-code>

            <h4>Custom Menu Themes</h4>
            <p>Light and Dark menus utilize the primary color of the chosen layout whereas custom menu themes listed below define their own design. Apply
                the following style classes for a custom menu theme.
            </p>
            <ul>
                <li>layout-menu-chile</li>
                <li>layout-menu-naples</li>
                <li>layout-menu-georgia</li>
                <li>layout-menu-infinity</li>
                <li>layout-menu-chicago</li>
                <li>layout-menu-majesty</li>
                <li>layout-menu-fish</li>
                <li>layout-menu-dawn</li>
                <li>layout-menu-record</li>
                <li>layout-menu-pool</li>
                <li>layout-menu-metal</li>
                <li>layout-menu-china</li>
                <li>layout-menu-table</li>
                <li>layout-menu-panorama</li>
                <li>layout-menu-barcelona</li>
                <li>layout-menu-underwater</li>
                <li>layout-menu-symmetry</li>
                <li>layout-menu-rain</li>
                <li>layout-menu-utah</li>
                <li>layout-menu-wave</li>
                <li>layout-menu-flora</li>
                <li>layout-menu-speed</li>
                <li>layout-menu-canopy</li>
                <li>layout-menu-sanpaolo</li>
                <li>layout-menu-basketball</li>
                <li>layout-menu-misty</li>
                <li>layout-menu-summit</li>
                <li>layout-menu-snow</li>
                <li>layout-menu-wall</li>
                <li>layout-menu-ferris</li>
                <li>layout-menu-ship</li>
                <li>layout-menu-ny</li>
                <li>layout-menu-cyan</li>
                <li>layout-menu-violet</li>
                <li>layout-menu-red</li>
                <li>layout-menu-blue</li>
                <li>layout-menu-porsuk</li>
                <li>layout-menu-pink</li>
                <li>layout-menu-purple</li>
                <li>layout-menu-orange</li>
            </ul>

<app-code  ngPreserveWhitespaces ngNonBindable lang="javascript">
&lt;div class="layout-wrapper layout-menu-chile"&gt;
...
&lt;/div&gt;
</app-code>

            <p>Creating a custom menu theme with the color scheme of your choice requires the following steps.</p>
            <ul>
                <li>Choose a menu theme such as myown.</li>
                <li>Create an empty file named myown.scss inside assets/sass/layout/menu/themes/ folder.</li>
                <li>Define the variables listed below and import the <i>/sass/layout/_layout.scss</i> file.</li>
                <li>Import <i>_menu_theme.scss</i> file.</li>
                <li>Import your file at <i>/assets/sass/layout/menu/_menu</i> file.</li>.
            </ul>

            <p>Here are the variables required to create a layout.</p>
<app-code ngPreserveWhitespaces ngNonBindable lang="css">
.layout-menu-myown &#123;
    $menuBgColor: #222932;
    $rootMenuitemTextColor:#eceff4;
    $rootMenuitemIconColor:#eceff4;
    $rootMenuitemHoverBgColor:rgba(255, 255, 255, 0.24);
    $activeRootMenuitemBgColor:#EBCB8B;
    $activeRootMenuitemTextColor:#3b4252;
    $activeRootMenuitemIconColor:#2e3440;
    $submenuBgColor:rgba(255, 255, 255, 0.12);
    $horizontalSubmenuBgColor:#2e3440;
    $submenuItemTextColor:#eceff4;
    $submenuItemIconColor:#eceff4;
    $submenuItemHoverBgColor:rgba(0, 0, 0, 0.42);
    $activeSubmenuItemTextColor:#EBCB8B;
    $activeSubmenuItemIconColor:#EBCB8B;
    $appNameTextColor:#ffffff;
    $pinBgColor:#ffffff;
    $pinIconColor:#2e3440;

    @import '../_menu_theme';
&#125;
</app-code>

            <p>An image can be used as the background of the menu using the menuBgImage variable.</p>
<app-code ngPreserveWhitespaces ngNonBindable lang="css">
.layout-menu-myown &#123;
    $menuBgColor: #222932;
    $menuBgImage:'myown.jpg';
    $horizontalMenuBgImage:'myown-horizontal.jpg';
    $rootMenuitemTextColor:#eceff4;
    $rootMenuitemIconColor:#eceff4;
    $rootMenuitemHoverBgColor:rgba(255, 255, 255, 0.24);
    $activeRootMenuitemBgColor:#EBCB8B;
    $activeRootMenuitemTextColor:#3b4252;
    $activeRootMenuitemIconColor:#2e3440;
    $submenuBgColor:rgba(255, 255, 255, 0.12);
    $horizontalSubmenuBgColor:#2e3440;
    $submenuItemTextColor:#eceff4;
    $submenuItemIconColor:#eceff4;
    $submenuItemHoverBgColor:rgba(0, 0, 0, 0.42);
    $activeSubmenuItemTextColor:#EBCB8B;
    $activeSubmenuItemIconColor:#EBCB8B;
    $appNameTextColor:#ffffff;
    $pinBgColor:#ffffff;
    $pinIconColor:#2e3440;

    @import '../_menu_theme';
&#125;
</app-code>

            <h4>Breadcrumb</h4>
            <p>Mirage has an optional built-in breadcrumb section right below the topbar.
                The items are dynamically generated using a BreadcrumbService where each main page that goes into the router-outlet should provide a collection of MenuItem instances.
                Here is an example component that updates the breadcrumb.</p>
<app-code  ngPreserveWhitespaces ngNonBindable lang="javascript">
import &#123;Component&#125; from '@angular/core';
import &#123;BreadcrumbService&#125; from '../../breadcrumb.service';

@Component(&#123;
    templateUrl: './controlpanel.component.html'
&#125;)
export class ControlPanelComponent &#123;

    constructor(private breadcrumbService: BreadcrumbService) &#123;
        this.breadcrumbService.setItems([
            &#123;label: 'Admin'&#125;,
            &#123;label: 'Control Panel', routerLink: ['/controlpanel']&#125;
        ]);
    &#125;

&#125;
</app-code>

            <h4>Grid CSS</h4>
            <p>Mirage uses PrimeFlex CSS throughout the demos such as Dashboard, however any Grid library can be used with it since Mirage itself does not depend on PrimeFlex except demo pages.</p>

            <h4>Customizing Styles</h4>
            <p>It is suggested to add your customizations in the following sass files under the override folder instead of adding them to the
                scss files under sass folder to avoid maintenance issues after an update.</p>

            <ul>
                <li><b>overrides/_layout_variables</b>: Variables of the layout.</li>
                <li><b>overrides/_layout_styles</b>: Styles for the layout.</li>
                <li><b>overrides/_theme_variables</b>: Variables of the theme.</li>
                <li><b>overrides/_theme_styles</b>: Styles for the theme.</li>
            </ul>

            <h4>Migration Guide</h4>
            <p>Every change is included in <b>CHANGELOG.md</b> file at the root folder of the distribution along with the instructions to update.</p>

        </div>
    </div>
</div>
