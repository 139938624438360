export class GblReportViewModel {
  ReportId: number;
  ReportUid: string;
  ReportName: string;
  ReportDescription: string;
  ReportUrl: string;
  ReportjobtypesettingId: number;
  JobtypeId?: number;
  JobtypelevelId?: number;
  ParametersettingId?: number;
  ParameterId?: number;
  IsVisible?: boolean;
  IsRequired?: boolean;
  IsDisable?: boolean;
  DefaultValue: string;
  DefaultSearchRangeDay?: number;
  ParameterMultiSelect?: boolean;
  ParameterUid: string;
  ParameterName: string;
  ParameterDisplayText: string;
  ParameterShortText: string;
  ParameterValueType: string;
  DisplayOrder: number;
  IsActive?: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  ParameterValueColumn?:string;
  JobTypeName?:string;
  JobTypeLevelName?:string;
}
