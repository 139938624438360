<div
  class="login-body"
  [ngClass]="{ 'layout-dark': !dark, 'layout-light': dark }"
  style="
    background: url('../../assets/layout/images/login_bg/2.1.png');
    background-repeat: no;
    background-size: cover;
    background-position: center;
  "
>
  <div class="login-content">
    <!-- <div class="night-switch">
      <p-button
        styleClass="dark-switch"
        icon="pi pi-circle-on"
        (click)="dark = false"
      ></p-button>
      <p-button
        styleClass="light-switch"
        icon="pi pi-circle-off"
        (click)="dark = true"
      ></p-button>
    </div> -->
    <div class="login-panel">
      <div class="login-panel-content">
        <div class="logo">
          <img src="assets/layout/images/logo.png" alt="mirage-layout" />
        </div>
        <h1>Sign in to RadPortal</h1>
        <p>Welcome. Please Sign in to the RadPortal Network.</p>
        <div class="forms">
          <div class="login-input-wrapper">
            <label for="username">Email</label>
            <!-- <input id="username" pInputText placeholder="Type your username..." /> -->
            <input
              pInputText
              [(ngModel)]="loginModel.Useremail"
              placeholder="Email"
              type="email"
              name="email"
              id="email"
              autocomplete="off"
            />
            <i class="pi pi-envelope"></i>
          </div>

          <div class="login-input-wrapper">
            <label for="password">Password</label>
            <!-- <input type="password" id="password" placeholder="Type your password" pPassword [feedback]="false"/> -->
            <input
              [(ngModel)]="loginModel.Password"
              placeholder="Password"
              type="password"
              name="password"
              id="password"
              autocomplete="off"
              pPassword
              [feedback]="false"
            />
            <i class="pi pi-lock"></i>
          </div>

          <div class="col-12">
            <p-checkbox
              [(ngModel)]="checked"
              label="Remember me"
              value="SaveInfo"
              binary="true"
            ></p-checkbox>
          </div>

          <div class="buttons">
            <button
              pButton
              type="submit"
              (click)="login()"
              label="Sign In"
            ></button>
            <!-- <button pButton type="button" [routerLink]="['/']" label="Login"></button> -->
            <a
              style="cursor: pointer"
              (click)="gotoForgetPassword()"
              class="secondary-button"
              >Forgot Password?</a
            >
          </div>
        </div>
        <div class="text-left mt-5">
          No account ?
          <a href="https://radportal.net/signup">Sign up</a>
        </div>
        <div class="text-left mt-3">
          <a style="cursor: pointer" (click)="goToLink()">Login with Line</a>
        </div>
      </div>
    </div>

    <div class="desert"></div>
  </div>
</div>

<!-- 
  <div class="envision-login">
    <div class="envision--wrapper">
        <div class="wrapper--inner">
            <div class="login-form-container">
                <div class="left">
                </div>
                <form action="" class="log-in" autocomplete="off">
                  <h4>We are <span>DIGITAL EXPERIENCE HEALTH CHECK</span></h4>
                  <p>Welcome back! Log in to your account to Digital Experience Health Check.</p>
                  <div class="floating-label">
                    <input  [(ngModel)]="loginModel.Useremail" placeholder="Email" type="email" name="email" id="email" autocomplete="off" />
                    <label for="email">Email:</label>
                    <div class="icon">
                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="floating-label password">
                    <input [(ngModel)]="loginModel.Password" placeholder="Password" type="password" name="password" id="password" autocomplete="off" />
                    <label for="password">Password:</label>
                    <div class="icon">
                      <i class="fa fa-lock" aria-hidden="true"></i>
                    </div>

                  </div>
                  <div  class="login-form-bottom">
                    <a *ngIf="isCloud" [routerLink]="['/signUp']" > Sign Up Now </a>
                    <button type="submit" (click)="login()">Log in</button>
                  </div>


                </form>
              </div>
        </div>
    </div>

    <div class="loading-overlay" *ngIf="showSpinner === true">
      <div class="loader">
        <p-progressSpinner >

          </p-progressSpinner>
      </div>
    </div>
</div> -->

<!-- <div *ngIf="isLoaderVisible" class="loading-overlay">
  <div class="loader"></div>
</div> -->

<div *ngIf="loaderVisible" class="loading-overlay">
  <div class="loader"></div>
</div>
