

<!-- <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="true"></p-steps>

<button *ngIf="activeIndex>=1" pButton (click)="PrevMenu()" label="Prev"></button>

<button  *ngIf="activeIndex<=items.length" pButton (click)="NextMenu()" label="Next"></button>


 <app-radiologist-phone-verification></app-radiologist-phone-verification>  -->

  <div class="singup">
    <form [formGroup]="radiogistForm">
      <p-panel styleClass="patient-information-panel" header="Radiologist Sign up">
        <div class="p-fluid p-formgrid grid">


          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Email</label>
              <div class="col-12 md:col-9">
                <input  *ngIf="disable==true" type="email" (ngModelChange)="EmmitRediologist()" disabled="true" pInputText formControlName="Email" [(ngModel)]="selectedEmail" />

                <input  *ngIf="disable==false" type="email" (ngModelChange)="EmmitRediologist()"  pInputText formControlName="Email" [(ngModel)]="selectedEmail" />
              </div>
            </div>
          </div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid signup-input-wrapper">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Password</label>
              <div class="col-12 md:col-9">
                <!-- <input type="password" pInputText formControlName="Password" 
                  /> -->

                  <p-password [(ngModel)]="selectedPassword" (ngModelChange)="EmmitRediologist()" formControlName="Password" [toggleMask]="true"></p-password>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid signup-input-wrapper">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Confirm Password</label>
              <div class="col-12 md:col-9">
                <!-- <input type="password" pInputText formControlName="Password" 
                  /> -->

                  <p-password [(ngModel)]="selectedConfirmPassword" (ngModelChange)="EmmitRediologist()" formControlName="ConfirmPassword" [toggleMask]="true"></p-password>
              </div>

              <div *ngIf="radiogistForm.controls['ConfirmPassword'].dirty && radiogistForm.hasError('mismatch')"
                  >
                  Password mismatch
                </div>
            </div>
          </div>

          
          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="firstname" class="col-12 mb-2 md:col-3 md:mb-0">First Name</label>
              <div class="col-12 md:col-9">
                <input type="text" pInputText formControlName="FName" (ngModelChange)="EmmitRediologist()" [(ngModel)]="selectedFName"
                placeholder="First Name" />
        
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>
        

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Last Name</label>
              <div class="col-12 md:col-9">
                <input type="text" pInputText formControlName="LName" (ngModelChange)="EmmitRediologist()"  [(ngModel)]="selectedLName"
                  placeholder="Last Name" />
              </div>
            </div>
          </div>
          <div class="col-12 md:col-1"></div>


          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Line Id</label>
              <div class="col-12 md:col-9">
                
                
                  <input  *ngIf="disable==true" type="text" disabled="true" (ngModelChange)="EmmitRediologist()" pInputText formControlName="LineId" [(ngModel)]="selectedLineId"/>
                  <input  *ngIf="disable==false" type="text" pInputText formControlName="LineId" (ngModelChange)="EmmitRediologist()" [(ngModel)]="selectedLineId"/>
                 
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Phone No</label>
              <div class="col-12 md:col-9">
                <input type="text" pInputText formControlName="PhoneNo" (ngModelChange)="EmmitRediologist()" [(ngModel)]="selectedPhoneNo"
                placeholder="PhoneNo" /> 
              </div>
            </div>
          </div>
          <div class="col-12 md:col-1"></div>


          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Country</label>
              <div class="col-12 md:col-9">
                
                  <!-- <input  type="text" pInputText formControlName="Country" /> -->

                  <p-dropdown [options]="countryList" [(ngModel)]="selectedCountry" formControlName="Country" (onChange)="loadProvince()"
                   placeholder="Select a country" optionLabel="CountryName" [showClear]="true"></p-dropdown>

              
        
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">District</label>
              <div class="col-12 md:col-9">
                <!-- <input type="text" pInputText formControlName="District" 
                placeholder="PhoneNo" />  -->

                <p-dropdown [options]="districtList" [(ngModel)]="selectedDistrict"  (onChange)="loadSubDistrict()" formControlName="District" placeholder="Select a District" optionLabel="DistrictName" [showClear]="true"></p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-1"></div>

          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Province</label>
              <div class="col-12 md:col-9">
                
                  <!-- <input  type="text" pInputText formControlName="Province" /> -->

                  <p-dropdown [options]="ProvinceList" [(ngModel)]="selectedProvince" (onChange)="loadDistrict()" formControlName="Province" placeholder="Select a Province" optionLabel="ProvinceName" [showClear]="true"></p-dropdown>
              
        
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Sub-District</label>
              <div class="col-12 md:col-9">
                <!-- <input type="text" pInputText formControlName="SubDistrict" 
                placeholder="PhoneNo" />  -->
                
                <p-dropdown [options]="SubDistrictList" [(ngModel)]="selectedSubDistrict" (onChange)="EmmitRediologist()" formControlName="SubDistrict" placeholder="Select a Sub-District" optionLabel="SubdistrictName" [showClear]="true"></p-dropdown>
              
              </div>
            </div>
          </div>
          <div class="col-12 md:col-1"></div>

          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Zip Code</label>
              <div class="col-12 md:col-9">
                <input type="text" pInputText formControlName="ZipCode" (ngModelChange)="EmmitRediologist()" [(ngModel)]="selectedZipCode"
                placeholder="Zip Code" /> 
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Subspecialties</label>
              <div class="col-12 md:col-9">
                <p-dropdown [options]="SubDistrictList" [(ngModel)]="selectedSubDistrict" (onChange)="EmmitRediologist()" formControlName="SubDistrict" placeholder="Select Subspecialties" optionLabel="name" [showClear]="true"></p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>
          
    
          <div class="col-12 md:col-2 rad-profile">
            <p-image [src]="profileImageUrl" alt="Image" width="250" [preview]="true"></p-image>
          </div>
          


        </div>

        <!-- <div class="p-formgrid grid">
          <div class="col-12 md:col-1"></div>

          <div class="col-12 md:col-2">
            <p-button class="mr-1" label="Cancel"></p-button>
  
            <p-button label="{{saveOrUpdateButtonLabel}}" [disabled]="!radiogistForm.valid" (onClick)="saveOrUpdateRediologist()"></p-button>
          </div>

          
        </div> -->
       
      </p-panel>


    </form> 
</div>
