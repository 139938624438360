import { RisOrderDtlModel } from "./ris-order-dtl.model";

export class RisModalityModel {
  modalityId: number;
  modalityUid: string;
  modalityName: string;
  modalityType?: number;
  allproperties: string;
  unitId?: number;
  isActive: string;
  startTime?: string;
  endTime?: string;
  avgInvTime?: number;
  roomId?: number;
  casePerDay?: number;
  restrictLevel: string;
  isUpdated: string;
  isDeleted: string;
  orgId?: number;
  createdBy?: number;
  createdOn?: string;
  lastModifiedBy?: number;
  lastModifiedOn?: string;
  isVisible: string;
  isDefault: boolean;
  patDestId?: number;
  announce: string;
  risOrderdtl: RisOrderDtlModel[];
}
