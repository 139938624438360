import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ExamDtoModel, GeneralResModel } from "src/app/_models";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ExamInfoService {
  private baseURL = `${environment.apiUrl}examInfo/`;

  constructor(private _httpClient: HttpClient) {}

  getAllExam(orgId: number) {
    return this._httpClient.get<GeneralResModel<ExamDtoModel[]>>(
      `${this.baseURL}GetAllExam/${orgId}`
    );
  }

  getExamById(examId: number) {
    return this._httpClient.get<GeneralResModel<ExamDtoModel>>(
      `${this.baseURL}GetExamById/${examId}`
    );
  }

  addExamInformation(request: ExamDtoModel) {
    return this._httpClient.post<GeneralResModel<ExamDtoModel>>(
      `${this.baseURL}AddExamInformation`,
      request
    );
  }

  updateExamInformation(request: ExamDtoModel) {
    return this._httpClient.post<GeneralResModel<ExamDtoModel>>(
      `${this.baseURL}UpdateExamInformation`,
      request
    );
  }
}
