import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from 'src/app/_models/user-models/user';
import { UserInfo } from 'src/app/_models/user-models/user-info.model';

export interface InternalStateType {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})

// @Injectable()
export class AppSessionState {
    public _state: InternalStateType = { };
    private _userInfo: UserInfo = null;
    constructor(private jwtHelperService: JwtHelperService) { }
    /**
     * Already return a clone of the current state.
     */
    public get state() {
        return this._state = this._clone(this._state);
    }
    /**
     * Never allow mutation
     */
    public set state(value) {
        throw new Error('do not mutate the `.state` directly');
    }

    public get(prop?: any) {
        /**
         * Use our state getter for the clone.
         */
        const state = this.state;
        return state.hasOwnProperty(prop) ? state[prop] : state;
    }

    public set(prop: string, value: any) {
        /**
         * Internally mutate our state.
         */
        return this._state[prop] = value;
    }

    private _clone(object: InternalStateType) {
        /**
         * Simple object clone.
         */
        return JSON.parse(JSON.stringify( object ));
    }



    setToken(token, user,loginDateTime)
    {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        // sessionStorage.setItem('user', JSON.stringify(user));
        // sessionStorage.setItem('loginTime', loginDateTime);
        localStorage.setItem('loginTime', loginDateTime);
    }

    getAccessToken()
    {
        return localStorage.getItem('token');
    }

    loggedIn() {
        const token = this.getAccessToken();
        if (!token) {
            return false;
        }

        return !this.jwtHelperService.isTokenExpired(token);

    }

    getSessionStorage() {
        return localStorage.getItem('user');
    }

    setUserToSessionStorage(user:User) {
        localStorage.removeItem('user');
        localStorage.setItem('user',JSON.stringify(user));
    }

    getUserLoginDateTime() {
        return localStorage.getItem('loginTime');
    }

    tokenExpired()
    {
        if(this.jwtHelperService.isTokenExpired(this.getAccessToken())){
            return true;
        }
        return false;

    }

}



// export class AppService {

//   constructor() { }
// }
