import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ExamImageQualityModel,
  ExamResultSeverityModel,
  GeneralResModel,
  OrderclinicalsignificanceModel,
  OrderScoreModel,
  ResetStudyReqModel,
  StudyReportingTemplateModel,
  StudyResetReasonResModel,
  StudyVersionResModel,
} from "src/app/_models";
import { ExamResult } from "src/app/_models/exam/exam-result.model";
import { StudyModel } from "src/app/_models/study.model";
import { WorklistQueryModel } from "src/app/_models/worklist-query-model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ExamResultService {
  private baseURL = `${environment.apiUrl}ExamResult/`;
  private baseImportURL = `${environment.apiUrl}Import/`;

  constructor(private _httpClient: HttpClient, private _datePipe: DatePipe) {}

  getExamImageQuality(orgId: number, examTypeId: number) {
    return this._httpClient.get<ExamImageQualityModel[]>(
      `${this.baseURL}getExamImageQuality/${orgId}/${examTypeId}`
    );
  }

  saveToStudyRisTemplate(request: StudyReportingTemplateModel) {
    return this._httpClient.post<boolean>(
      `${this.baseImportURL}saveToStudyRisTemplate`,
      request
    );
  }

  updateStudyRisTemplate(request: StudyReportingTemplateModel) {
    return this._httpClient.post<boolean>(
      `${this.baseImportURL}updateStudyRisTemplate`,
      request
    );
  }

  deleteStudyReportingTemplate(request: StudyReportingTemplateModel) {
    return this._httpClient.delete<boolean>(
      `${this.baseImportURL}deleteStudyReportingTemplate/${request.TemplateId}/${request.CreatedBy}/${request.OrgId}`
    );
  }

  getStudyReportTemplateList(orgId: number, userId: number, examId: number) {
    return this._httpClient.get<GeneralResModel<StudyReportingTemplateModel[]>>(
      `${this.baseImportURL}GetStudyReportTemplateList/${orgId}/${userId}/${examId}`
    );
  }

  getStudyReportTemplates(orgId: number, userId: number) {
    return this._httpClient.get<GeneralResModel<StudyReportingTemplateModel[]>>(
      `${this.baseImportURL}getStudyReportTemplates/${orgId}/${userId}`
    );
  }

  getStudyReportingTemplate(orgId: number, tempId: number) {
    return this._httpClient.get<GeneralResModel<StudyReportingTemplateModel>>(
      `${this.baseImportURL}GetStudyReportingTemplate/${orgId}/${tempId}`
    );
  }

  getSeverityList(orgId: number) {
    return this._httpClient.get<GeneralResModel<ExamResultSeverityModel[]>>(
      `${this.baseURL}getSeverityList/${orgId}`
    );
  }

  updateSeverity(request: ExamResult) {
    return this._httpClient.post<GeneralResModel<string>>(
      `${this.baseURL}UpdateSeverity`,
      request
    );
  }

  ClearSeverity(request: ExamResult) {
    return this._httpClient.post<GeneralResModel<string>>(
      `${this.baseURL}ClearSeverity`,
      request
    );
  }

  getOrderScoreList(orgId: number) {
    return this._httpClient.get<GeneralResModel<OrderScoreModel[]>>(
      `${this.baseURL}getOrderScoreList/${orgId}`
    );
  }

  getOrderClinicalSignificanceList(orgId: number) {
    return this._httpClient.get<
      GeneralResModel<OrderclinicalsignificanceModel[]>
    >(`${this.baseURL}getOrderClinicalSignificanceList/${orgId}`);
  }

  scoreClinicalCombination(orgId: number) {
    return this._httpClient.get<
      GeneralResModel<OrderclinicalsignificanceModel[]>
    >(`${this.baseURL}scoreClinicalCombination/${orgId}`);
  }

  examResultScoreUpdate(request: StudyModel) {
    return this._httpClient.post<GeneralResModel<StudyModel>>(
      `${this.baseURL}examResultScoreUpdate`,
      request
    );
  }

  examResultScoreUpdateClear(request: StudyModel) {
    return this._httpClient.post<GeneralResModel<StudyModel>>(
      `${this.baseURL}examResultScoreUpdateClear`,
      request
    );
  }

  examResultImageQualityUpdate(request: StudyModel) {
    return this._httpClient.post<GeneralResModel<StudyModel>>(
      `${this.baseURL}ExamResultImageQualityUpdate`,
      request
    );
  }

  examResultImageQualityClear(request: StudyModel) {
    return this._httpClient.post<GeneralResModel<StudyModel>>(
      `${this.baseURL}ExamResultImageQualityClear`,
      request
    );
  }

  resetStudy(request: ResetStudyReqModel) {
    return this._httpClient.post<GeneralResModel<boolean>>(
      `${this.baseURL}resetStudy`,
      request
    );
  }

  getVersionList(orderId: number, accessionNO: string) {
    return this._httpClient.get<GeneralResModel<StudyVersionResModel[]>>(
      `${this.baseURL}getVersionList/${orderId}/${accessionNO}`
    );
  }

  getRisSiteOrderHistoryVersion(queryModel: WorklistQueryModel) {
    let worklist = this._httpClient.post<StudyModel>(
      `${this.baseURL}getRisSiteOrderHistoryVersion`,
      queryModel
    );
    return worklist;
  }

  getResetReasons(orgId: number) {
    return this._httpClient.get<GeneralResModel<StudyResetReasonResModel[]>>(
      `${this.baseURL}getResetReasons/${orgId}`
    );
  }

  getCurrentStudyVersion(orderId: number, accessionNO: string) {
    return this._httpClient.get<GeneralResModel<number>>(
      `${this.baseURL}getCurrentStudyVersion/${orderId}/${accessionNO}`
    );
  }

  rptTextForStudyVerion(data: StudyModel): string {
    let rptText = "";
    rptText = data.ExamResultHistory.ResultTextHtml;
    if (data.Examresultnotehistory.length > 0) {
      let tempAddendum = "";
      let mainReportData = rptText;
      data.Examresultnotehistory.forEach((e) => {
        tempAddendum =
          tempAddendum +
          "<b>" +
          "Addendum By: " +
          "</b>" +
          e.AddendumByName +
          "<b>" +
          "  Addendum On: " +
          " </b>" +
          this._datePipe.transform(e.CreatedOn, "yyyy-MM-dd hh:mm a") +
          e.NoteText;
      });

      if (data.AddendumPosition === "T") {
        rptText = tempAddendum + mainReportData;
      } else {
        rptText = mainReportData + tempAddendum;
      }
    }

    return rptText;
  }
}
