import { ExamResult } from "../exam/exam-result.model";
import { RisOrderModel } from "../ris-order/ris-order.model";
import { HrEmpProfileModel } from "./hr-emp-profile.model";
import { HrEmpSubspecialtyModel } from "./hr-emp-subspecialty.model";
import { HrJobtitleModel } from "./hr-jobtitle.model";

export class HrEmpModel {
  HrEmpprofile: HrEmpProfileModel[];
  EmpId: number;
  EmpUid: string;
  UserName: string;
  SecurityQuestion: string;
  SecurityAnswer: string;
  Pwd: string;
  UnitId?: number;
  JobtitleId?: number;
  JobType: string;
  IsRadiologist: string;
  Salutation: string;
  Fname: string;
  Mname: string;
  Lname: string;
  GovtAccessToken: string;
  GovtAccessTokenOn?: string;
  FullNameText: string;
  TitleEng: string;
  FnameEng: string;
  MnameEng: string;
  LnameEng: string;
  Gender: string;
  EmailPersonal: string;
  EmailOfficial: string;
  PhoneHome: string;
  PhoneMobile: string;
  PhoneOffice: string;
  PreferredPhone: string;
  PabxExt?: number;
  FaxNo: string;
  Fob?: string;
  BloodGroup: string;
  DefaultLang?: number;
  Religion: number;
  PeAddr1: string;
  PeAddr2: string;
  PeAddr3: string;
  PeAddr4: string;
  AuthLevelId?: number;
  ReportingTo?: number;
  AllowOthersToFinalize: string;
  LastPwdModified?: string;
  LastLogin?: string;
  CardNo: string;
  PlaceOfBirth: string;
  Nationality: string;
  MStatus: string;
  IsActive: string;
  SupportUser: string;
  CanKillSession: string;
  DefaultShiftNo?: number;
  ImgFileName: string;
  EmpReportFooter1: string;
  EmpReportFooter2: string;
  Allproperties: string;
  Visible?: boolean;
  OrgId?: number;
  OrgName: string;
  CreatedBy?: number;
  CreatedOn?: string;
  LastModifiedBy?: number;
  ApprovedBy?: number;
  LastModifiedOn?: string;
  IsResident: string;
  AliasName: string;
  DefOrgId?: number;
  PwdHash: string;
  PwdSalt: string;
  OldPwd: string;
  Status: string;
  FullName: string;
  ApprovedOn?: string;
  WorkListType: string;
  IsImmediateReportWrite?: boolean;
  WorklistOrder: string;
  SignatureFileName: string;
  LineAccessToken: string;
  LineUserName: string;
  LineId: string;
  CountryId?: number;
  ProvinceId?: number;
  DistrictId?: number;
  SubdistrictId: number;
  ZipCode?: number;
  JobTypeId?: number;
  JobTypeLevelId?: number;
  Jobtitle?: HrJobtitleModel;
  RisExamresultFinalizedByNavigation: ExamResult[];
  RisExamresultReleasedByNavigation: ExamResult[];
  RisOrder: RisOrderModel[];
  OrgType: string;
  OrgParentId: number;
  IsRadiographer: string;
  IsEmailVerified: boolean;
  EmailVerifiedOn: string;
  IsPhoneVerified: boolean;
  PhoneVerifiedOn: string;
  PhoneOtp: string;
  HrEmpsubspecialty: HrEmpSubspecialtyModel[];
  HrEmpdocuments: File[];
  HrEmpdocumentFileTitle: string[];
  HrEmpdocumentFileType: string[];
  HrEmpdocumentFileFormat: string[];
  HrEmpsubspecialtyId: number[];
  ProfilePictureFile: File;
  CountryDialCode: string;
  MedicalLincence: string;
  RefDocName: string;
  RefDocTelNo: string;
}
