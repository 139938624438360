import {UserSessionStateModel} from '../models/user-session-state.model'
import { createAction, props } from '@ngrx/store';

//noinspection TypeScriptValidateTypes
export const login = createAction(
    '[Login Page] Login',
    props<{ userSessionDetail : UserSessionStateModel }>()
);

export const setUserImageUrl = createAction(
    '[Login Page] SetImageUrl',
    props<{ userSessionDetail : UserSessionStateModel }>()
);



// import {Action} from '@ngrx/store'
// import UserSessionStateModel from '../models/user-session-state.model'
//
//
// export const LOGIN_BY_EMAIL_PASSWORD = 'LOGIN_BY_EMAIL_PASSWORD';
//
//
// export class LoginByEmailPassword implements Action{
//     readonly type = LOGIN_BY_EMAIL_PASSWORD;
//     constructor(public payload: UserSessionStateModel){
//
//     }
// }
//
//
// export type Actions = LoginByEmailPassword;

