<div class="login-body" [ngClass]="{'layout-dark': dark, 'layout-light': !dark}">

    <div class="login-content">
        <div class="night-switch">
            <p-button styleClass="dark-switch"  icon="pi pi-circle-on" (click)="dark = true"></p-button>
            <p-button styleClass="light-switch"  icon="pi pi-circle-off" (click)="dark = false"></p-button>
        </div>
        <div class="login-panel">
            <div class="login-panel-content">
                <div class="logo">
                    <img src="assets/layout/images/logo-mirage@2x.png" alt="mirage-layout" />
                </div>
                <h1>Sign in to RadPortal</h1>
                <p>Welcome. Please Sign in to the RadPortal Network.</p>
                <div class="forms">
                    <div class="login-input-wrapper">
                        <label for="username">Username</label>
                        <input id="username" pInputText placeholder="Type your username..." />
                        <i class="pi pi-user"></i>
                    </div>

                    <div class="login-input-wrapper">
                        <label for="password">Password</label>
                        <input type="password" id="password" placeholder="Type your password" pPassword [feedback]="false"/>
                        <i class="pi pi-lock"></i>
                    </div>

                    <div class="col-12">
                        <p-checkbox [(ngModel)]="checked" label="Remember me" value="SaveInfo" binary="true"></p-checkbox>
                    </div>


                    <div class="buttons">
                        <button pButton type="button" [routerLink]="['/']" label="Login"></button>
                        <a href="#" class="secondary-button">Forget Password?</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="desert"></div>
    </div>
</div>
