export class RadProfileStudyCountChartModel {
  labels: any[];
  datasets: DatasetsObj;
}

export class DatasetsObj {
  label: string;
  data: any[];
  fill: boolean;
  backgroundColor: string = "#00bb7e";
  borderColor: string = "#00bb7e";
  tension: number = 0.4;
}
