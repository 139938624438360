import { OrgResponseViewModel } from "./org-response-view.model";

export class RisOrderSubmitDTLAPISendLogViewModel {
  OrderSubmitDTLAPISendLogId: number;
  OrdersubmitdtlId: number;
  APIName: string;
  APIURL: string;
  RequestBody: string;
  ResponseStatus: number;
  ResponseMessage: string;
  RequestedOn: string;
  ResponsedOn: string;
  TransactionBy: number;
  TransactionByName: string;
  TransactionOn: string;
  Token: string;
  OrgId: number;
  PatientValidity: OrgResponseViewModel;
}
