import { RisExam } from "../exam/ris-exam.model";
import { Tatsetup } from "../study/tatsetup.model";
import { RisModalityModel } from "./ris-modality.model";
import { RisOrderModel } from "./ris-order.model";

export class RisOrderDtlModel {
  orderId: number;
  examId: number;
  accessionNo: string;
  qNo: string;
  modalityId: number;
  examDt?: string;
  serviceType?: number;
  qty: number;
  estStartTime?: string;
  assignedTo?: number;
  hl7Text: string;
  hl7Sent: string;
  rate?: number;
  comments: string;
  specialClinic: string;
  selfArrival: string;
  imageCapturedBy?: number;
  imageCapturedOn?: string;
  qaBy?: number;
  qaOn?: string;
  orgId?: number;
  priority: string;
  status: string;
  isDeleted: string;
  assignedBy?: number;
  assignedTimestamp?: string;
  clinicType?: number;
  bpviewId?: number;
  merge: string;
  mergeWith: string;
  hisSync: string;
  createdBy?: number;
  createdOn?: string;
  createdFrom: string;
  lastModifiedBy?: number;
  lastModifiedOn?: string;
  hisAck: string;
  preparationId?: number;
  commentsOnline: string;
  imagecount?: number;
  hasComment: string;
  instanceUid: string;
  aeTitleId?: number;
  workStationId?: number;
  requestResultDatetime?: string;
  noOfImage?: number;
  filePath: string;
  pacsAccessionNo: string;
  cancelHost: string;
  cancelledBy?: number;
  cancelledReason: string;
  cancelledOn?: string;
  exam: RisExam;
  modality: RisModalityModel;
  order: RisOrderModel;
  lunitAiResultStatus: boolean;
  risSendStatus: string;
  pacsSendStatus: string;
  isDisagree: boolean;
  feedbackComment: string;
  hospitalAccessionNo: string;
  mwlAccess?: boolean;
  busId?: number;
  queueSerial?: number;
  isSyncToLocal?: boolean;
  technicalQuality: string;
  crh_Number: string;
  room: string;
  caseNo: string;
  printCount?: number;
  submittedBy?: number;
  submittedOn?: string;
  statusId?: number;
  isDraft?: boolean;
  tatsetupId?: number;
  tatsetup: Tatsetup;
  earningType: string;
  earningValue?: number;
  actualEarning?: number;
  statusChanged: string;
  patientType: string;
  statusChangedOn?: string;
  statusChangedBy?: number;
  isReset?: boolean;
  resetBy?: number;
  resetReason: string;
  resetOn?: string;
  versionNo?: number;
  resetTo: string;
  resetreasonId?: number;
  resetFrom: string;
  isReviewed?: boolean;
  reviewedOn?: string;
}
