import { GblReportModel } from "./gbl-report.model";

export class GblReportJobtypeSettingModel {
  reportjobtypesettingId: number;
  ReportId?: number;
  JobtypeId?: number;
  JobtypelevelId?: number;
  IsActive?: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  Report: GblReportModel;
}
