import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { GeneralResModel, RisExamResultKeyImagesModel, RisExamResultKeyImagesViewModel } from 'src/app/_models';
import { StudyModel } from 'src/app/_models/study.model';
import { User } from 'src/app/_models/user-models/user';
import { AlertifyService } from 'src/app/_services/common/alertify.service';
import { StudyWorklistService } from 'src/app/_services/worklist/study-worklist.service';
import { environment } from 'src/environments/environment';

interface SelectedFile {
  file: File;
  url: string;
}

@Component({
  selector: 'app-study-key-images',
  templateUrl: './study-key-images.component.html',
  styleUrls: ['./study-key-images.component.scss']
})
export class StudyKeyImagesComponent implements OnInit {

  client: User = new User();
  studyRowData: StudyModel = new StudyModel();
  reportingImgEnvURL = environment.reportingImgEnvURL;
  imagesForgallery: any[] | undefined;
  previewImageIndex: number | null = null;
  selectedFiles: SelectedFile[] = [];
  selectedReportingFiles: File[] = [];
  reportingImgUploadRequest: RisExamResultKeyImagesViewModel[] = [];
  reportingImageData: RisExamResultKeyImagesModel[] = [];
  reportingImageTempData: RisExamResultKeyImagesModel[] = [];
  modifiedImageList: RisExamResultKeyImagesModel[] = [];
  private draggingIndex: number | null = null;

  constructor(
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private _domSanitizer: DomSanitizer,
    private confirmationService: ConfirmationService,
    private studyWorklistService: StudyWorklistService,
  ) { }

  ngOnInit(): void {
    this.client = JSON.parse(localStorage.getItem("user"));
    this.getRouteParam();
  }

  private getRouteParam() {
    this.route.paramMap.subscribe(params => {
      const encodedRowData = params.get('encodedRowData');
      if (encodedRowData) {
        this.studyRowData = this.decodeUrlParamToObject(encodedRowData);
        console.log("New Window RowData: ", this.studyRowData);
        if(this.studyRowData != null) {
          this.getAllImagesByFilter(this.studyRowData);
        }
      }
    });
  }

  private decodeUrlParamToObject(param: string): any {
    return JSON.parse(decodeURIComponent(escape(atob(decodeURIComponent(param)))));
}

  onFileSelected(event: Event): void {
    this.selectedReportingFiles = [];
    const inputElement = event.target as HTMLInputElement;
    if (inputElement?.files) {
      for (let i = 0; i < inputElement.files.length; i++) {
        const file = inputElement.files[i];
        this.selectedReportingFiles.push(file);
      }

      if (this.selectedReportingFiles.length > 0) {
        this.addReportingImages(this.selectedReportingFiles);
      }
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.selectedReportingFiles = [];
    const dataTransfer = event.dataTransfer;
    if (dataTransfer?.files) {
      for (let i = 0; i < dataTransfer.files.length; i++) {
        const file = dataTransfer.files[i];
        this.selectedReportingFiles.push(file);
      }

      if (this.selectedReportingFiles.length > 0) {
        this.addReportingImages(this.selectedReportingFiles);
      }
    }
  }

  onPaste(event: ClipboardEvent) {
    this.selectedReportingFiles = [];
    const items = Array.from(event.clipboardData?.items || []);

    for (const item of items) {
      if (item.type.indexOf("image") !== -1) {
        const file = item.getAsFile();
        this.selectedReportingFiles.push(file);
      }
    }

    if (this.selectedReportingFiles.length > 0) {
      this.addReportingImages(this.selectedReportingFiles);
    }
  }

  private buildFormData(files: File[]): FormData {
    const formData = new FormData();

    let siNo: number = 0;

    if (this.reportingImageData.length > 0) {
      siNo = this.reportingImageData.length + 1;
    } else {
      siNo = 1;
    }

    files.forEach((file, index) => {
      formData.append(
        `images[${index}].AccessionNo`,
        this.studyRowData.AccessionNo
      );
      formData.append(
        `images[${index}].OrderId`,
        this.studyRowData.OrderId + ""
      );
      formData.append(
        `images[${index}].OrgId`,
        this.studyRowData.OrgId + ""
      );
      formData.append(`images[${index}].SlNo`, siNo.toString());
      formData.append(
        `images[${index}].CreatedBy`,
        this.client.EmpId.toString()
      );
      formData.append(`images[${index}].Images`, file, file.name);

      siNo++;
    });

    return formData;
  }

  addReportingImages(files: File[]) {
    const formData = this.buildFormData(files);
    this.studyWorklistService
      .addReportingImages(formData)
      .subscribe((res: GeneralResModel<RisExamResultKeyImagesModel[]>) => {
        if (res.IsSucceed) {
          this.getAllImagesByFilter(this.studyRowData);
          this.alertify.success(res.Message);
        }
      });
  }


  getAllImagesByFilter(rowData: StudyModel) {
    this.reportingImageData = [];
    this.reportingImageTempData = [];
    this.studyWorklistService
      .getAllImagesByFilter(rowData)
      .subscribe((res: GeneralResModel<RisExamResultKeyImagesModel[]>) => {
        if (res.IsSucceed) {
          this.reportingImageData = res.Result;

          const count = this.reportingImageData.length;
          this.studyWorklistService.setKeyImgCount(count);

          if (this.reportingImageData.length > 0) {
            this.reportingImageTempData = this.reportingImageData.map((x) => ({
              ExamresultkeyimagesId: x.ExamresultkeyimagesId,
              OrderId: x.OrderId,
              AccessionNo: x.AccessionNo,
              SlNo: x.SlNo,
              KeyImage: x.KeyImage,
              OrgId: x.OrgId,
              CreatedBy: x.CreatedBy,
              CreatedOn: x.CreatedOn,
              LastModifiedBy: x.LastModifiedBy,
              ImageURL: x.ImageURL,
              ImageURLSafe: this._domSanitizer.bypassSecurityTrustResourceUrl(
                this.reportingImgEnvURL +
                  x.OrderId +
                  "/" +
                  x.ImageURL.split("\\").pop()
              ),
              LastModifiedOn: x.LastModifiedOn,
            }));
          }
        }
      });
  }

  sortedImageTempData(): Array<any> {
    return this.reportingImageTempData.slice().sort((a, b) => a.SlNo - b.SlNo);
  }

  onDragStart(event: DragEvent, index: number) {
    this.draggingIndex = index;
    if (event.dataTransfer) {
      event.dataTransfer.setData("text/plain", "dragging");
    }
  }

  onDragOverImg(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = "move";
    }
  }

  onDragEnd() {
    this.draggingIndex = null;
  }

  onDropImg(index: number) {
    if (this.draggingIndex === null || this.draggingIndex === index) return;
    const draggedImage = this.reportingImageTempData[this.draggingIndex];
    const replacedImage = this.reportingImageTempData[index];
    this.reportingImageTempData.splice(this.draggingIndex, 1);
    this.reportingImageTempData.splice(index, 0, draggedImage);

    const imagesList = [draggedImage, replacedImage];
    this.swapReportingImage(imagesList);
    console.log("Dragged Image: ", draggedImage);
    console.log("Replaced Image: ", replacedImage);
    this.draggingIndex = null;
  }

  private swapReportingImage(items: RisExamResultKeyImagesModel[]) {
    this.modifiedImageList = [];
    this.modifiedImageList = items.map((x) => ({
      ExamresultkeyimagesId: x.ExamresultkeyimagesId,
      OrderId: x.OrderId,
      AccessionNo: x.AccessionNo,
      SlNo: x.SlNo,
      KeyImage: x.KeyImage,
      OrgId: x.OrgId,
      CreatedBy: x.CreatedBy,
      CreatedOn: x.CreatedOn,
      LastModifiedBy: this.client.EmpId,
      ImageURL: x.ImageURL,
      ImageURLSafe: this._domSanitizer.bypassSecurityTrustResourceUrl(
        this.reportingImgEnvURL +
          "/" +
          x.OrderId +
          "/" +
          x.ImageURL.split("\\").pop()
      ),
      LastModifiedOn: x.LastModifiedOn,
    }));
    this.studyWorklistService
      .swapReportingImage(this.modifiedImageList)
      .subscribe((res: GeneralResModel<RisExamResultKeyImagesModel[]>) => {
        if (res.IsSucceed) {
          this.getAllImagesByFilter(this.studyRowData);
          this.alertify.success("Swap is success between two images.");
        } else {
          this.getAllImagesByFilter(this.studyRowData);
          this.alertify.error("Something went wrong, Please try again laget.");
        }
      });
  }

  onDeleteImage(item: RisExamResultKeyImagesModel) {
    this.confirmationService.confirm({
      key: "deleteReportingImage",
      message: "Are you sure to remove?",
      accept: () => {
        this.studyWorklistService
          .removeReportingImages(item)
          .subscribe((res: GeneralResModel<RisExamResultKeyImagesModel[]>) => {
            if (res.IsSucceed) {
              this.getAllImagesByFilter(this.studyRowData);
              this.alertify.success(res.Message);
            } else {
              this.alertify.warning(
                "Something went wrong. Please try again later."
              );
            }
          });
      },
      reject: () => {
        this.alertify.message("You have rejected to remove this image.");
      },
    });
  }

  openPopupForReportingImage(item: RisExamResultKeyImagesModel): void {
    const popupURL =
      this.reportingImgEnvURL +
      "/" +
      item.OrderId +
      "/" +
      item.ImageURL.split("\\").pop();

    const popupOptions = "width=800,height=600,resizable=yes";
    const popupContent = `
    <html>
    <head>
    <title>VetPortal - Cloud Imaging Solutions</title> 
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link rel="icon" type="image/x-icon" href="../../../../assets/layout/images/favicon/favicon.png" />
      <style>
        body {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
        }
        #zoomableImage {
          object-fit: cover;
        }
      </style>
    </head>
    <body>
    <app-popup-content>
      <img id="zoomableImage" src="${popupURL}" [alt]='item.SlNo' class="zoomable-img">
    </app-popup-content>
    </body>
    </html>
  `;

    const popupWindow = window.open("", "popupWindow", popupOptions);
    popupWindow.document.write(popupContent);
    popupWindow.document.close();
  }

  showPreviewImage(index: number): void {
    this.previewImageIndex = index;
  }

  hidePreviewImage(): void {
    this.previewImageIndex = null;
  }

  isImagePreviewVisible(index: number): boolean {
    return this.previewImageIndex === index;
  }

}
