<div
  class="patient_history_list_table"
  [ngClass]="!isMainReport ? 'save-and-submit-study-worklist' : ''"
>
  <p-table
    #dt1
    [value]="patientHistoryData"
    dataKey="type"
    [rows]="10"
    [rowHover]="true"
    styleClass="p-datatable-gridlines"
    [paginator]="true"
    responsiveLayout="scroll"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="AccessionNo">
          Accession# <p-sortIcon field="AccessionNo"></p-sortIcon>
        </th>
        <th pSortableColumn="ExamName">
          Exam Name <p-sortIcon field="ExamName"></p-sortIcon>
        </th>
        <th pSortableColumn="ExamDate">
          Exam Date <p-sortIcon field="ExamDate"></p-sortIcon>
        </th>
        <th>Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-patientHistoryData>
      <tr>
        <td>
          {{ patientHistoryData.AccessionNo }}
        </td>
        <td>
          {{ patientHistoryData.ExamName }}
        </td>
        <td>
          {{ patientHistoryData.ExamDate | date : "dd-MM-yyyy hh:mm a" }}
        </td>
        <td [style.width.px]="110">
          <div class="flex justify-content-between align-items-center">
            <button
              style="width: 36px; margin-right: 10px"
              (click)="goToLocalPacks(patientHistoryData)"
              title="View Image"
              pButton
            >
              <img
                style="width: 15px; height: 15px"
                src="../../../../assets/layout/images/white_synapse.svg"
                alt=""
              />
            </button>
            <button
              *ngIf="!isMainReport"
              style="width: 36px; height: 31px"
              (click)="convertToMain(patientHistoryData)"
              icon="fa fa-solid fa-retweet fa-large"
              title="Convert To Main"
              pButton
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4" class="text-center">No data found.</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="4" class="text-center">
          Loading uploaded document data. Please wait.
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  header="AI Finding"
  [(visible)]="displayResultText"
  appendTo="body"
  [modal]="true"
>
  <div class="grid">
    <div class="col-12">
      <p-editor
        readonly="true"
        [(ngModel)]="examResult"
        [style]="{ height: '320px' }"
      >
        <p-header> </p-header>
      </p-editor>
      <br />
      <br />
      <strong>AI DISCLAIMER:</strong> This is an AI generated Finding.
      <br />
      <br />
      <strong>DISCLAIMER: </strong>{{ siteDetail?.RepFooter1 }}
    </div>
  </div>
</p-dialog>

<!-- Study Swap Confirmation Dialog START -->
<p-confirmDialog
  header="Confirmation"
  key="changeStudyStatus"
  icon="pi pi-exclamation-triangle"
  message="Are you sure you want to proceed?"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
<!-- Study Swap Confirmation Dialog END -->
