import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class CloudApiService {
    imageUrl = environment.imageUrl;
    cloudApiUrl = environment.cloudApiUrl;    

    constructor(private http: HttpClient) { }

    public get<T>(url: string): Observable<T> {
        return this.http.get<T>(`${this.cloudApiUrl}${url}`);
    }

    public post<T>(url: string, body): Observable<T> {
        return this.http.post<T>(`${this.cloudApiUrl}${url}`, body);
    }

    public put<T>(url: string, body): Observable<T> {
        return this.http.put<T>(`${this.cloudApiUrl}${url}`, body);
    }

    public delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(`${this.cloudApiUrl}${url}`);
    }

    public patch<T>(url: string, body: string): Observable<T> {
        return this.http.patch<T>(`${this.cloudApiUrl}${url}`, body);
    }

}