import { Injectable } from "@angular/core";
import { GroupViewModel } from "../_models/group/group-view-model";
import { PatientHistory } from "../_models/group/patient-history.model";
import { SiteInformation } from "../_models/site-information.model";
import { StudyModel } from "../_models/study.model";
import { ApiService } from "./api/api.service";

@Injectable({
  providedIn: "root",
})
export class GroupService {
  apiPath: string = "Group/";

  constructor(private api: ApiService) {}

  getGroupModelsByUser(userId: number) {
    return this.api.get<GroupViewModel[]>(
      `${this.apiPath}getGroupModelsByUser/${userId}`
    );
  }

  getOrgListByUser(userId: number) {
    return this.api.get<SiteInformation[]>(
      `${this.apiPath}getOrgListByUser/${userId}`
    );
  }

  getStudyResultModel(accessionNo: number) {
    return this.api.get<StudyModel>(
      `${this.apiPath}getGroupModelsByUser/${accessionNo}`
    );
  }
  getPatientHistory(hn: string, orgId: number) {

    const convertedHn = this.getConvertedHn(hn);

    return this.api.get<PatientHistory[]>(
      //`${this.apiPath}getPatientHistory/${hn}/${orgId}`
      `${this.apiPath}getPatientHistory/${convertedHn}/${orgId}`
    );
  }

  private getConvertedHn(hn: string) {
    let converted = hn.replace("/", "_");
    return converted;
  }
}
