import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  GblReportModel,
  GblReportViewModel,
  GeneralResModel,
} from "src/app/_models";
import { CommonResponse } from "src/app/_models/common-response.modle";
import { AutoCompleteResponse } from "src/app/_models/report/autocompleteresponse";
import { JasperReportDetails } from "src/app/_models/report/jasperreportdetails";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  private baseURL = `${environment.apiUrl}report/`;

  constructor(private _httpClient: HttpClient) {}

  getRptJobTypeSettingById(rptjobtypesettingId: number) {
    return this._httpClient.get<CommonResponse>(
      `${this.baseURL}GetReportJobtypeSettingById/${rptjobtypesettingId}`
    );
  }
  getRptJobTypeSettingList() {
    return this._httpClient.get<CommonResponse>(
      `${this.baseURL}GetReportJobtypeSetting`
    );
  }
  
  getActiveRptJobTypeSettingList() {
    return this._httpClient.get<CommonResponse>(
      `${this.baseURL}GetActiveReportJobtypeSetting`
    );
  }

  addRptJobTypeSetting(body:GblReportViewModel) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}AddReportJobtypeSetting`,body
    );
  }
  updateRptJobTypeSetting(body:GblReportViewModel) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}UpdateReportJobtypeSetting`,body
    );
  }
 deleteRptJobTypeSetting(body:GblReportViewModel) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}DeleteReportJobtypeSetting`,body
    );
  }

  getActiveReportList() {
    return this._httpClient.get<CommonResponse>(
      `${this.baseURL}GetActiveReport`
    );
  }
  getAllReportList() {
    return this._httpClient.get<GeneralResModel<GblReportModel[]>>(
      `${this.baseURL}GetReport`
    );
  }

  getReportByUserId(userId: number) {
    return this._httpClient.get<GeneralResModel<GblReportViewModel[]>>(
      `${this.baseURL}GetReportByUserId/${userId}`
    );
  }

  getReportParamSettingByReportUserId(reportId: number, userId: number) {
    return this._httpClient.get<GeneralResModel<GblReportViewModel[]>>(
      `${this.baseURL}GetReportParamSettingByReportUserId/${reportId}/${userId}`
    );
  }
  getAccssionNowithOrgName(filter: string,type:string,status:string) {
    return this._httpClient.get<GeneralResModel<AutoCompleteResponse[]>>(
      `${this.baseURL}GetAccessionNoWithOrgName/${filter}/${type}/${status}`
    );
  }

  // getReportShow(url: string) {
  //   return this._httpClient.get<GeneralResModel<JasperReportDetails>>(
  //     `${this.baseURL}GetAccessionNoWithOrgName/${url}`
  //   );
  // }
}
