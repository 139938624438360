export class RisExamResultKeyImagesViewModel {
    ExamresultkeyimagesId: number;
    OrderId?: number;
    AccessionNo: string;
    SlNo: number;
    KeyImage: string;
    OrgId?: number;
    CreatedBy: number;
    CreatedOn?: Date;
    LastModifiedBy: number;
    LastModifiedOn?: Date;
    Images: File;
    Files: File;
    ImageURL: string;
  }
  