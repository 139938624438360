import { GblReportParameterModel } from "./gbl-report-parameter.model";
import { GblReportModel } from "./gbl-report.model";

export class GblReportParameterSettingModel {
  ParametersettingId: number;
  ReportId?: number;
  parameterId?: number;
  IsVisible?: boolean;
  IsRequired?: boolean;
  IsDisable?: boolean;
  DefaultValue: string;
  DisplayOrder: number;
  IsActive?: boolean;
  OrgId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  Parameter: GblReportParameterModel;
  Report: GblReportModel;
}
