import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-radiologist-email-verification',
  templateUrl: './radiologist-email-verification.component.html',
  styleUrls: ['./radiologist-email-verification.component.scss']
})
export class RadiologistEmailVerificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onOtpChange(data:any){
    console.log(data);
  }

}
