export class ExamResultNote {
  OrderId: number;
  ExamId: number;
  AccessionNo: string;
  NoteNo: number;
  NoteText: string;
  NoteBy: number;
  NoteOn: string;
  OrgId: number;
  CreatedBy: number;
  CreatedOn: Date;
  lastModifiedBy: number;
  LastModifiedOn: string;
  noteTextRtf: string;
  AddendumByName: string;
  AddendumPosition: string;
}
