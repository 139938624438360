import { AfterViewInit, Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, Observable, Subject, switchMap } from 'rxjs';
import { emailValidator } from 'src/app/_helper/validator.helper';
import { GblCountry } from 'src/app/_models/radiologist-signup/GblCountry';
import { GblDistrict } from 'src/app/_models/radiologist-signup/GblDistrict';
import { GblProvince } from 'src/app/_models/radiologist-signup/GblProvince';
import { GblSubdistrict } from 'src/app/_models/radiologist-signup/GblSubdistrict';
import { HrEmpVerify } from 'src/app/_models/radiologist-signup/HrEmpVerify';
import { SiteService } from '../../../../_services/site/site.services';
import { UserVerifyService } from '../../../../_services/user/user-verify.service';
import { UserService } from '../../../../_services/user/user.service';
import { HospitalCustomModel } from '../../../../_models/Hospital/HospitalCustomModel';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { AlertifyService } from '../../../../_services/common/alertify.service';
import { environment } from 'src/environments/environment';
import { error } from 'protractor';
import { ConfigurationService } from 'src/app/_core/services/configuration.service';
import { Configuration } from 'src/app/_core/models/configuration.model';
import { PwdValidatorParam, PwdValidatorRes } from 'src/app/_models/password-policy/pwdvalidator-res.modal';
import { AuthService } from 'src/app/_services/auth/auth.service';

@Component({
  selector: 'app-hospital-signup',
  templateUrl: './hospital-signup.component.html',
  styleUrls: ['./hospital-signup.component.scss']
})
export class HospitalSignupComponent implements OnInit {
  orgForm: FormGroup;
  showOrHideResendButton: boolean;
  isLoaderVisible: boolean;
  isConfirmOtpField: boolean = false;
  VerificationId: any;
  selectedEmailOTP: any;
  HospitalLogoToUpload: any;
  ProfileImageToUpload: any;

  @ViewChild('ngOtpInputEmail') ngOtpInputRefEmail:any;
  profileImageUrl: string;

   config : Configuration = new Configuration();

    //Pwd Validator
    isPasswordValid: boolean;
    validationMessage: string[];
    validationMessageForTT: string;
    pwdValidatorRes: PwdValidatorRes;
    pwdValidatorParam: PwdValidatorParam = new PwdValidatorParam();
  
    isPasswordMasked: boolean = true;
  constructor(private siteService:SiteService,
    private userVerifyService:UserVerifyService,
    private _userService:UserService,
    private _httpClient:HttpClient,
    private alertifyService:AlertifyService,
    private configurationService: ConfigurationService,
    private _authService: AuthService,
    private eRef: ElementRef) { }

  IsSentVerificationCode:boolean=false;
  IsVerifySuccessfull:boolean=false;
  selectedEmail:string;
  emailExists:boolean=false;
  searchEmailTextSubject$ = new Subject<{ email: string; Id: number }>();

  IsEmailVerified:boolean;

  selectedHospitalName:string;
  selectedWebUrl:string;
  selectedTelNo:string;
  selectedLineId:string;
  
  selectedCountry:GblCountry;
  countryList:GblCountry[];
  selectedDistrict:GblDistrict;
  districtList:GblDistrict[];
  selectedProvince:GblProvince;
  ProvinceList:GblProvince[];
  selectedSubDistrict:GblSubdistrict;
  SubDistrictList:GblSubdistrict[];

  selectedZipCode:string;
  selectedFName:string;
  selectedLName:string;

  selectedPassword:string;
  selectedConfirmPassword:string;
  selectedSiteAdminTelNo:string;
  selectedTermAndCon:boolean=false;
  selectedSiteAdminLineId:string;
  uploadedFiles: any[] = [];
  uploadedFilesForHospitalLogo: any[] =[];
  IsCountDownTimeOut: boolean;
  timerInterval: any;
  display: any;
  isSendOTPbutton: boolean = true;
  autocompleteAttribute: string = 'nope';
  
  ngOnInit() {
    this.IsCountDownTimeOut=false;    
      this.configurationService.getJSONwithAPI().subscribe(res=>{
        this.config = res as Configuration;
      // console.log('getJSONwithAPI',this.config );
    });
    
    this.orgForm = new FormGroup({
      HospitalName:new FormControl("",Validators.required),
      WebUrl:new FormControl(""),
      HospitalTelNo:new FormControl(""),
      SiteAdminTelNo:new FormControl(""),
      FName: new FormControl("", Validators.required),
      LName:new FormControl("", Validators.required),
      Email:new FormControl("", emailValidator),
      Password:new FormControl("", Validators.required),
      ConfirmPassword: new FormControl("", Validators.required),
      LineId:new FormControl(),
      PhoneNo: new FormControl(
        "",
        Validators.compose([Validators.pattern("[0-9]+")])
      ),
      phoneCode:new FormControl(),
      Country:new FormControl(),
      District: new FormControl(),
      Province:new FormControl(),
      SubDistrict:new FormControl(),
      SubSpecialty:new FormControl(),
      ZipCode:new FormControl(),
      MedicalLincence:new FormControl(),
      TearmAndCondition:new FormControl(false,Validators.required),
      SiteAdminLineId:new FormControl()
    },
    { validators: this.passwordMatch }
    );


    this.profileImageUrl=environment.defaultRadiologistImage;
    
    this.loadStartUpData();
  }

  VerifyOtp(){

    let userVerify=new HrEmpVerify();
    userVerify.VerifyId=this.VerificationId;
    userVerify.EmailOtp=this.selectedEmailOTP;
   
    this.userVerifyService.verifyOTP(userVerify).subscribe(x=>{
      if(x){

        if(x.IsEmailVerified){
          this.IsEmailVerified=x.IsEmailVerified;
          this.IsVerifySuccessfull=x.IsEmailVerified;
        }else{
          this.IsEmailVerified=false;
          this.showOrHideResendButton=true;

          this.ngOtpInputRefEmail.setValue("");
        }

        this.stop();
        
      }
    });
  }

  sentVerificationCode() {
    let userVerify=new HrEmpVerify();
    this.IsCountDownTimeOut=false;
    userVerify.Email=this.selectedEmail;
    userVerify.IsEmailVerified=this.IsEmailVerified;
    userVerify.IsPhoneVerified=true;
    if(this.IsEmailVerified==false){
      this.ngOtpInputRefEmail.setValue("");
    }
    this.IsEmailVerified=null;
    this.showOrHideResendButton=false;

    this.isLoaderVisible=true;

    this.stop();
    this.userVerifyService.SendVerificationCode(userVerify).subscribe(x=>{
       if(x){

        this.start(10);

        this.IsSentVerificationCode=true;
         this.VerificationId=x.VerifyId;
         this.showOrHideResendButton=false;
       

         this.isLoaderVisible=false;
       }
       else{
        this.isLoaderVisible=false;
       }
     },error=>{
      this.isLoaderVisible=false;
     });
  }

  checkEmail(event) {
    this.emailCheck();
    this.searchEmailTextSubject$.next({
      email: this.orgForm.controls["Email"].value,
      Id: 0,
    });
  }
  
  emailCheck() {
    this.isSendOTPbutton = true;
    this.searchEmailTextSubject$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchText) =>
          this._userService.emailAlreayExists(searchText.email)
        )
      )
      .subscribe((status) => {
        this.emailExists = status;
        if (
          (this.selectedEmail != null ||
            this.selectedEmail != "" ||
            this.selectedEmail != undefined) &&
          !this.emailExists &&
          this.isEmailValid()
        ) {
          this.isSendOTPbutton = false;
        } else {
          this.isSendOTPbutton = true;
        }
      });
  }

  isEmailValid(){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.selectedEmail).toLowerCase());
  }

  passwordMatch(g: FormGroup) {
    return g.get("Password").value === g.get("ConfirmPassword").value
      ? null
      : { mismatch: true };
  }

  onEmailOtpChange(code) {
    this.selectedEmailOTP=code;
    if(this.selectedEmailOTP.length === 6) {
      this.isConfirmOtpField = true;
    } else {
      this.isConfirmOtpField = false;
    }
  }


  loadStartUpData(){
    this._userService.getCountryList().subscribe(x=>{
      if(x){
        this.countryList=x;
      }
    });


  }

  loadProvince(){

    this._userService.getProvinceList(this.selectedCountry.CountryId).subscribe(x=>{
      if(x){
        this.ProvinceList=x;
      }
    })

    // this.EmmitRediologist();
  }

  loadDistrict(){
    this._userService.getDistrictList(this.selectedProvince.ProvinceId).subscribe(x=>{
      if(x){
        this.districtList=x;
      }
    })
    // this.EmmitRediologist();
  }

  loadSubDistrict(){
    this._userService.getSubDistrictList(this.selectedDistrict.DistrictId).subscribe(x=>{
      if(x){
        this.SubDistrictList=x;
      }
    })
    // this.EmmitRediologist();
  }

  saveOrUpdateHospital(){

    if (this.isPasswordValid==false) {
      this.alertifyService.error("Password Invalid");
      return;
    }
    
    let hospitalForm=  new FormData();
    if(this.selectedCountry){
      hospitalForm.append("CountryId",this.selectedCountry.CountryId.toString());
    }
    if(this.selectedProvince){
      hospitalForm.append("ProvinceId",this.selectedProvince.ProvinceId.toString());
    }
    
    if(this.selectedDistrict){
      hospitalForm.append("DistrictId",this.selectedDistrict.DistrictId.toString());
    }
    
    if(this.selectedSubDistrict){
      hospitalForm.append("SubdistrictId",this.selectedSubDistrict.SubdistrictId.toString());
    }
    
    
    hospitalForm.append("OrgZipCode",this.selectedZipCode??"");
    hospitalForm.append("OrgName",this.selectedHospitalName);
    hospitalForm.append("OrgTel1",this.selectedTelNo??"");
    hospitalForm.append("OrgEmail1",this.selectedEmail);
    hospitalForm.append("OrgWebsite",this.selectedWebUrl??"");
    hospitalForm.append("HospitalLogoFile",this.HospitalLogoToUpload);
    if(this.selectedLineId){
      hospitalForm.append("OrgLineId",this.selectedLineId);
    }
    

    hospitalForm.append("Fname",this.selectedFName);
    hospitalForm.append("Lname",this.selectedLName);
    hospitalForm.append("Pwd",this.selectedConfirmPassword);
    hospitalForm.append("PhoneMobile",this.selectedSiteAdminTelNo??"");
    if(this.selectedSiteAdminLineId){
      hospitalForm.append("HrLineId",this.selectedSiteAdminLineId);
    }
    

    hospitalForm.append("profilePictureFile",this.ProfileImageToUpload);

    this.isLoaderVisible=true;

    this.siteService.addNewHospital(hospitalForm).subscribe(x=> {
      if(x){
        this.alertifyService.success("Healthcare Service Provider created successfully");

        window.open("https://radportal.net/thank-you", "_self");
        
      }
      else{
        this.alertifyService.error("Failed to create Healthcare Service Provider");

        this.isLoaderVisible=false;
      }
      //
    },
    error=>{
      this.isLoaderVisible=false;
    })
                             
  }

  onUpload(event): void {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  
  myUploader(event): void {
    if (event.files.length === 0) {
      return;
    }
     this.ProfileImageToUpload = event.files[0];
  
  }


  onUploadHospitalLogo(event): void {
    for (const file of event.files) {
      this.uploadedFilesForHospitalLogo.push(file);
    }
  }

  
  myUploaderForHospitalLogo(event): void {
    if (event.files.length === 0) {
      return;
    }
     this.HospitalLogoToUpload = event.files[0];
  
  }

  start(time) {
    this.timer(time);
  }
  stop() {
    clearInterval(this.timerInterval);
  }

  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;
  
    const prefix = minute < 10 ? '0' : '';
  
    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;
  
      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;
  
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
  
      if (seconds == 0) {
        this.IsCountDownTimeOut=true;
        this.showOrHideResendButton=true;
        console.log('finished');
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }



  

  validatePassword(event) {
    this.pwdValidatorParam.Password=(event.target as HTMLInputElement).value;
    this.pwdValidatorParam.OrgId=0;
    if (this.pwdValidatorParam.Password !== null && this.pwdValidatorParam.Password.trim() !== '') {
      this._authService
      .ValidatePassword(this.pwdValidatorParam)
      .subscribe((res) => {
        if (res.IsSucceed) {
          this.pwdValidatorRes = res.Result;
          this.isPasswordValid=this.pwdValidatorRes.IsPasswordValid;
          this.validationMessage=this.pwdValidatorRes.ValidationMessage;
          this.validationMessageForTT=this.validationMessage.join('\n');
        }
      });
    }
  }
  togglePasswordMask(): void {
    this.isPasswordMasked = !this.isPasswordMasked;
  }
  

}
