export const environment = {
  production: true,
  apiUrl: "/SwiftBackendAPI/api/",
  cloudApiUrl: "http://10.10.10.19/SwiftBackendAPI/api/",
  lunitApiUrl: "http://10.10.10.11:8000/",
  imageUrl: "/SwiftBackendAPI/api/ImageUpload/",
  profileImagerUrl: "/Documents/",
  documentEnvURL: "/Documents/",
  finalizedReportEnvURL: "/Documents/Reports/",
  reportingImgEnvURL: "/Documents/StudyKeyImages/",
  dicomImagerUrl: "/Documents/DicomImagePath/",
  selectedApiUrl: "/SwiftBackendAPI/api/",
  isCloud: true,
  isSuperAdmin: false,
  isSiteAdmin: false,
  excelTemplatePath: "../../assets/Documents/Templates/",
  reportViewerUrl: "/ReportsViewer/",
  appUrl: "https://doc.dinsight.health/#/",
  refreashTimeinSec: 300000,
  OAuthUrl:
    "access.line.me/oauth2/v2.1/login?loginState=Ir5DviW235jbc55TMqTvCG&loginChannelId=1657640735&returnUri=%2Foauth2%2Fv2.1%2Fauthorize%2Fconsent%3Fresponse_type%3Dcode%26client_id%3D1657640735%26redirect_uri%3Dhttps%253A%252F%252Fapp.radportal.net%252FLineOAuth%26state%3D12345abcde%26scope%3Dprofile%2Bopenid%2Bemail%26nonce%3D09876xyz%26bot_prompt%3Daggressive#/",
  defaultRadiologistImage:"../assets/layout/images/topbar/avatar.png",
  studyListAutorefreashTenSec: 10000
};
