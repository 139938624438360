import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

    private studyUpdateLimitHourSubject: BehaviorSubject<number>;

  constructor() {
    // Fetch client data from local storage
    const client = JSON.parse(localStorage.getItem("user"));
    const initialHour = client && client.StudyUpdatelimithour ? client.StudyUpdatelimithour : 0;

    // Initialize BehaviorSubject with the initial value
    this.studyUpdateLimitHourSubject = new BehaviorSubject<number>(initialHour);
  }

  // Expose the Observable
  get studyUpdateLimitHour$() {
    return this.studyUpdateLimitHourSubject.asObservable();
  }

  // Method to set the initial value, if needed
  setInitialStudyUpdateLimitHour(hour: number): void {
    this.studyUpdateLimitHourSubject.next(hour);
  }

  // Method to update the value
  updateStudyUpdateLimitHour(hour: number): void {
    this.studyUpdateLimitHourSubject.next(hour);
  }
}
