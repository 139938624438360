import { Component, OnInit } from "@angular/core";
import { AuthService } from "../_services/auth/auth.service";

import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Store } from "@ngrx/store";
import { User } from "../_models/user-models/user";
import { AlertifyService } from "../_services/common/alertify.service";
import { AppSessionState } from "../_services/common/app.service";
import { login } from "../_store/actions/login.actions";
import { UserSessionStateModel } from "../_store/models/user-session-state.model";
//loginModel
import { environment } from "../../environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  dark: boolean;

  checked: boolean;

  loginModel: any = {};

  showSpinner: boolean;
  // isLoaderVisible: boolean = false;
  loaderVisible: boolean = false;
  isDisabled: boolean = false;
  isCloud: boolean = true;
  constructor(
    private authService: AuthService,
    private router: Router,
    private appSessionState: AppSessionState,
    private jwtHelperService: JwtHelperService,
    private store: Store<any>,
    private alertify: AlertifyService
  ) {}

  ngOnInit() {
    localStorage.clear();

    this.isCloud = environment.isCloud;
    environment.isSiteAdmin = false;
    // if(this.isCloud){
    //         const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
    //         const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');

    //         themeLink.href = 'assets/theme/theme-indigo.css';
    //         layoutLink.href = 'assets/layout/css/layout-indigo.css';

    // }
    //console.log(environment.isCloud);
    if (this.jwtHelperService.isTokenExpired(localStorage.getItem("token"))) {
      this.router.navigate(["/login"]);
    } else {
      this.routeToStudyWorklist();
    }
  }

  login() {
    //this.showLoadingSpinner();
    //this.isLoaderVisible = true;
    this.loaderVisible = true;
    this.loginModel.IsCloud = this.isCloud;
    this.authService.login(this.loginModel).subscribe(
      (data) => {
        if (data) {
          //debugger;
          if (data.user.IsSuperAdmin) {
            environment.isSuperAdmin = true;
          }

          if (data.user.JobType == "A") {
            environment.isSiteAdmin = true;
          }
          this.appSessionState.setToken(
            data.tokenString,
            <User>data.userRights,
            "" + new Date()
          );
          this.appSessionState.setUserToSessionStorage(data.user);
          localStorage.setItem("immediateReport", "true");
          this.setUserSessionState(data);
          //this.hideLoadingSpinner();
          this.routeToStudyWorklist();
          //this.isLoaderVisible = false;
          this.loaderVisible = false;
          this.alertify.success("Logged in successfully.");
        }
      },
      (error) => {
        this.loaderVisible = false;
        //this.isLoaderVisible = false;
        // this.hideLoadingSpinner();
        this.alertify.error("Failed to login");
      }
    );
  }
  setUserSessionState(data) {
    let loginModel: UserSessionStateModel = {
      LoginTime: new Date(),
      UserInfo: data.user,
    };
    this.store.dispatch(login({ userSessionDetail: loginModel }));
  }

  routeToStudyWorklist() {
    this.router.navigate(["/study/worklist"]);
  }

  showLoadingSpinner() {
    this.showSpinner = true;
    this.isDisabled = true;
  }
  hideLoadingSpinner() {
    this.showSpinner = false;
    this.isDisabled = false;
  }

  goToLink() {
    window.open("https://" + environment.OAuthUrl, "_self");
  }

  gotoForgetPassword() {
    this.router.navigate(["/reset-password/radiologist"]);
  }
}
