import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { ChangeOrderStatusReqModel } from "src/app/_models";
import { PatientHistory } from "src/app/_models/group/patient-history.model";
import { SiteInformation } from "src/app/_models/site-information.model";
import { StudyModel } from "src/app/_models/study.model";
import { User } from "src/app/_models/user-models/user";
import { AlertifyService } from "src/app/_services/common/alertify.service";
import { AppSessionState } from "src/app/_services/common/app.service";
import { GroupService } from "src/app/_services/group.service";
import { SiteService } from "src/app/_services/site/site.services";
import { StudyWorklistService } from "src/app/_services/worklist/study-worklist.service";

@Component({
  selector: "app-patient-study-history",
  templateUrl: "./patient-study-history.component.html",
  styleUrls: ["./patient-study-history.component.scss"],
})
export class PatientStudyHistoryComponent implements OnInit {
  @Input() studyWorklistData: StudyModel;
  @Input() isMainReport: boolean;
  @Output() isCloseStudySummaryPanel: EventEmitter<boolean> =
    new EventEmitter();

  patientHistoryData: PatientHistory[];
  examResult: string;
  displayResultText: boolean = false;
  siteDetail: SiteInformation;
  client: User = new User();
  convertToMainReq: ChangeOrderStatusReqModel = new ChangeOrderStatusReqModel();

  constructor(
    private _datePipe: DatePipe,
    private _alertify: AlertifyService,
    private _siteService: SiteService,
    private _appState: AppSessionState,
    private _groupService: GroupService,
    private _confirmationService: ConfirmationService,
    private _studyWorklistService: StudyWorklistService
  ) {}

  ngOnChanges(): void {
    this.onChangesInitialization();
  }

  ngOnInit(): void {
    this.basicInitialization();
  }

  private patientStudyHistory(rowData: StudyModel) {
    this._groupService
      .getPatientHistory(rowData.Hn, rowData.OrgId)
      .subscribe((res: PatientHistory[]) => {
        this.patientHistoryData = res;
      });
  }

  private getSitedetails() {
    this._siteService.getSiteDetail(this.client.OrgId).subscribe((data) => {
      this.siteDetail = data;
    });
  }

  viewAiResult(data: PatientHistory) {
    this.displayResultText = true;
    this.examResult = "";
    this._studyWorklistService
      .getExamResult(data.OrderId, this.studyWorklistData.OrgId)
      .subscribe((data) => {
        if (data) {
          this.examResult = data;
        }
      });
  }

  goToLocalPacks(history: PatientHistory) {
    let date = this.getDateLocation(history.ExamDate);

    let localPacsUrl = "http://www.microsoft.com";
    if (this.siteDetail.PacsUrl2 != undefined) {
      let dcmViewer = this.siteDetail.DCMViewer;
      //let _AccessionNo=history.AccessionNo;
      let _AccessionNo=history.PacsAccessionNo;
    


      if (dcmViewer == "PACS") {
        localPacsUrl = this.siteDetail.PacsUrl2 + _AccessionNo;
      } else {
        localPacsUrl =
          this.siteDetail.PacsUrl2 +
          this.studyWorklistData.OrgId +
          "/" +
          date +
          "/" +
          _AccessionNo +
          "/study.json";
      }
      console.log(localPacsUrl);
    }
    window.open(localPacsUrl, "history", "toolbar=0,location=0,menubar=0");
  }

  private getDateLocation(sdate: Date) {
    let dateString = sdate;
    return this._datePipe.transform(dateString, "d.M.yyyy");
  }

  convertToMain(history: PatientHistory) {
    this.convertToMainReq.EmpId = this.client.EmpId;
    this.convertToMainReq.OldAccessionNo = this.studyWorklistData.AccessionNo;
    this.convertToMainReq.NewAccessionNo = history.AccessionNo;

    this._confirmationService.confirm({
      key: "changeStudyStatus",
      message: "Are you sure to convert this study to main?",
      accept: () => {
        this._studyWorklistService
          .changeStudyStatus(this.convertToMainReq)
          .subscribe((res: boolean) => {
            if (res) {
              this.isCloseStudySummaryPanel.emit(false);
              this._alertify.success(
                `Accession# ${history.AccessionNo} study history is now main study.`
              );
            } else {
              this._alertify.error(
                "Something went wrong, please try again later."
              );
            }
          });
      },
      reject: () => {
        this._alertify.success("You have rejected to swap.");
      },
    });
  }

  private basicInitialization() {
    this.client = JSON.parse(this._appState.getSessionStorage());
    this.getSitedetails();
  }

  private onChangesInitialization() {
    if (this.studyWorklistData != null) {
      this.patientStudyHistory(this.studyWorklistData);
    }
  }
}
