export class StudyReportingTemplateModel {
  TemplateId: number;
  TemplateUid: string;
  TemplateName: string;
  TemplateHeader: string;
  TemplateText: string;
  TemplateTextRtf: string;
  TemplateBinary: number;
  TemplateType: string;
  SeverityId?: number;
  AutoApply?: boolean;
  IsUpdated: string;
  IsDeleted: string;
  OrgId: number;
  CreatedBy: number;
  CreatedOn: Date;
  LastModifiedBy?: number;
  LastModifiedOn?: Date;
  Color: string;
  IsNormal?: boolean;
  ExamId?: number;
  isAutoApply?: boolean;
  ExamType?: number;
  ExamName: string;
}
