<p-panel header="Documents and History">
  <div class="grid">
    <div class="col-12">
      <p-tabView
      (onChange)="switchHeaders($event)"
      [(activeIndex)]="tabIndex"
    >
      <p-tabPanel header="Documents">
        <div class="document-table-fixed-height">
          <app-study-document
            id="child"
            [studyWorklistData]="studyWorklistData"
            [isReportDetails]="isReportDetails"
          ></app-study-document>
        </div>
      </p-tabPanel>
      <p-tabPanel header="History">
        <app-patient-study-history
          [studyWorklistData]="studyWorklistData"
          [isMainReport]="true"
        ></app-patient-study-history>
      </p-tabPanel>
    </p-tabView>
    </div>
  </div>
</p-panel>
