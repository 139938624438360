import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-radiologist-phone-verification',
  templateUrl: './radiologist-phone-verification.component.html',
  styleUrls: ['./radiologist-phone-verification.component.scss']
})
export class RadiologistPhoneVerificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onOtpChange(data:any){
    console.log(data);
  }


}
