<div class="grid reporting-img-upload-wrap">
    <div class="field col-12 lg:col-6 md:col-6" style="padding-left: 15px; padding-right: 15px">
      <label htmlFor="regularReportImg">Image Upload:</label>
      <div class="upload-container">
        <label class="choose-files-label" for="fileInput">
          <div>
            <i class="pi pi-upload"></i> <br />
            Click to select or <br /> Drag and drop images here
          </div>
        </label>
        <input type="file" id="fileInput" (change)="onFileSelected($event)" multiple accept="image/*">
        <div class="drop-area" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event)">
        </div>
      </div>
    </div>

    <div class="field col-12 lg:col-6 md:col-6" style="padding-right: 15px">
      <label htmlFor="reportImg" style="display: block;">Image Upload (Using Copy Paste):</label>
      <input id="uploadImageWithPaste" pInputText placeholder="Paste here for Image Upload"
        (paste)="onPaste($event)" />
    </div>

    <div class="field col-12" style="padding-left: 15px; padding-right: 15px">
        <!-- Reporting Key Photo Fallery Start -->
        <div class="photo-gallery">
            <div class="photo-item" *ngFor="let photo of sortedImageTempData(); let i = index" [style.order]="i"
              draggable="true" (dragstart)="onDragStart($event, i)" (dragover)="onDragOverImg($event)"
              (dragend)="onDragEnd()" (drop)="onDropImg(i)">
              <div class="reporting-image-container">
                <i class="pi pi-trash delete-icon" (click)="onDeleteImage(photo)"
                  title="Press for delete the image."></i>
                <div class="reporting-image-wrap" (click)="openPopupForReportingImage(photo)">
                  <div class="overlay">
                    <div class="overlay-content">
                      <span class="overlay-text">Click to View</span>
                    </div>
                  </div>
                  <img [src]="photo.ImageURLSafe" [alt]="photo.SlNo" class="report-img" [id]="i+1">
                </div>

              </div>
            </div>
        </div>
        <!-- Reporting Key Photo Fallery Start -->
    </div>
</div>

<!-- Delete Reporting Key Image Confirmation Dialog -->
<p-confirmDialog header="Confirmation" key="deleteReportingImage" icon="pi pi-exclamation-triangle"
  message="Are you sure you want to proceed?" [style]="{ width: '350px' }" acceptButtonStyleClass="p-button-text"
  rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<!-- Delete Reporting Key Image Confirmation Dialog -->
