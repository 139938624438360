import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/_services/auth/auth.service';
import { AlertifyService } from '../_services/common/alertify.service';
import { AppSessionState } from '../_services/common/app.service';
import {JwtHelperService} from "@auth0/angular-jwt";
import {Store} from "@ngrx/store";
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user-models/user';
import { UserSessionStateModel } from '../_store/models/user-session-state.model';

@Component({
  selector: 'app-login-with-line',
  templateUrl: './login-with-line.component.html',
  styleUrls: ['./login-with-line.component.scss']
})
export class LoginWithLineComponent implements OnInit {

 

  code:string;
  state:string;
  hubId: string;
  sessionId: string;

  showLoader:boolean;

  dark: boolean;

  checked: boolean;

     loginModel: any = {};

    showSpinner : boolean;
    isDisabled: boolean = false;
    isCloud : boolean = true;
    constructor(private authService : AuthService,
                private router: Router,
                private appSessionState: AppSessionState,
                private jwtHelperService:JwtHelperService,
                private store: Store<any>,
                private alertify:AlertifyService,
                private activatedRouter: ActivatedRoute,
                ) { }

  ngOnInit() {
    this.showLoader=true;
    this.activatedRouter.queryParams.subscribe((routeParam) => {
      console.log(routeParam);
      this.code = routeParam.code;
      this.state = routeParam.state;
    });

    if(this.code){

    

    this.authService.LoginWithLine(this.code,this.state).subscribe(data=>{

      if(data){

        if(data.IsSignUp==true){
          this.redirectToSignUpPage(data.LineId);
          return;
        }
        //debugger;
        if(data.user.IsSuperAdmin){
            environment.isSuperAdmin = true;
        }

        if(data.user.JobType=="A"){
            environment.isSiteAdmin = true;
        }
        this.appSessionState.setToken(data.tokenString, <User>data.userRights, "" + new Date());
        this.appSessionState.setUserToSessionStorage(data.user)
        localStorage.setItem('immediateReport','true')
        this.setUserSessionState(data);
        this.hideLoadingSpinner();
        this.routeToStudyWorklist();
        this.alertify.success('Logged in successfully.');
    }

},error => {
    this.hideLoadingSpinner();
    this.alertify.error('Failed to login');

})
  
  
  }

  }




  setUserSessionState(data){
    let loginModel : UserSessionStateModel ={

        LoginTime : new Date(),
        UserInfo : data.user
    }
   // this.store.dispatch(login({ userSessionDetail: loginModel }))
}


routeToStudyWorklist(){
    this.router.navigate(['/study/worklist']);
}

showLoadingSpinner() {
    this.showSpinner = true;
    this.isDisabled = true;
}
hideLoadingSpinner() {
    this.showSpinner = false;
    this.isDisabled = false;
}

redirectToSignUpPage(LineId: any) {
  this.router.navigate(['/signup/radiologist/'+LineId]);
}

}


