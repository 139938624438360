import { RptFindingDtlItem } from "../reporting/rpt-finding-dtl-item";
import { TodayStatus } from "./today-status.model";

export class ExamResultHistoryResModel {
  public ExamresulthistoryId: number;
  public AccessionNo: string;
  public OrderId: number;
  public ExamId: number;
  public ResultTextHtml: string;
  public ResultTextPlain: string;
  public ResultTextRtf: string;
  public ResultBinary: Uint8Array;

  public ResultStatus: string;
  public IcdId: number;
  public SeverityId: number;
  public IsCritical: boolean;
  public Hl7Text: string;
  public Hl7Send: string;

  public ReleasedBy: number;
  public ReleasedOn: Date;

  public FinalizedBy: number;
  public FinalizedOn: Date;
  public OrgId: number;
  public CreatedBy: number;
  public CreatedOn: Date;
  public LastModifiedBy: number;
  public LastModifiedOn: Date;
  public Reason: number;
  public Hl7HisSend: string;
  public Hl7TextHis: string;
  public Layout: string;
  public SynEmpId: number;
  public VerbalDatetime: Date;
  public FollowUp: number;
  public SuggestedStudy: string;
  public ImpressionText: string;
  public Icd10Text: string;
  public FirstfinalizedOn: Date;
  public ScoreResultText: string;
  public ReportResultText: string;
  public HeatmapResultText: string;
  public ContourResultText: string;
  public ImageFolderPath: string;
  public AtelectasisScore: number;
  public CalcificationScore: number;
  public CardiomegalyScore: number;
  public ConsolidationScore: number;
  public FibrosisScore: number;
  public MediastinalWideningScore: number;
  public NoduleScore: number;
  public PleuralEffusionScore: number;
  public PneumoperitoneumScore: number;
  public PneumothoraxScore: number;
  public AbnormalityScore: number;
  public TbScore: number;
  public AtelectasisText: string;
  public CalcificationText: string;
  public CardiomegalyText: string;
  public ConsolidationText: string;
  public FibrosiText: string;
  public MediastinalWideningText: string;
  public NoduleText: string;
  public PleuralEffusionText: string;
  public PneumoperitoneumText: string;
  public PneumothoraxText: string;
  public TbText: string;

  public Tuberculosis: number;
  public PulmonaryEdema: number;
  public LungOpacity: number;
  public Mass: number;
  public AbnormalReason: string;
  public IsNormal: boolean;
  public IsLocked: boolean;
  public LockedOn: Date;
  public LockedBy: string;
  public LockedByName: string;
  public Remarks: string;

  TemplateId?: number;
  ExamResultDtls: RptFindingDtlItem[];

  PositiveDate?: Date;
  CompareDate?: Date;
  ComparisonStatus: boolean;
  ResultQuality: string;

  public TodayStatus: TodayStatus;
  IsDraft: boolean;
  ComplexRating: number;
  Rating: number;
  public RatingBy?: number;
  public RatingOn?: Date;
  public ClinicalSignificanceId?: number;
  public ResetReason: string;
  public VersionNo?: number;
  public TransactionType: string;
  public TransactionBy?: number;
  public TransactionOn?: Date;
}
