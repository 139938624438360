export class StudyTrackingModel {
  Status: string;
  OrderStatus: string;
  Date: Date;
  NameBy: string;
  NameTo: string;
  ChangeslogId: number;
  Dob: Date; 
  PatientName: string;
  PatientNameEng: string;
  Gender: string;
  CurGender: string;

  CurPatientName: string;
  CurPatientNameEng: string;
  CurTitle: string;
  CurTitleEng: string;
  CurDob: Date;
  TitleEng: string;
  Title: string;
  AssignedNameTo: string;
  UnassignedName: string;
}
