import { Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { StudyModel } from 'src/app/_models/study.model';
import { StudyWorklistService } from 'src/app/_services/worklist/study-worklist.service';

@Component({
  selector: 'app-study-worklist-dialog',
  templateUrl: './study-worklist-dialog.component.html',
  styleUrls: ['./study-worklist-dialog.component.css']
})
export class StudyWorklistDialogComponent implements OnInit {


  OrderId: number = 0;
  studyWorkList: StudyModel[] = [];
  studyWorklistData: StudyModel;
  activeTab = 0;
  tabIndex = 0;
  isReportDetails: boolean;
  title: string = "";
  Hn: string = "";
  StudyName: string = "";
  originalUlrNumber = "";

  constructor(private currentRoute: ActivatedRoute, private studyWorklistService: StudyWorklistService,private titleService: Title) { }

  ngOnInit(): void {
    
    let id = this.currentRoute.snapshot.paramMap.get('id');

    this.originalUlrNumber=atob(id);


    if(this.originalUlrNumber!=null){
      this.OrderId=Number(this.originalUlrNumber);


      this.studyWorklistService.getPatientByOrderID(this.OrderId).subscribe((res) => {

        if (res) {
          this.studyWorkList = res;
          this.studyWorklistData=this.studyWorkList[0];
          console.log(this.studyWorklistData);

          this.Hn=this.studyWorklistData.Hn == null ? '' : this.studyWorklistData.Hn;
          this.StudyName=this.studyWorklistData.GovtName == null ? '' : this.studyWorklistData.GovtName;

          this.title="Cloud Imaging Solutions - Document Viewer - "+this.Hn+" - "+this.StudyName;

          this.titleService.setTitle(this.title);
          
        }
      });
     

    }
  }

  switchHeaders(tabNumber: any) {
    this.activeTab = tabNumber.index;
  }

}
