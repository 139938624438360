<div class="singup-top grid">
  <div class="col-12 md:col-4">
    <div class="signup-logo">
      <img src="assets/layout/images/logo-svg.png" alt="mirage-layout" />
    </div>
  </div>
  <div class="col-12 md:col-8 text-right">
    <ul>
      <li><a href="https://radportal.net/">HOME</a></li>
      <li><a href="https://radportal.net/#about">ABOUT</a></li>
      <li><a href="https://radportal.net/#contact">CONTACT US</a></li>
    </ul>
  </div>
</div>

<div class="singup">
  <div class="text-center">
    <h1 class="mt-50">{{ registrationHeader }}</h1>
    <div class="show_wrap all_radiologist-wrap" style="margin-left: 10%">
      <div class="org-name__mix-all all_radiologist-inner">
        <div class="">
          <div class="flex">
            <p class="mr-1">I am a Radiologist</p>
            <p-inputSwitch [disabled]="IsVerifySuccessfull" [(ngModel)]="isRadiographer" class="nmt-9"
              (onChange)="radiographerShow()"></p-inputSwitch>
            <p class="ml-1">Radiographer</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="radiogistForm">
    <p-card styleClass="patient-information-panel">
      <div class="p-fluid grid">
        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4">
          <div class="field grid relative check-mark">
            <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Email <span class="red">*</span>
            </label>

            <div class="col-12 md:col-9">
              <input (keyup)="checkEmail($event)" *ngIf="
                  (disable == true && LineId != null) || IsEmailVerified == true
                " type="email" disabled="true" pInputText formControlName="Email" [(ngModel)]="selectedEmail"
                placeholder="Email" (input)="checkButtonState()" />

              <input (keyup)="checkEmail($event)" *ngIf="
                  disable == false &&
                  (IsEmailVerified == false || IsEmailVerified == null)
                " type="email" pInputText formControlName="Email" [(ngModel)]="selectedEmail" placeholder="Email"
                (input)="checkButtonState()" />

              <i *ngIf="IsEmailVerified == true" style="color: green" class="pi pi-check-circle"></i>
            </div>

            <div *ngIf="
                radiogistForm.controls['Email'].dirty &&
                IsEmailVerified == false
              ">
              Email verification failed!
            </div>

            <p-message severity="error" *ngIf="emailExists && radiogistForm.controls['Email'].dirty"
              class="danger exist-email" text="Email already exists."></p-message>

             
          </div>
        </div>

        <div class="mt-15" *ngIf="
            IsCountDownTimeOut == false &&
            (IsVerifySuccessfull == false || IsVerifySuccessfull == null)
          ">
          {{ display }}
        </div>

        <div class="col-12 md:col-1"></div>
        
        <div class="col-12 md:col-5">
          <div class="field grid">
            <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Phone No. <span class="red">*</span></label>
            <div class="col-12 md:col-7 relative check-mark pdl-0" style="margin-left: -20px">
              <div class="p-inputgroup phone-inputgroup">
                <span class="phone-inputgroup-addon">
                  <p-dropdown [disabled]="IsPhoneVerified == true" [options]="CountryWithCode"
                    formControlName="phoneCode" [(ngModel)]="selectedPhoneCode" optionLabel="name" [filter]="true"
                    filterBy="name">
                    <ng-template pTemplate="selectedItem">
                      <div class="country-item country-item-value" *ngIf="selectedPhoneCode">
                        <img src="../../../../../assets/demo/flags/flag_placeholder.png" [class]="
                            'flag flag-' + selectedPhoneCode.code.toLowerCase()
                          " />
                        &nbsp;<span>{{ selectedPhoneCode.dial_code }}</span>
                      </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                      <div class="country-item">
                        <img src="../../../../../assets/demo/flags/flag_placeholder.png"
                          [class]="'flag flag-' + country.code.toLowerCase()" />
                        &nbsp;<span>{{ country.name }} {{ country.dial_code }}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </span>
                <input *ngIf="IsPhoneVerified == true" (keyup)="checkPhone($event)" disabled type="text" pInputText
                  formControlName="PhoneNo" [(ngModel)]="selectedPhoneNo" placeholder="Phone No."
                  (input)="checkButtonState()" />

                <input *ngIf="IsPhoneVerified == false || IsPhoneVerified == null" (keyup)="checkPhone($event)"
                  type="text" pInputText formControlName="PhoneNo" [(ngModel)]="selectedPhoneNo" placeholder="Phone No."
                  (input)="checkButtonState()" />
              </div>

              <i *ngIf="IsPhoneVerified == true" style="color: green" class="pi pi-check-circle"></i>
            </div>
            <div class="col-12 mb-2 md:col-1 md:mb-0">
              <p-button *ngIf="
                  IsSentVerificationCode == true && IsVerifySuccessfull != true
                " icon="pi pi-refresh" title="Reset" (onClick)="reset()"></p-button>
            </div>

            <div *ngIf="
                radiogistForm.controls['PhoneNo'].dirty &&
                IsPhoneVerified == false
              ">
              Phone number verification failed!
            </div>

            <p-message severity="error" *ngIf="PhoneExists && radiogistForm.controls['PhoneNo'].dirty"
              class="danger exist-email" text="Phone number already exists.">
            </p-message>
          </div>
        </div>

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-11">
          <div class="flex" *ngIf="!IsVerifySuccessfull">
            <p-checkbox formControlName="TearmAndCondition" name="TearmAndCondition" [binary]="true"
              [(ngModel)]="selectedTermAndCon"></p-checkbox>
            <div class="ml-3" *ngIf="config.setting.TermsAndConditions" [innerHTML]="config.setting.TermsAndConditions">

            </div>
            <!-- {{config.setting.TermsAndConditions}} -->
          </div>
        </div>
      </div>

      <div class="p-fluid grid" *ngIf="IsVerifySuccessfull">

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4">
          <div class="field grid signup-input-wrapper">
            <label htmlfor="password" class="col-12 mb-2 md:col-3 md:mb-0">Password <span class="red">*</span></label>
            <div class="col-12 md:col-9">

              <p-password id="password" [(ngModel)]="selectedPassword" formControlName="Password" [toggleMask]="true"
                autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"></p-password>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4">
          <div class="field grid signup-input-wrapper">
            <label htmlfor="ConfirmPassword" class="col-12 mb-2 md:col-3 md:mb-0">Confirm Password <span
                class="red">*</span></label>
            <div class="col-12 md:col-9">
              <p-password id="ConfirmPassword" [(ngModel)]="selectedConfirmPassword" formControlName="ConfirmPassword"
                [toggleMask]="true" autocomplete="off" readonly
                onfocus="this.removeAttribute('readonly');"></p-password>
            </div>

            <div *ngIf="
                radiogistForm.controls['ConfirmPassword'].dirty &&
                radiogistForm.hasError('mismatch')
              ">
              Password mismatch
            </div>
          </div>
        </div>
        <div class="col-12 md:col-2"></div>

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4">
          <div class="field grid">
            <label htmlfor="firstname" class="col-12 mb-2 md:col-3 md:mb-0">First Name <span
                class="red">*</span></label>
            <div class="col-12 md:col-9">
              <input id="firstname" type="text" pInputText formControlName="FName" [(ngModel)]="selectedFName"
                placeholder="First Name" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
            </div>
          </div>
        </div>
        <div class="col-12 md:col-1"></div>

        <div class="col-12 md:col-4">
          <div class="field grid">
            <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Last Name <span class="red">*</span></label>
            <div class="col-12 md:col-9">
              <input type="text" pInputText formControlName="LName" [(ngModel)]="selectedLName" placeholder="Last Name"
                autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
            </div>
          </div>
        </div>
        <div class="col-12 md:col-2"></div>

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4">
          <div class="field grid">
            <label htmlfor="LineId" class="col-12 mb-2 md:col-3 md:mb-0">Line Id</label>
            <div class="col-12 md:col-9">
              <input id="LineId" *ngIf="disable == true && LineId != null" type="text" disabled="true" pInputText
                formControlName="LineId" [(ngModel)]="selectedLineId" autocomplete="off" readonly
                onfocus="this.removeAttribute('readonly');" />
              <input id="LineId" *ngIf="disable == false" type="text" pInputText formControlName="LineId"
                [(ngModel)]="selectedLineId" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
            </div>
          </div>
        </div>

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4">
          <div class="field grid">
            <label htmlfor="MedicalLincence" class="col-12 mb-2 md:col-3 md:mb-0">Medical License</label>
            <div class="col-12 md:col-9">
              <input id="MedicalLincence" type="text" pInputText formControlName="MedicalLincence"
                [(ngModel)]="selectedMedicalLincence" autocomplete="off" readonly
                onfocus="this.removeAttribute('readonly');" />
            </div>
          </div>
        </div>
        <div class="col-12 md:col-2"></div>


        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4">
          <div class="field grid">
            <label htmlfor="CountryName" class="col-12 mb-2 md:col-3 md:mb-0">Country</label>
            <div class="col-12 md:col-9">
              <p-dropdown id="CountryName" [options]="countryList" [(ngModel)]="selectedCountry"
                formControlName="Country" (onChange)="loadProvince()" placeholder="Select a country"
                optionLabel="CountryName" [showClear]="true"></p-dropdown>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-1"></div>

        <div class="col-12 md:col-4">
          <div class="field grid">
            <label htmlfor="ProvinceName" class="col-12 mb-2 md:col-3 md:mb-0">Province</label>
            <div class="col-12 md:col-9">

              <p-dropdown id="ProvinceName" [options]="ProvinceList" [(ngModel)]="selectedProvince"
                (onChange)="loadDistrict()" formControlName="Province" placeholder="Select a Province"
                optionLabel="ProvinceName" [showClear]="true"></p-dropdown>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-2"></div>

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4">
          <div class="field grid">
            <label htmlfor="DistrictName" class="col-12 mb-2 md:col-3 md:mb-0">District</label>
            <div class="col-12 md:col-9">

              <p-dropdown id="DistrictName" [options]="districtList" [(ngModel)]="selectedDistrict"
                formControlName="District" (onChange)="loadSubDistrict()" placeholder="Select a District"
                optionLabel="DistrictName" [showClear]="true"></p-dropdown>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-1"></div>

        <div class="col-12 md:col-4">
          <div class="field grid">
            <label htmlfor="SubdistrictName" class="col-12 mb-2 md:col-3 md:mb-0">Sub-District</label>
            <div class="col-12 md:col-9">

              <p-dropdown id="SubdistrictName" [options]="SubDistrictList" [(ngModel)]="selectedSubDistrict"
                formControlName="SubDistrict" placeholder="Select a Sub-District" optionLabel="SubdistrictName"
                [showClear]="true"></p-dropdown>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-2"></div>

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4">
          <div class="field grid">
            <label htmlfor="ZipCode" class="col-12 mb-2 md:col-3 md:mb-0">Zip Code</label>
            <div class="col-12 md:col-9">
              <input id="ZipCode" type="text" pInputText formControlName="ZipCode" [(ngModel)]="selectedZipCode"
                placeholder="Zip Code" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
            </div>
          </div>
        </div>

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4">
          <div class="field grid">
            <label htmlfor="SubspecialtyName" class="col-12 mb-2 md:col-3 md:mb-0">Subspecialties <span
                class="red">*</span></label>
            <div class="col-12 md:col-9">
              <p-multiSelect id="SubspecialtyName" [options]="SubSpecialtyList" [(ngModel)]="selectedSubSpecialty"
                formControlName="SubSpecialty" placeholder="Select Subspecialties" optionLabel="SubspecialtyName"
                [showClear]="true"></p-multiSelect>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-2"></div>

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-1 rad-profile">
          <p-image [src]="profileImageUrl" alt="Image" width="250" [preview]="false"></p-image>

          <p-fileUpload class="ui-state-focus" name="myFile[]" customUpload="true" (uploadHandler)="myUploader($event)"
            (onUpload)="onUpload($event)" auto="auto" accept="image/*">
          </p-fileUpload>
        </div>
        <div class="col-12 md:col-4"></div>
        <div class="col-12 md:col-2">
          <p-button class="upload-feature-btn" icon="pi pi-upload" label="Upload Document"
            (onClick)="OpenDocumentPopUp()"></p-button>
        </div>
      </div>
    </p-card>

    <div class="height-20"></div>
    <p-card *ngIf="IsSentVerificationCode == true && IsVerifySuccessfull == false">
      <div class="otp-container grid">
        <div class="col-12 md:col-1"></div>
        <div class="otp-email field">
          <label htmlfor="lastname">Type Code</label>
          <ng-otp-input #ngOtpInputEmail (onInputChange)="onEmailOtpChange($event)"
            [config]="{ length: 6 }"></ng-otp-input>
        </div>
        <div class="col-12 md:col-1"></div>
        <div class="otp-phone field">
          <label htmlfor="lastname">Type OTP</label>
          <ng-otp-input #ngOtpInputPhone (onInputChange)="onOtpPhoneChange($event)"
            [config]="{ length: 6, disableAutoFocus: true }"></ng-otp-input>
        </div>
      </div>
    </p-card>

    <div class="p-formgrid grid" *ngIf="IsVerifySuccessfull == true">
      <div class="col-12 md:col-9">
        <!-- <p-checkbox
          formControlName="TearmAndCondition"
          name="groupname"
          value="val1"
          [(ngModel)]="selectedTermAndCon"
        ></p-checkbox>
        I have read and agreed to the <a> RadPortal Account Terms of Use </a>
        <br /> -->
      </div>
      <div class="col-12 md:col-3 text-right">
        <p-button (onClick)="startOverPage()" icon="pi pi-spinner" class="mr-2" label="Start Over"></p-button>

        <p-button icon="pi pi-check" label="Finish" [disabled]="
            !radiogistForm.valid ||         
            selectedSubSpecialty?.length == 0
          " (onClick)="saveOrUpdateRediologist()"></p-button>
      </div>
    </div>

    <div class="send-verfication text-center mt-50">
      <p-button icon="pi pi-send" *ngIf="IsSentVerificationCode == false" [disabled]="
          emailExists == true ||
          PhoneExists == true ||
          !selectedPhoneNo ||
          !selectedEmail ||
          !isEmailValid() || isSendOTPbutton ||
          selectedPhoneNo == null || !radiogistForm.get('TearmAndCondition').value || 
          selectedPhoneNo == undefined
        " label="Send Verification" (onClick)="sentVerificationCode()"></p-button>
      <!-- <p-button icon="pi pi-send" [disabled]="isButtonDisabled" *ngIf="!IsSentVerificationCode" label="Send Verification"
        (onClick)="sentVerificationCode()">
      </p-button> -->
    </div>

    <div class="confirm-second-step mt-50">
      <div class="resend-btn" *ngIf="showOrHideResendButton == true">
        <p-button icon="pi pi-send" *ngIf="
            IsVerifySuccessfull == false &&
            IsSentVerificationCode == true &&
            (IsCountDownTimeOut == true ||
              (IsEmailVerified == false && IsPhoneVerified == true) ||
              (IsEmailVerified == false && IsPhoneVerified == false) ||
              (IsEmailVerified == true && IsPhoneVerified == false))
          " label="Resend Verification Code" (onClick)="sentVerificationCode()"></p-button>
      </div>

      <div class="grid verify-btn" *ngIf="showOrHideResendButton == false">
        <div class="col-12 md:col-5"></div>
        <div class="verify-btn" *ngIf="
            (IsEmailVerified == false && IsPhoneVerified == false) ||
            (IsEmailVerified == null && IsPhoneVerified == null) ||
            IsEmailVerified != IsPhoneVerified
          ">
          <p-button icon="fa fa-check-square-o" [disabled]="!(isConfirmOtpPhoneField && isConfirmOtpEmailField)"  *ngIf="
              IsVerifySuccessfull == false &&
              IsSentVerificationCode == true &&
              IsCountDownTimeOut == false
            " label="Confirm" (onClick)="VerifyOtp()">
          </p-button>
        </div>
      </div>
    </div>
  </form>
</div>

<p-dialog header="Upload Document" [(visible)]="displayDocumentUploadDialog" (onHide)="closeDocumentDialog()"
  [modal]="true" [responsive]="true" [minY]="70" [maximizable]="false" [style]="{ width: '80vw' }"
  [breakpoints]="{ '960px': '75vw' }">
  <p-panel>
    <div class="document_upload_form">
      <div class="formgroup-inline">
        <div class="field">
          <label htmlFor="type" class="d-block mb-1">Type</label>
          <p-dropdown id="type" [options]="documentTypedDopdownItems" optionLabel="name" required name="Type"
            [(ngModel)]="selectedDocumentType"></p-dropdown>
        </div>
        <div class="field">
          <label htmlFor="document" class="d-block mb-1">Select file</label>
          <input pInputText id="document" type="file" name="File" [(ngModel)]="selecetdInput"
            accept="application/pdf,image/png,image/jpeg" (change)="documentUploader($event)" />
        </div>
        <div class="field">
          <label htmlFor="title" class="d-block mb-1">Title</label>
          <input class="min-w-515" pInputText id="title" type="text" placeholder="Enter Title" required name="Title"
            [(ngModel)]="userDocumet.Title" />
        </div>

        <p-button class="mt-20" icon="pi pi-upload" (onClick)="uploadDocuments()" label="Upload"></p-button>
      </div>

      <div class="uploaded_document_list_table">
        <p-table #dt1 [value]="newUser.hrEmpdocuments" dataKey="type" [rows]="10" [rowHover]="true"
          styleClass="p-datatable-gridlines" [paginator]="true" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th class="max-w-150" pSortableColumn="Type">
                Type <p-sortIcon field="Type"></p-sortIcon>
              </th>
              <th class="max-w-110" pSortableColumn="Format">
                Format <p-sortIcon field="Format"></p-sortIcon>
              </th>
              <th pSortableColumn="Title">
                Title <p-sortIcon field="Title"></p-sortIcon>
              </th>
              <th class="width-70">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="max-w-150">
                {{ rowData.Type }}
              </td>
              <td class="max-w-110">
                <div class="flex justify-content-center align-items-center">
                  <i class="mr-2" [ngClass]="
                      rowData.Format === 'pdf'
                        ? 'pi pi-file-pdf'
                        : 'pi pi-image'
                    "></i>
                  <span>({{ rowData.Format }})</span>
                </div>
              </td>
              <td class="td-min-w">
                {{ rowData.Title }}
              </td>
              <td class="width-70">
                <button pButton type="button" icon="fa fa-regular fa-trash" title="Delete the document"
                  class="p-button-danger p-button-rounded" (click)="deleteOrderDocument(rowData)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4" class="text-center">No data found.</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="loadingbody">
            <tr>
              <td colspan="4" class="text-center">
                Loading uploaded document data. Please wait.
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-panel>
</p-dialog>

<div *ngIf="isLoaderVisible" class="loading-overlay">
  <div class="loader"></div>
</div>