<div class="singup-top grid">
  <div class="col-12 md:col-4">
    <div class="signup-logo">
      <img src="assets/layout/images/logo-svg.png" alt="mirage-layout" />
    </div>
  </div>
  <div class="col-12 md:col-8 text-right">
    <ul>
      <li><a href="https://radportal.net/">HOME</a></li>
      <li><a href="https://radportal.net/#about">ABOUT</a></li>
      <li><a href="https://radportal.net/#contact">CONTACT US</a></li>
    </ul>
  </div>
</div>

<div class="singup hospital-signup">
  <div class="text-center">
    <h1 class="mt-50">Sign up as Healthcare Service Provider</h1>
  </div>
  <form [formGroup]="orgForm" autocomplete="off">
    <p-card styleClass="patient-information-panel">

      <div class="p-fluid grid">
        <div class="col-12 md:col-1"></div>

        <div class="col-12 md:col-6">
          <div class="field grid relative check-mark hospital-email-input">
            <label *ngIf="IsVerifySuccessfull!=true" htmlfor="email" class="col-12 mb-2 md:col-2 md:mb-0">Email</label>
            
            <label *ngIf="IsVerifySuccessfull==true" htmlfor="hospitalEmail" class="col-12 mb-2 md:col-3 md:mb-0">Hospital Email</label>
            <div class="col-12 md:col-10">
              <input (keyup)="checkEmail($event)"  *ngIf="IsVerifySuccessfull==true"  type="email"  disabled="true" pInputText formControlName="Email" [(ngModel)]="selectedEmail" placeholder="Email" />

              <input (keyup)="checkEmail($event)" type="email"  *ngIf="IsVerifySuccessfull==false"  pInputText formControlName="Email" [(ngModel)]="selectedEmail" placeholder="Email" />
              &nbsp; <span *ngIf="IsCountDownTimeOut == false && (IsVerifySuccessfull== false ||IsVerifySuccessfull==null)">  
                {{display}}
      
                </span>

              <i *ngIf="IsVerifySuccessfull==true" style="color: green;" class="pi pi-check-circle"></i>
            </div>
            
            <div *ngIf="orgForm.controls['Email'].dirty && IsEmailVerified==false">Email verification failed!</div>

            <p-message severity="error" *ngIf="emailExists && orgForm.controls['Email'].dirty" class="danger exist-email" text='Email already exists.'></p-message>
          </div>
          <div class="field">
            <div class="flex mt-5" *ngIf="IsVerifySuccessfull!=true">
              <p-checkbox formControlName="TearmAndCondition" name="TearmAndCondition" [binary]="true"
                [(ngModel)]="selectedTermAndCon"></p-checkbox>
                <div *ngIf="config.setting.TermsAndConditions">
                  <div  class="ml-3" [innerHTML]="config.setting.TermsAndConditions">
  
                  </div>
                </div>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4" >
          <div class="site-admin" *ngIf="IsVerifySuccessfull">
            <label htmlfor="siteAdmin" class="col-12 mb-2 md:col-3 md:mb-0">Site Admin  </label> <p-badge value='i' pTooltip="Site Admin Infomation"  styleClass="mr-2"></p-badge>
          </div>
        </div>      
      </div>

      <div class="p-fluid grid" *ngIf="IsVerifySuccessfull">

        <div class="col-12 md:col-1"></div>
        <div class="col-12 md:col-4">

          <div class="field grid signup-input-wrapper">
            <label htmlfor="hospitalName" class="col-12 mb-2 md:col-3 md:mb-0">Hospital Name <span class="red">*</span></label>
            <div class="col-12 md:col-9">
                <input autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" id="hospitalName" type="text"  pInputText formControlName="HospitalName" [(ngModel)]="selectedHospitalName" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
            </div>
          </div>
          <div class="field grid signup-input-wrapper">
            <label htmlfor="webUrl" class="col-12 mb-2 md:col-3 md:mb-0">Web URL</label>
            <div class="col-12 md:col-9">
                <input id="webUrl" type="text"  pInputText formControlName="WebUrl"
                 [(ngModel)]="selectedWebUrl" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
            </div>
          </div>
          <div class="field grid">
            <label htmlfor="HospitalTelNo" class="col-12 mb-2 md:col-3 md:mb-0">Tel No.</label>
            <div class="col-12 md:col-9">
              <input id="HospitalTelNo" type="text" pInputText formControlName="HospitalTelNo"  [(ngModel)]="selectedTelNo" 
              autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" />
      
            </div>
          </div>
          <div class="field grid">
            <label htmlfor="LineIdUserTwo" class="col-12 mb-2 md:col-3 md:mb-0">Line Id</label>
            <div class="col-12 md:col-9">
              <input autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" id="LineIdUserTwo" type="text" pInputText name="LineId" formControlName="LineId" [(ngModel)]="selectedLineId" autocomplete="off"/>
            </div>
          </div>
          <div class="field grid">
            <label htmlfor="Country" class="col-12 mb-2 md:col-3 md:mb-0">Country</label>
            <div class="col-12 md:col-9">
              <p-dropdown [options]="countryList" [(ngModel)]="selectedCountry" formControlName="Country" (onChange)="loadProvince()"
              placeholder="Select a country" optionLabel="CountryName" [showClear]="true"></p-dropdown>
               
            </div>
          </div>
          <div class="field grid">
            <label htmlfor="Province" class="col-12 mb-2 md:col-3 md:mb-0">Province</label>
            <div class="col-12 md:col-9">
              
              <p-dropdown [options]="ProvinceList" [(ngModel)]="selectedProvince" (onChange)="loadDistrict()" formControlName="Province" placeholder="Select a Province" optionLabel="ProvinceName" [showClear]="true"></p-dropdown>

            </div>
          </div>
          <div class="field grid">
            <label htmlfor="District" class="col-12 mb-2 md:col-3 md:mb-0">District</label>
            <div class="col-12 md:col-9">
                <p-dropdown [options]="districtList" [(ngModel)]="selectedDistrict"  formControlName="District" (onChange)="loadSubDistrict()" placeholder="Select a District" optionLabel="DistrictName" [showClear]="true"></p-dropdown>
            </div>
          </div>
          <div class="field grid">
            <label htmlfor="SubDistrict" class="col-12 mb-2 md:col-3 md:mb-0">Sub-District</label>
            <div class="col-12 md:col-9">
                <p-dropdown [options]="SubDistrictList" [(ngModel)]="selectedSubDistrict"  formControlName="SubDistrict" placeholder="Select a Sub-District" optionLabel="SubdistrictName" [showClear]="true"></p-dropdown>
            </div>
          </div>
          <div class="field grid">
            <label htmlfor="ZipCode" class="col-12 mb-2 md:col-3 md:mb-0">Zip Code</label>
            <div class="col-12 md:col-9">
              <input type="text" pInputText formControlName="ZipCode"  [(ngModel)]="selectedZipCode"
              autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/> 
            </div>
          </div>

          <div class="rad-profile">
            <label for="">Hospital Logo</label>
            <p-image [src]="profileImageUrl" alt="Image" width="250" [preview]="false"></p-image>
              <p-fileUpload
              class="ui-state-focus"
              name="myFile[]"
              customUpload="true"
              (uploadHandler)="myUploaderForHospitalLogo($event)"
              (onUpload)="onUploadHospitalLogo($event)"
              auto="auto"
              accept="image/*"
            >
            </p-fileUpload>
          </div>
        </div>
        <div class="col-12 md:col-1"></div>

        <div class="col-12 md:col-4">

          <div class="field grid">
            <label htmlfor="firstName" class="col-12 mb-2 md:col-3 md:mb-0">First Name</label>
            <div class="col-12 md:col-9">
              <input id="firstName" type="text" pInputText formControlName="FName"  [(ngModel)]="selectedFName"
              autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/> 
            </div>
          </div>
          <div class="field grid">
            <label htmlfor="lastName" class="col-12 mb-2 md:col-3 md:mb-0">Last Name</label>
            <div class="col-12 md:col-9">

              <input id="lastName" type="text" pInputText formControlName="LName"  [(ngModel)]="selectedLName"
              autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" /> 
            </div>
          </div>
          <div class="field grid signup-input-wrapper">
            <label for="adminPassword" class="col-12 mb-2 md:col-3 md:mb-0">Password <span class="red">*</span></label>
            <div class="col-12 md:col-9">
                <!-- <input type="password" name="adminPassword" id="adminPassword" value="" style="display: none;" />
                <p-password autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" id="adminPassword" name="selectedPassword" [(ngModel)]="selectedPassword"  formControlName="Password" [toggleMask]="true"></p-password> -->

                <div class="pwd-input-group">
                  <input type="text" pInputText [(ngModel)]="selectedPassword" formControlName="Password"
                         pTooltip={{validationMessageForTT}} tooltipEvent="focus" (keyup)="validatePassword($event)" 
                         [class.password-mask]="isPasswordMasked" />
                  <button pButton type="button" icon="pi pi-eye" (click)="togglePasswordMask()"  class="transparent-button"></button>
                </div>
            </div>
          </div>
          <div class="field grid signup-input-wrapper">
            <label htmlfor="confirmPassword" class="col-12 mb-2 md:col-3 md:mb-0">Confirm Password <span class="red">*</span></label>
            <div class="col-12 md:col-9">
                <!-- <p-password autocomplete="off" readonly onfocus="this.removeAttribute('readonly');" id="confirmPassword" [(ngModel)]="selectedConfirmPassword"  formControlName="ConfirmPassword" [toggleMask]="true"></p-password> -->
                <div class="pwd-input-group">
                  <input type="text" pInputText [(ngModel)]="selectedConfirmPassword" formControlName="ConfirmPassword"
                         [class.password-mask]="isPasswordMasked" />
                  <button pButton type="button" icon="pi pi-eye" (click)="togglePasswordMask()"  class="transparent-button"></button>
                </div>
            </div>

            <div *ngIf="orgForm.controls['ConfirmPassword'].dirty && orgForm.hasError('mismatch')"
                >
                <span class="red"> Password mismatch</span>
               
              </div>
          </div>
          <div class="field grid">
            <label htmlfor="SiteAdminTelNo" class="col-12 mb-2 md:col-3 md:mb-0">Tel No.</label>
            <div class="col-12 md:col-9">
              <input id="SiteAdminTelNo" type="text" pInputText formControlName="SiteAdminTelNo"  [(ngModel)]="selectedSiteAdminTelNo"
              autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"/>
            </div>
          </div>
          <div class="field grid">
            <label htmlfor="siteAdminLineId" class="col-12 mb-2 md:col-3 md:mb-0">Line Id</label>
            <div class="col-12 md:col-9">
              <input  id="siteAdminLineId" type="text" pInputText formControlName="SiteAdminLineId"  [(ngModel)]="selectedSiteAdminLineId"
              autocomplete="off"/>
            </div>
          </div>

          <div class="rad-profile">
            <label for="">Profile Picture</label>
            <p-image [src]="profileImageUrl" alt="Image" width="250" [preview]="false"></p-image>
              <p-fileUpload
              class="ui-state-focus"
              name="myFile[]"
              customUpload="true"
              (uploadHandler)="myUploader($event)"
              (onUpload)="onUpload($event)"
              auto="auto"
              accept="image/*"
            >
            </p-fileUpload>
  
          </div>
        </div>
      </div>
    </p-card>

    <div class="height-20"></div>

    <p-card *ngIf="IsSentVerificationCode==true && IsVerifySuccessfull==false">
        <div class="otp-container grid">
          <div class="col-12 md:col-1"></div>
          <div class="otp-email field">
            <label htmlfor="tyoeCode">Type Code</label>
            <ng-otp-input  #ngOtpInputEmail (onInputChange)="onEmailOtpChange($event)"  [config]="{length:6}"></ng-otp-input>
          </div>
        </div>
    </p-card>

    <div class="p-formgrid grid" *ngIf="IsVerifySuccessfull==true">
      <div class="col-12 md:col-9">
        <!-- <p-checkbox formControlName="TearmAndCondition" name="groupname" value="val1" [(ngModel)]="selectedTermAndCon"></p-checkbox> I have read and agreed to the <a> RadPortal Account Terms of Use </a> <br> -->
      </div>
      <div class="col-12 md:col-3 text-right">
        <p-button icon="pi pi-check" label="Finish"   [disabled]="!orgForm.valid" (onClick)="saveOrUpdateHospital()"></p-button>
      </div>
    </div>

    <div class="send-verfication text-center mt-50">
      <p-button icon="pi pi-send" [disabled]="isSendOTPbutton || !orgForm.get('TearmAndCondition').value" *ngIf="!IsSentVerificationCode" label="Send Verification"
        (onClick)="sentVerificationCode()">
      </p-button>
    </div> 

    <div class="confirm-second-step mt-50" *ngIf="IsVerifySuccessfull!=true  && IsSentVerificationCode==true">
      <div class="grid verify-btn"  >
        <div class="col-12 md:col-5"></div>

        <div class="resend-btn" *ngIf="showOrHideResendButton==true && IsVerifySuccessfull==false">
          <p-button icon="pi pi-send" 
          label="Resend Verification Code" (onClick)="sentVerificationCode()"></p-button>
      </div>

        <div class="verify-btn"  *ngIf="showOrHideResendButton!=true && IsVerifySuccessfull==false"> 
          <p-button [disabled]="!isConfirmOtpField" icon="fa fa-check-square-o"  label="Confirm" (onClick)="VerifyOtp()"></p-button>
        </div>
      </div> 
    </div>
  </form> 
</div>


<div *ngIf="isLoaderVisible" class="loading-overlay">
  <div class="loader"></div>
</div>