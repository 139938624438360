
import { ValidatorFn, FormControl, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { UserService } from '../_services/user/user.service';


export function existingEmailValidatorForOrg(userService: UserService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    let isExists:boolean;
  //debugger;
    userService.isExistEmail(control.value).subscribe(
      exist => {
        isExists=exist;
        return (isExists==true) ? of({"emailExists": true}) : null;
      }
    );
    return null;
  };
} 


export const trimValidator: ValidatorFn = (control: FormControl) => {
  if(control.pristine) {
    return null;
  }

  if (control.value.trim()=="") {
    return {
      'trimError': true
    };
  }

  return null;
};

export const idValidator: ValidatorFn = (control: FormControl) => {
  if(control.pristine) {
    return null;
  }
  const re = /^[a-zA-Z0-9_\-]*$/;
  const patt = new RegExp(re);
  const _result_ = patt.test(control.value.trim());
  if (!_result_) {
    return {
      'idError': true
    };
  }

  return null;
};



// only code without space like - service type id(item101)
export const codeValidator: ValidatorFn = (control: FormControl) => {
  if(control.pristine) {
    return null;
  }
  //const re = /^[a-zA-Z].*[\s\.]*$/g;
  // const re = /^[a-zA-Z]+(([-#_])?[0-9]*)*$/g;
  // const re = /^([a-zA-Z]+)?(([-#_])?[0-9]+)?$/g;
  // const re = /^([a-zA-Z]+)?(([-#_])?[a-zA-Z0-9]+)?$/g
  const re = /^([a-zA-Z]+)?(([-#_])?[a-zA-Z0-9]+)*?$/g
  const patt = new RegExp(re);
  const _result_ = patt.test(control.value.trim());
  if (!_result_) {
    return {
      'codeError': true
    };
  }

  return null;
};

export const decimalValidator: ValidatorFn = (control: FormControl) => {
  if(control.pristine) {
    return null;
  }

  // const re = /^\d+(\d|(\.\d{1,2}))$/;
  //const re = /[0-9]?(([.])?[0-9]+)$/;
  const re = /[1-9](([.])?[0-9]+)?$/;
  const patt = new RegExp(re);
  const _result_ = patt.test(control.value.trim());
  if (!_result_) {
    return {
      'decimalError': true
    };
  }

  return null;
};

export const numberValidator: ValidatorFn = (control: FormControl) => {
  if(control.pristine) {
    return null;
  }
  // const re = /^\d+$/;
  const re = /^[1-9]\d*$/g
  const patt = new RegExp(re);
  const _result_ = patt.test(control.value.trim());
  if (!_result_) {
    return {
      'numberError': true
    };
  }

  return null;
};

export const phoneNumberValidator: ValidatorFn = (control: FormControl) => {
  if(control.pristine) {
    return null;
  }
  // const re = /^\d+$/;
  const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
  const patt = new RegExp(re);
  const _result_ = patt.test(control.value.trim());
  if (!_result_) {
    return {
      'phoneNumberError': true
    };
  }

  return null;
};

export const typeValidator: ValidatorFn = (control: FormControl) => {
  
  // if(control.pristine) {
  //   return null;
  // }
  // const re = /^[a-zA-Z0-9 ]*$/;
  // const patt = new RegExp(re);
  // const _result_ = patt.test(control.value.trim());
  // if (!_result_) {
  //   return {
  //     'typeError': true
  //   };
  // }

  return null;
};

export const emailValidator: ValidatorFn = (control: FormControl) => {
  if(control.pristine) {
    return null;
  }
  const re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const patt = new RegExp(re);
  const _result_ = patt.test(control.value.trim());
  if (!_result_) {
    return {
      'emailError': true
    };
  }

  return null;
};

// special common code - like item code
export const nameValidator: ValidatorFn = (control: FormControl) => {
  
  
  if(control.pristine) {
    return null;
  }
  // const re = /^[a-zA-Z0-9_\-]*$/;
  const re = /^[a-zA-Z_\- ]*$/g
  const patt = new RegExp(re);
  const _result_ = patt.test(control.value.trim());
  if (!_result_) {
    return {
      'nameError': true
    };
  }

  return null;
};

export const addManufacturerValidator: ValidatorFn = (control: FormControl) => {
  
  // if(control.pristine) {
  //   return null;
  // }
  // const re = /^[a-zA-Z0-9_\-]*$/;
  // const patt = new RegExp(re);
  // const _result_ = patt.test(control.value.trim());
  // if (!_result_) {
  //   return {
  //     'addManuError': true
  //   };
  // }

  return null;
};

// common/global name validate format
export const addVendorValidator: ValidatorFn = (control: FormControl) => {
    
  
  // if(control.pristine) {
  //     return null;
  //   }
  //   const re = /^[a-zA-Z]+((['-_ ][a-zA-Z ])?[a-zA-Z]*)((['-_ ][a-zA-Z0-9])?[a-zA-Z0-9]*)*$/;
  //   const patt = new RegExp(re);
  //   const _result_ = patt.test(control.value.trim());
  //   if (!_result_) {
  //     return {
  //       'addVenError': true
  //     };
  //   }


// export const addVendorValidator: ValidatorFn = (control: FormControl) => {
//   if(control.pristine) {
//     return null;
//   }
//   const re = /^[a-zA-Z0-9_\- ]*$/;
//   const patt = new RegExp(re);
//   const _result_ = patt.test(control.value.trim());
//   if (!_result_) {
//     return {
//       'addVenError': true
//     };
//   }


  return null;
};
